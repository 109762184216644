import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';

import useScrollDown from 'components/hooks/useScrollDown';
import Hamburger from './elements/Hamburger';
import Register from './elements/Register';
import Menu from './elements/Menu';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  
  const browser = useSelector((state) => state.browser);
  const isScrollingDown = useScrollDown();

  const $menu = useRef();

  useEffect(() => {
    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  const handleMenu = () => {
    if (isMenuOpen) {
      enableBodyScroll($menu.current);
      setIsMenuOpen(false);
    } else {
      disableBodyScroll($menu.current);
      setIsMenuOpen(true);
    }
  };

  const handleClose = () => {
    enableBodyScroll($menu.current);
    setIsMenuOpen(false);
  };

  return (
    <Root>
      <Wrapper isScrollingDown={isScrollingDown}>
        <Link to="/">
          <Logo
            src={
              browser.is.mobile ?
              require('src/assets/images/logo/ascent-logo-black.svg') :
              require('src/assets/images/logo/ascent_logo.svg')
            }
            isScrollingDown={isScrollingDown}
            alt="Ascent"
          />
        </Link>
        <Container>
          <Register handleClick={handleClose} isScrollingDown={isScrollingDown}/>
          <Hamburger handleMenu={handleMenu} isOpen={isMenuOpen} isScrollingDown={isScrollingDown}/>
        </Container>
      </Wrapper>
      <Menu isOpen={isMenuOpen} handleClose={handleClose} innerRef={$menu} isScrollingDown={isScrollingDown} />
    </Root>
  );
};

const Root = styled.div``;

const Wrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 10;
  width: 100%;
  display: flex;
  align-items: center;
  transition: 0.4s ease;
  ${props => props.isScrollingDown ? vw('height', 50, 84, 76) : vw('height', 50, 84, 96)}
  ${vw('padding-left', 0, 30, 32)}
  ${vw('padding-right', 0, 30, 32)}
  @media ${media.tablet} {
    justify-content: space-between;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const Logo = styled.img`
  display: block;
  ${vw('margin-left', 12, 0)}
  ${vw('margin-right', 36, 0)}
  ${vw('width', 82, 138, 181)}
`;

export default Header;
