import React from 'react';
import styled from 'styled-components';

import { Redirect } from 'react-router-dom';

const Reroute = () => {
  return (
    <Root>
      <Redirect to='/404' />
    </Root>
  );
};

export default Reroute;

const Root = styled.div``;