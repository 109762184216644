import React from 'react';
import styled from 'styled-components';

import data from 'src/data/floorplans/building';

import vw, { vwDesktop } from 'src/styles/utils';

const Building = (props) => {
  const { activeLevel, handleLevel } = props;

  return (
    <Root>
      <img src={require('src/assets/images/floorplans/building/Building.jpg')} alt='' />
      <Label
        top={data[activeLevel].top}
      >
        {data[activeLevel].label}
      </Label>
      <svg
        width="392px"
        height="588px"
        viewBox="0 0 392 588"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        {...props}
      >
        <title>{"Level_Shapes"}</title>
        <g
          id="Level_Shapes"
          stroke="none"
          strokeWidth={1}
          fill="none"
          fillRule="evenodd"
          opacity={0.500930786}
        >
          <g
            id="Levels"
            transform="translate(90.278036, 72.875297)"
            fill="#97C93D"
          >
            {data.map((level, index) => (
              <G
                active={activeLevel === index}
                onClick={() => handleLevel(index)}
                key={index}
              >
                {level.shape}
              </G>
            ))}
          </g>
        </g>
      </svg>
    </Root>
  )
}

export default Building;

const Root = styled.div`
  position: relative;
  width: ${vwDesktop(392)};
  height: ${vwDesktop(588)};
  img {
    position: absolute;
  }
  svg {
    position: relative;
    z-index: 1;
  }
  img, svg {
    width: 100%;
    height: 100%;
  }
`;

const G = styled.g`
  polygon, path {
    cursor: pointer;
    opacity: ${props => props.active ? '1' : '0'};
    transition: all 0.3s ease;

    &:hover {
      opacity: 1;
    }
  }
`;

const Label = styled.div`
  position: absolute;
  white-space: nowrap;
  top: ${props => vwDesktop(props.top)};
  left: ${vwDesktop(310)};
  font-family: ${({ theme }) => theme.font.family};
  font-weight: bold;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.darkGrey};
  width: ${vwDesktop(214)};
  display: flex;
  justify-content: flex-end;
  transition: all 0.3s ease;
  ${vw('font-size', 16, 16, 16)}
  ${vw('letter-spacing', 2, 2, 2)}
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.color.darkGrey};
    bottom: ${vwDesktop(-10)};
  }
`;