import React from 'react';

export default {
  living: {
    schemes: [
      {
        label: 'grey',
        image: require('src/assets/images/interiors/living_kitchen_grey.jpg'),
      },
      {
        label: 'green',
        image: require('src/assets/images/interiors/living_kitchen_green.jpg'),
      },
      {
        label: 'yellow',
        image: require('src/assets/images/interiors/living_kitchen_yellow.jpg'),
      },
      {
        label: 'brown',
        image: require('src/assets/images/interiors/living_kitchen_brown.jpg'),
      },
    ],
    points: [
      {
        label: 'Integrated Cabinetry',
        labelPos: 'right',
        pos: ['5.7%', '12.5%'],
      },
      {
        label: 'Air Conditioning',
        labelPos: 'right',
        pos: ['49.5%', '12.9%'],
      },
      {
        label: 'Generous Balconies',
        labelPos: 'left',
        pos: ['96.5%', '68.5%'],
      },
    ]
  },
  bathroom: {
    schemes: [
      {
        label: 'grey',
        image: require('src/assets/images/interiors/bathroom_grey.jpg'),
      },
      {
        label: 'green',
        image: require('src/assets/images/interiors/bathroom_green.jpg'),
      },
      {
        label: 'yellow',
        image: require('src/assets/images/interiors/bathroom_yellow.jpg'),
      },
      {
        label: 'brown',
        image: require('src/assets/images/interiors/bathroom_brown.jpg'),
      },
    ],
    points: [
      {
        label: 'Stone Ledge',
        labelPos: 'left',
        pos: ['44%', '36%'],
      },
      {
        label: 'Convenient Fixtures',
        labelPos: 'left',
        pos: ['91.5%', '45%'],
      },
      {
        label: 'Underlit Cabinetry',
        labelPos: 'right',
        pos: ['46.75%', '82%'],
      },
    ],
    content: {
      title: <>More Style, <br/> More you</>,
      body: "Set on this minimalist backdrop, four contemporary colour options let you bring your personality into the home. Live with everything you need in a home designed to reflect your style.",
    }
  },
  features: [
    'A 31 storey concrete highrise; an enduring landmark in the city',
    'A vertical village of amenities, rising to the Level 30 Sun Terrace',
    'Generous indoor and outdoor layouts with a focus on seamless design',
    'Air-conditioning in all homes for year-round comfort',
    <>Thoughtful design details, including placement of shower handle in the bathroom and functional <br/> storage solutions</>,
    'Panoramic mountain and city views',
  ]
}