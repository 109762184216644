import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import vw, { vwMobile, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Dollhouse = (props) => {
  const { data, activeLevel, activePoint, handlePoint } = props;
  return (
    <Root>
      <MobileOverlay active={activeLevel === 0}>
        <span>{data[activeLevel].mobileLabel}</span>
      </MobileOverlay>
      {data.map((item, index) => (
        <Image
          key={index}
          image={item.image}
          style={{
            opacity: activeLevel === index ? 1 : 0,
            pointerEvents: activeLevel === index ? 'all' : 'none',
          }}
        >
          {item.points.map((item, index) => (
            <Point
              key={index}
              top={item.top}
              left={item.left}
              mobileLeft={item.mobileLeft}
              mobileBottom={item.mobileBottom}
              tabletLeft={item.tabletLeft}
              active={activePoint === index}
              onClick={() => handlePoint(index)}
            >
              <span />
              <div style={{ opacity: activePoint === index ? 1 : 0 }}>
                <p className="callouts">{item.text}</p>
              </div>
            </Point>
          ))}
        </Image>
      ))}
    </Root>
  );
};

Dollhouse.propTypes = {
  data: PropTypes.array,
  activeLevel: PropTypes.number,
  activePoint: PropTypes.number,
  handlePoint: PropTypes.func,
};

const Root = styled.div`
  position: relative;
  width: 100%;
  ${vw('height', 240, 576, 969)}
  @media ${media.desktop} {
    width: ${vwDesktop(1292)};
    margin: 0 auto;
  }
`;

const MobileOverlay = styled.div`
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: ${props => props.active ? 'linear-gradient(to bottom, rgba(238, 238, 238, 0) 81%, rgba(0, 0, 0, 0.4) 89%)' : ''};
  transition: all 0.3s ease;
  z-index: 1;
  span {
    position: absolute;
    left: ${vwMobile(16)};
    bottom: ${vwMobile(8)};
    font-weight: bold;
    font-size: ${vwMobile(10)};
    letter-spacing: ${vwMobile(0.5)};
    line-height: normal;
    color: white;
    text-transform: uppercase;
  }
  @media ${media.tablet} {
    display: none;
  }
`;

const Image = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  transition: 0.4s ease;
`;

const Point = styled.div`
  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  span {
    display: block;
    background-color: ${({ theme }) => theme.color.lightGreen};
    border-radius: 50%;
    box-shadow: 0 0 33px 0 rgba(0, 0, 0, 0.1), 0 10px 15px 0 rgba(0, 0, 0, 0.5);
    z-index: 2;
    cursor: pointer;
    ${vw('width', 18, 22, 36)}
    ${vw('height', 18, 22, 36)}
    &::after {
      content: '';
      display: block;
      background-color: ${({ theme }) => theme.color.brandGreen};
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      ${vw('width', 9, 11, 18)}
      ${vw('height', 9, 11, 18)}
    }
    @media ${media.desktop} {
      transition: 0.4s ease;
      &:hover {
        transform: scale(1.05);
      }
    }
  }
  div {
    position: absolute;
    z-index: 1;
    left: ${(props) => (props.mobileLeft ? 'auto' : '85%')};
    right: ${(props) => (props.mobileLeft ? '85%' : 'auto')};
    bottom: ${(props) => (props.mobileBottom ? 'auto' : '40%')};
    top: ${(props) => (props.mobileBottom ? '40%' : 'auto')};
    background-color: rgba(242, 247, 230, 0.85);
    white-space: nowrap;
    pointer-events: none;
    transition: 0.4s ease;
    ${vw('padding-top', 9, 10, 14)}
    ${vw('padding-bottom', 9, 9, 14)}
    ${vw('padding-left', 12, 24)}
    ${vw('padding-right', 12, 24)}
    @media ${media.tablet} {
      top: auto;
      bottom: 40%;
      left: ${(props) => (props.tabletLeft ? 'auto' : '85%')};
      right: ${(props) => (props.tabletLeft ? '85%' : 'auto')};
    }
    @media ${media.desktop} {
      left: 85%;
      right: auto;
    }
  }
`;

export default Dollhouse;
