import React from 'react';
import styled from 'styled-components';

import vw, { vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Info = () => {
  return (
    <Root>
      <Shape
        src={require('src/assets/images/footer/yellow_shape.svg')}
        alt="yellow shape"
      />
      <Ascent
        src={require('src/assets/images/logo/ascent-logo-white.svg')}
        alt="Ascent Surrey City Centre"
      />
      <Container className="relative">
        <Wrapper>
          <div>
            <h2 className="footer">Maple Leaf Office</h2>
            <p className="footer">
              <a
                href="https://goo.gl/maps/TdcYniuFwU1GyqBN6"
                target="_blank"
                rel="noopener noreferrer"
              >
                13018 84 Ave, <br/>
                Surrey, BC V3W 1L2
              </a>
            </p>
          </div>
          <div>
            <h2 className="footer">Contact Us</h2>
            <a href="tel:604-498-8215">
              <p className="footer">604-498-8215</p>
            </a>
            <a href="mailto:info@OwnYourAscent.com">
              <p className="footer">info@OwnYourAscent.com</p>
            </a>
          </div>
        </Wrapper>
      </Container>
      <Container end>
        <Logo className="relative">
          <a
            href="https://mlhcan.ca/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={require('src/assets/images/logo/developer_logo.svg')}
              alt="Maple Leaf Homes"
              className="maple"
            />
          </a>
          <a
            href="https://rennie.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={require('src/assets/images/logo/marketing_logo.svg')}
              alt="Marketing by Rennie"
              className="rennie"
            />
          </a>
        </Logo>
        <p className="footer disclaimer tall relative">
          © 2021 Maple Leaf Homes. All Rights Reserved.
        </p>
      </Container>
      <p className="footer disclaimer relative">
        This is not an offering for sale. Any such offering can only be made
        with a disclosure statement. The developer reserves the right to make
        changes and modifications to the information contained herein without
        prior notice. Artist’s renderings and maps are representations only and
        may not be accurate. E.&amp;O.E.
      </p>
    </Root>
  );
};

const Root = styled.div`
  background-color: ${({ theme }) => theme.color.brandYellow};
  position: relative;
  width: 100%;
  overflow: hidden;
  ${vw('padding-top', 24, 22, 52)}
  ${vw('padding-bottom', 24, 16, 30)}
  ${vw('padding-left', 38, 30, 80)}
  ${vw('padding-right', 38, 30, 80)}
  h2 {
    ${vw('margin-bottom', 8, 8, 20)}
  }
  .disclaimer {
    ${vw('margin-top', 10, 12, 20)}
  }
  .tall {
    ${vw('margin-top', 10, 12, 0)}
    ${vw('margin-left', 0, 0, 70)}
  }
  @media ${media.tablet} {
    background-color: ${({ theme }) => theme.color.brandGrey};
    .relative {
      position: relative;
      z-index: 2;
    }
    .disclaimer {
      max-width: ${vwTablet(432)};
    }
  }
  @media ${media.desktop} {
    .disclaimer {
      max-width: ${vwDesktop(902)};
    }
    a {
      transition: 0.4s ease;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const Wrapper = styled.div`
  ${vw('padding-left', 19, 0, 0)}
  // Added padding bottom with removal of map
  ${vw('padding-bottom', 40, 0)}
  > div {
    ${vw('margin-bottom', 23, 0)}
  }
  > div:first-of-type {
    ${vw('margin-right', 0, 25, 125)}
  }
  @media ${media.tablet} {
    order: 2;
    display: flex;
  }
`;

const Container = styled.div`
  @media ${media.tablet} {
    display: ${(props) => (props.end ? 'inline-block' : 'flex')};
    align-items: ${(props) => (props.end ? 'flex-end' : 'flex-start')};
  }
  @media ${media.desktop} {
    display: flex;
  }
`;

const Map = styled.img`
  width: 100%;
  display: block;
  ${vw('margin-top', 63, 0)}
  ${vw('margin-bottom', 63, 0)}
  ${vw('margin-right', 0, 40, 165)}
  @media ${media.tablet} {
    width: ${vwTablet(160)};
    order: 1;
  }
  @media ${media.desktop} {
    width: ${vwDesktop(160)};
  }
`;

const Shape = styled.img`
  display: none;
  @media ${media.tablet} {
    display: block;
    width: auto;
    height: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: ${vwTablet(-350)};
    z-index: 1;
  }
  @media ${media.desktop} {
    object-fit: cover;
    left: ${vwDesktop(-100)};
    height: 100%;
  }
`;

const Ascent = styled.img`
  display: none;
  ${vw('width', 103, 103, 132)}
  ${vw('top', 0, 22, 46)}
  ${vw('right', 0, 30, 80)}
  @media ${media.tablet} {
    display: block;
    position: absolute;
  }
`;

const Logo = styled.div`
  display: flex;
  align-items: flex-end;
  ${vw('margin-top', 0, 38, 78)}
  img {
    display: block;
  }
  .maple {
    ${vw('width', 94, 123, 141)}
    ${vw('margin-right', 28, 32, 37)}
  }
  .rennie {
    ${vw('width', 55, 72, 83)}
  }
`;

export default Info;
