import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const StickyBox = (props) => {
  const { $ref } = props;
  const $boxRef = useRef();
  const browser = useSelector((state) => state.browser);

  useEffect(() => {
    // window.addEventListener('scroll', setPosition)
    // return window.removeEventListener('animationend', setPosition);
  }, [browser])

  const setPosition = () => {
    if (!browser.is.mobile) {
      if ($ref.current.getBoundingClientRect().top < window.innerHeight) {
        $boxRef.current.style.position = 'absolute';
      } else {
        $boxRef.current.style.position = 'fixed';
      }
    }
  }
  return (
    <Root ref={$boxRef}>
      <h3>Sold out</h3>
      <p>Thank you to everyone who participated in one of Surrey's most successful highrise communities!</p>
    </Root>
  )
}

export default StickyBox;

const Root = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.color.brandYellow};
  z-index: 5;
  ${vw('width', 320, 273, 280)}
  /* ${vw('height', 142, 176, 188)} */
  ${vw('padding-top', 16, 16, 16)}
  ${vw('padding-right', 48, 27, 30)}
  ${vw('padding-bottom', 16, 16, 16)}
  ${vw('padding-left', 50, 26, 29)}
  h3 {
    text-transform: uppercase;
    ${vw('font-size', 14, 14, 16)}
    ${vw('line-height', 22, 20, 22)}
    ${vw('letter-spacing', 0.3, 0.5, 0.3)}
    ${vw('margin-bottom', 4, 4, 4)}
  }
  p {
    ${vw('font-size', 12, 12, 14)}
    ${vw('line-height', 18, 18, 22)}
  }
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    text-transform: uppercase;
    background-color: ${({ theme }) => theme.color.brandGreen};
    position: relative;
    ${vw('width', 222, 221, 221)}
    ${vw('height', 32, 48, 48)}
    ${vw('font-size', 12, 14, 14)}
    ${vw('letter-spacing', 0.5, 0.5, 0.5)}
    ${vw('margin-top', 16, 16, 16)}
    @media ${media.desktop} {
      box-shadow: none;
      transition: 0.3s ease;
      cursor: pointer;
      &::after {
        content: '';
        position: absolute;
        z-index: -1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transform: scaleX(0.5) skew(-45deg);
        transform-origin: 50% 50%;
        background-color: ${({ theme }) => theme.color.brandGreen};
        transition: transform 0s ease 0.4s, opacity 0.3s ease 0s;
      }
      &:hover::after {
        opacity: 1;
        transform: scaleX(1) skew(-45deg);
        transition: all 0.4s ease 0s;
      }
      &:hover{
        background-color: transparent;
      }
    }
  }
`;