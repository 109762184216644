import React from 'react';
import styled, { keyframes } from 'styled-components';

import data from 'src/data/floorplates';
import Floorplate from '../elements/Floorplate';
import Building from '../elements/Building';

import vw, { vwDesktop } from 'src/styles/utils';

const ExploreByLevel = (props) => {
  const { activeLevel, handleLevel, handleUnit, active, filters, activeFilter} = props;
  return (
    <Root active={active}>
      <Building
        activeLevel={activeLevel}
        handleLevel={handleLevel}
      />
      <StyledFloorplate
        plate={data[activeLevel].component}
        handleUnit={handleUnit}
        filters={filters}
        activeFilter={activeFilter}
      />
    </Root>
  )
}

export default ExploreByLevel;

const load = keyframes`
  from {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }
  to {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
`;

const Root = styled.div`
  animation: ${load} 0.3s linear;
  ${vw('padding-top', 46)}
  ${vw('padding-bottom', 46)}
  ${vw('padding-left', 80)}
`;

const StyledFloorplate = styled(Floorplate)`
  position: absolute;
  top: ${vwDesktop(164)};
  right: ${vwDesktop(74)};
  

  svg {
    width: ${vwDesktop(720)};
    height: ${vwDesktop(470)};
  }
`;