import React from 'react';
import styled, { css } from 'styled-components';

import vw from 'src/styles/utils';

const Heading = (props) => {
  const { small } = props;
  return (
    <Root small={small} {...props} >
      <h2>{props.subtitle}</h2>
      <h1>{props.title}</h1>
    </Root>
  )
}

export default Heading;

const Root = styled.div`
  text-align: center;
  ${vw('margin-top', 50, 87, 96)}
  ${vw('padding-top', 48, 72, 80)}
  ${vw('margin-bottom', 48, 72, 120)}
  h2 {
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    line-height: normal;
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.brandGrey};
    ${vw('font-size', 12, 14, 20)}
    ${vw('letter-spacing', 0.4, 0.7, 1)}
    ${vw('margin-bottom', 4, 8, 16)}
  }
  h1 {
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.brandGreen};
    ${vw('font-size', 22, 36, 64)}
    ${vw('line-height', 24, 42, 72)}
    ${vw('letter-spacing', 0.55, 1, 1)}
    ${props => props.small && css`
      ${vw('font-size', 18, 30, 40)}
      ${vw('line-height', 20, 36, 48)}
    `}
  }
`;