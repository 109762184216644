import React, { useEffect, useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import {
  disableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';

import data from 'src/data/floorplans/floorplans';
import levels from 'src/data/floorplans/levels';
import View360 from 'src/components/View360';
import Compass from '../elements/Compass';

import vw, { vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Views = (props) => {
  const [activeLevel, setActiveLevel] = useState(0);
  const [angle, setAngle] = useState(180);
  const [swiped, setSwiped] = useState(false);
  const [plan, setPlan] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const browser = useSelector((state) => state.browser);
  const params = useParams();
  const history = useHistory();
  const $ref = useRef();

  useEffect(() => {
    disableBodyScroll($ref.current);
    if (params.id) {
      if (data.find(plan => plan.id === params.id)) {
        setPlan(data.filter(plan => plan.id === params.id)[0])
        if (typeof data.filter(plan => plan.id === params.id)[0] !== 'undefined') {
          setActiveLevel(data.filter(plan => plan.id === params.id)[0].views.defaultIndex);
          setAngle(data.filter(plan => plan.id === params.id)[0].views.defaultAngle);
        }
      } else {
        history.push('/404');
      }
    }
  },[params.id])

  const handleUpdate = (val) => {
    setAngle(val);
  }

  const onStart = () => {
    setSwiped(true);
  }

  const handleSelect = (index) => {
    setActiveLevel(index);
    setIsDropdownOpen(false);
  }

  const handleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  }

  const handleClose = () => {
    if (typeof history.location.state === 'undefined') {
      console.log('hello');
      history.push(`/floorplans/${params.id}`);
    } else {
      history.push({
        pathname: `/floorplans/${params.id}`,
        state: {
          activeFilter: history.location.state.activeFilter,
          activeLevel: history.location.state.activeLevel,
          activeTab: history.location.state.activeTab,
        }
      })
    }
    clearAllBodyScrollLocks();
  }

  return (
    <Root ref={$ref}>
      <Close 
        src={require('src/assets/images/icons/modal_close.svg')} alt='' 
        onClick={handleClose}
      />
      {plan &&
        <Container>
          <Details>
            <div className='plan-info'>
              <h1>{plan.id}</h1>
              <p>{plan.bed > 0 ? `${plan.bed} bed` : 'studio'} {plan.den && `+ den`}</p>
            </div>
            <Line
              src={require('src/assets/images/icons/green_diagonal_line.svg')}
              alt="Line"
            />
            <div className='plan-details'>
              <p><span>Interior:</span> {plan.interior} SQFT</p>
              <p><span>Balcony:</span> {plan.balcony} SQFT</p>
              <p><span>Total:</span> {plan.size} SQFT</p>
            </div>
          </Details>
          <ViewsContainer>
            <Compass 
              angle={angle}
            />
            <Swiped swiped={swiped}>&lt;<span>Swipe to Pan the View</span>&gt;</Swiped>
            <View360
              src={levels.views[activeLevel].img}
              onUpdate={handleUpdate}
              onStart={onStart}
              defaultAngle={angle}
            />
          </ViewsContainer>

          <Thumbnails>
            {plan.thumb1 &&
              <Thumb src={require(`src/assets/images/floorplans/thumbs/${plan.thumb1}`)} alt='' />
            }
            {plan.thumb2 &&
              <Thumb src={require(`src/assets/images/floorplans/thumbs/${plan.thumb2}`)} alt='' />
            }
            {plan.thumb3 &&
              <Thumb src={require(`src/assets/images/floorplans/thumbs/${plan.thumb3}`)} alt='' />
            }
            <North src={require('src/assets/images/floorplans/north/icon_north.svg')} alt='' />
          </Thumbnails>

          <Select>
            <span>Select Level</span>
            <Button
              onClick={handleDropdown}
              disable
            >
              {levels.views[activeLevel].label}
              <img 
                src={browser.is.desktop ? 
                require('src/assets/images/icons/dropdown_arrow_white.svg') :
                require('src/assets/images/icons/dropdown_arrow.svg')} 
                alt='' 
                style={{
                  transform: isDropdownOpen ? 'rotate(180deg)' : '',
                }}
              />
            </Button>
            <ButtonsWrapper
              isOpen={isDropdownOpen}
            >
              {levels.views.map((level, index) => (
                <Button
                  key={index}
                  onClick={() => handleSelect(index)}
                  disabled={!level.units.includes(plan.id)}
                >
                {level.label}
                </Button>
              ))}
            </ButtonsWrapper>
          </Select>
        </Container>
      }
    </Root>
  )
}

export default Views;

const Root = styled.div`
  position: relative;
  width: 100vw;
  min-height: 100vh;
  position: fixed;
  background-color: white;
  z-index: 9999;
  overflow-y: scroll;
  ${vw('padding-top', 58, 100, 49)}
  ${vw('padding-right', 12, 31)}
  ${vw('padding-bottom', 48, 39, 0)}
  ${vw('padding-left', 13, 30, 74)}
`;

const Close = styled.img`
  cursor: pointer;
  position: fixed;
  z-index: 1;
  ${vw('width', 9, 14, 27)}
  ${vw('top', 19.5, 31, 33)}
  ${vw('right', 17, 36, 83)}
`;

const Container = styled.div``;

const Details = styled.div`
  display: flex;
  ${vw('margin-bottom', 24, 40)}
  .plan-info {
    ${vw('margin-right', 90, 288)}
    h1 {
      text-transform: uppercase;
      font-weight: bold;
      color: ${({ theme }) => theme.color.brandGreen};
      line-height: normal;
      ${vw('font-size', 44, 58, 64)}
      ${vw('width', 85, 113, 124)}
      ${vw('margin-bottom', 0, 5)}
    }
    p {
      text-transform: uppercase;
      font-weight: bold;
      color: ${({ theme }) => theme.color.darkGrey};
      ${vw('font-size', 12, 14, 14)}
      ${vw('letter-spacing', 1.13, 1, 1.31)}
    }
  }
  .plan-details {
    ${vw('margin-top', 10, 21)}
    p {
      display: flex;
      flex-direction: column;
      color: ${({ theme }) => theme.color.darkGrey};
      text-transform: uppercase;
      line-height: normal;
      ${vw('font-size', 12, 14, 14)}
      ${vw('letter-spacing', 0.5, 1, 1.31)}
      &:not(:last-of-type) {
        ${vw('margin-bottom', 10, 0, 0)} 
      }
      &:last-of-type {
        font-weight: bold;
        ${vw('margin-top', 0, 2, 4)}
      }
      span {
        display: inline-block;
        ${vw('width', 91, 76, 90)}
        ${vw('margin-right', 0, 10, 0)}
      }
    }
  }
  @media ${media.tablet} {
    .plan-details {
      display: none;
    }
  }
  @media ${media.desktop} {
    flex-direction: column;
  }
`;

const Line = styled.img`
  position: absolute;
  ${vw('width', 27, 60, 60)}
  ${vw('top', 69, 100, 59)}
  ${vw('left', 131, 160, 257)}
`;

const ViewsContainer = styled.div`
  position: relative;
  ${vw('width', 295, 707, 1072)}
  ${vw('height', 158, 380, 592)}
  ${vw('margin-bottom', 24, 40, 0)}
  @media ${media.desktop} {
    position: absolute;
    border-bottom: 16px solid transparent;
    top: ${vwDesktop(208)};
    left: ${vwDesktop(294)};
  }
`;

const Swiped = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  opacity: ${props => props.swiped ? '0' : '1'};
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  transition: all 0.3s ease;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  ${vw('top', 72, 46, 79)}
  ${vw('width', 174, 216, 318)}
  ${vw('font-size', 10, 12, 14)}
  ${vw('letter-spacing', 0.5, 0.8, 1.2)}
`;

const Thumbnails = styled.div`
  display: flex;
  flex-direction: column;
`;

const Thumb = styled.img`
  ${vw('width', 80, 96, 116)}
  ${vw('margin-bottom', 20, 20, 32)}
`;

const North = styled.img`
  ${vw('width', 40, 40, 40)}
`;

const Select = styled.div`
  position: absolute;
  ${vw('top', 387, 145, 96)}
  ${vw('right', 13, 31, 74)}
  span {
    display: inline-block;
    line-height: normal;
    text-transform: uppercase;
    font-weight: bold;
    ${vw('font-size', 10, 12, 14)}
    ${vw('letter-spacing', 0.5, 0.8, 1.2)}
    ${vw('margin-bottom', 8, 8, 8)}
  }
`;

const ButtonsWrapper = styled.div`
  overflow-y: scroll;
  ${props => props.isOpen ? vw('max-height', 78, 480, 640) : vw('max-height', 0)};
  transition: all 0.3s ease;
  @media ${media.tablet} {
    overflow-y: hidden;
  }
`;


const Button = styled.div`
  cursor: pointer;
  position: relative;
  background-color: ${({ theme }) => theme.color.brandGrey};
  color: white;
  display: ${props => props.disabled ? 'none' : 'flex'};
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.color.brandGreen};
  font-weight: bold;
  ${vw('font-size', 10, 12, 14)}
    ${vw('letter-spacing', 0.5, 0.8, 1.2)}
  ${vw('width', 125, 125, 125)}
  ${vw('height', 26, 30, 40)}
  ${vw('padding-left', 11, 11, 14)}
  img {
    position: absolute;
    ${vw('right', 16, 16, 10)}
    ${vw('width', 9, 11, 12)}
  }
  @media ${media.desktop} {
    ${props => props.disable && css`
      background-color: ${({ theme }) => theme.color.darkGrey};
      border-bottom: none;
    `}
    
    ${props => !props.disable && css`
      &:hover {
        background-color: ${({ theme }) => theme.color.brandGreen};
        color: ${({ theme }) => theme.color.darkGrey};
      }
    `}
  }
`;

/*
  Desktop: 16 * 40
  Tablet: 16 * 30
  Mobile: 16 * 26
*/