import React from 'react';
import styled from 'styled-components';

import Hero from 'components/Hero';
import Intro from 'components/Layout/Intro';
import Stats from './sections/Stats';
import Location from './sections/Location';

const Neighbourhood = () => {
  return (
    <Root>
      <Hero
        image={require('src/assets/images/neighbourhood/hero_rendering.jpg')}
        mobileImage={require('src/assets/images/neighbourhood/mobile_hero_rendering.jpg')}
        title="A RISING DOWNTOWN"
        subtitle="NEIGHBOURHOOD"
      />
      <Intro
        title="SURREY CITY CENTRE"
        text="Surrey’s urban centre is thriving–a hub for innovative industries and prestigious university campuses; actively programmed parks interspersed between restaurants and retail; continued investment in transportation networks and public infrastructure."
        tablet={302}
        desktop={551}
      />
      <Stats />
      <Location />
    </Root>
  );
};

const Root = styled.div``;

export default Neighbourhood;
