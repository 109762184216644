import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, useParams, useHistory } from 'react-router-dom';

import data from 'src/data/floorplans/floorplans';
import Button from 'components/Button';

import vw, { vwMobile, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const SinglePlan = () => {
  const [plan, setPlan] = useState(null);
  const [isViewsOpen, setIsViewsOpen] = useState(false);
  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    if (params.id) {
      if (data.find(plan => plan.id === params.id)) {
        setPlan(data.filter(plan => plan.id === params.id)[0])
      } else {
        history.push('/404');
      }
    }
  }, [params.id])
  return (
    
    <Root>
      <StyledLink 
        to={
          typeof history.location.state === 'undefined' ?
          '/floorplans' :
          {
            pathname: '/floorplans',
            state: {
              activeFilter: history.location.state.activeFilter,
              activeLevel: history.location.state.activeLevel,
              activeTab: history.location.state.activeTab,
            }
          }
        }
      >
        <img src={require('src/assets/images/icons/back_arrow.svg')} alt='' />
        Back To Floorplan Selection
      </StyledLink>
      {plan && 
        <Container>
          <Details>
            <div className='plan-info'>
              <h1>{plan.id}</h1>
              <p>{plan.bed > 0 ? `${plan.bed} bed` : 'studio'} {plan.den && `+ den`}</p>
            </div>
            <Line
              src={require('src/assets/images/icons/green_diagonal_line.svg')}
              alt="Line"
            />
            <div className='plan-details'>
              <p><span>Interior:</span> {plan.interior} SQFT</p>
              <p><span>Balcony:</span> {plan.balcony} SQFT</p>
              <p><span>Total:</span> {plan.size} SQFT</p>
            </div>
          </Details>

        <Plan src={require(`src/assets/images/floorplans/plans/${plan.id}.png`)} alt='' />

        <Section>
          <Thumbnails>
            {plan.thumb1 &&
              <Thumb src={require(`src/assets/images/floorplans/thumbs/${plan.thumb1}`)} alt='' />
            }
            {plan.thumb2 &&
              <Thumb src={require(`src/assets/images/floorplans/thumbs/${plan.thumb2}`)} alt='' />
            }
            {plan.thumb3 &&
              <Thumb src={require(`src/assets/images/floorplans/thumbs/${plan.thumb3}`)} alt='' />
            }
            <Compass src={require('src/assets/images/floorplans/north/icon_north.svg')} alt='' />
          </Thumbnails>

            <Buttons>
              <Button 
                to={`/pdfs/plans/${plan.id}.pdf`}
                text='Download PDF'
                target="_blank"
                download
              />
              {typeof plan.views !== 'undefined' &&
                <Button 
                  to={
                    typeof history.location.state === 'undefined' ?
                    `/floorplans/${plan.id}/views` :
                    {
                      pathname: `/floorplans/${plan.id}/views`,
                      state: {
                        activeFilter: history.location.state.activeFilter,
                        activeLevel: history.location.state.activeLevel,
                        activeTab: history.location.state.activeTab,
                      }
                    }
                  }
                  text='Enjoy the View'
                />
              }
            </Buttons>
          </Section>
        </Container>
      }
    </Root>
  )
}

export default SinglePlan;

const Root = styled.div`
  ${vw('padding-top', 50, 87, 96)}
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  font-weight: bold;
  color: ${({ theme }) => theme.color.darkGrey};
  text-transform: uppercase;
  ${vw('font-size', 10, 12, 16)}
  ${vw('letter-spacing', 0.5, 0.8, 2)}
  ${vw('margin-top', 42, 42, 51)}
  ${vw('margin-left', 17, 32, 83)}
  ${vw('margin-bottom', 40, 40, 48)}
  img {
    ${vw('width', 10, 12, 15)}
    ${vw('margin-right', 10, 7, 9)}
  }
`;

const Container = styled.div`
  background-color: ${({ theme }) => theme.color.lightGreen};
  position: relative;
  ${vw('padding-top', 16, 40, 80)}
  ${vw('padding-right', 13, 31, 117)}
  ${vw('padding-bottom', 48, 40, 160)}
  ${vw('padding-left', 12, 30, 74)}
  ${vw('margin-bottom', 56, 72, 120)}
  @media ${media.desktop} {
    display: flex;
  }
`;

const Details = styled.div`
  display: flex;
  ${vw('margin-bottom', 16, 40)}
  ${vw('padding-left', 0, 64, 0)}
  .plan-info {
    ${vw('margin-right', 90, 288)}
    h1 {
      text-transform: uppercase;
      font-weight: bold;
      color: ${({ theme }) => theme.color.brandGreen};
      line-height: normal;
      ${vw('font-size', 44, 58, 64)}
      ${vw('width', 85, 113, 124)}
      ${vw('margin-bottom', 0, 2)}
    }
    p {
      text-transform: uppercase;
      font-weight: bold;
      color: ${({ theme }) => theme.color.darkGrey};
      ${vw('font-size', 12, 14, 14)}
      ${vw('letter-spacing', 1.13, 1, 1.31)}
    }
  }
  .plan-details {
    ${vw('margin-top', 10, 21)}
    p {
      display: flex;
      flex-direction: column;
      color: ${({ theme }) => theme.color.darkGrey};
      text-transform: uppercase;
      line-height: normal;
      ${vw('font-size', 12, 14, 14)}
      ${vw('letter-spacing', 0.5, 1, 1.31)}
      &:not(:last-of-type) {
        ${vw('margin-bottom', 10, 0, 0)} 
      }
      &:last-of-type {
        font-weight: bold;
        ${vw('margin-top', 0, 2, 4)}
      }
      span {
        display: inline-block;
        ${vw('font-size', 12, 14, 16)}
        ${vw('width', 91, 76, 90)}
        ${vw('margin-right', 0, 10, 8)}
      }
    }
  }
  @media ${media.tablet} {
    align-items: flex-end;
    .plan-details {
      p {
        flex-direction: row;
      }
    }
  }
  @media ${media.desktop} {
    align-items: flex-start;
    flex-direction: column;
  }
`;

const Line = styled.img`
  position: absolute;
  ${vw('width', 27, 60, 60)}
  ${vw('top', 27, 45, 90)}
  ${vw('left', 130, 354, 257)}
`;

const Plan = styled.img`
  display: block;
  ${vw('width', 295, 707, 882)}
  ${vw('height', 295, 707, 882)}
  ${vw('margin-bottom', 17, 40, 0)}
`;

const Section = styled.div`
  display: flex;
  ${vw('padding-left', 0, 64, 0)}
  @media ${media.desktop} {
    position: absolute;
    flex-direction: column;
    top: ${vwDesktop(348)};
    left: ${vwDesktop(74)};
  }
`;

const Thumbnails = styled.div`
  display: flex;
  flex-direction: column;
  ${vw('margin-right', 56, 85, 0)}
  ${vw('margin-bottom', 0, 0, 68)}
`;

const Thumb = styled.img`
  ${vw('width', 80, 96, 116)}
  ${vw('margin-bottom', 20, 20, 32)}
`;

const Compass = styled.img`
  ${vw('width', 40, 40, 40)}
`;

const Buttons = styled.div`
  display: grid;
  grid-template-rows: ${`repeat(2, ${vwMobile(24)})`};
  ${vw('grid-row-gap', 24, 0, 24)}
  ${vw('grid-column-gap', 0, 55, 0)}
  @media ${media.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${media.desktop} {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: ${`repeat(2, ${vwDesktop(40)})`};
  }
`;