import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import data from 'src/data/team';
import Slider from 'react-slick';


import vw, { vwTablet, vwDesktop} from 'src/styles/utils';
import media from 'src/styles/media';

const Grid = () => {
  const browser = useSelector((state) => state.browser);
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  }

  return (
    <Root>
      <Featured 
        src={require('src/assets/images/team/ascent_horizon_rendering.jpg')}
        mobile={require('src/assets/images/team/ascent_horizon_rendering_mobile.jpg')}
      >
        <img src={require('src/assets/images/team/delta_rise_indicator.svg')} alt='' />
        <span>Delta Rise</span>
      </Featured>
      
      {browser.is.mobile && 
        <SliderWrapper>
          <Slider {...settings}>
            {data.projects.map((project, index) => (
              <Project src={project.image} key={index}>
                <span>{project.name}</span>
              </Project>
            ))}
          </Slider>
        </SliderWrapper>
      }

      {!browser.is.mobile && 
        <Cards>
          {data.projects.map((project, index) => (
            <Project src={project.image} key={index}>
              <span>{project.name}</span>
            </Project>
          ))}
        </Cards> 
      }

      <Companies>
        {data.team.map((company, index) => (
          <Company key={index}>
            <Container>
              <h2>{company.name}</h2>
              <p>{company.description}</p>
            </Container>
            <img src={company.logo} alt={company.name} />
          </Company>
        ))}
      </Companies>
    </Root>
  )
}

export default Grid;

const Root = styled.div`

`;

const Featured = styled.div`
  background: url(${props => props.mobile});
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  ${vw('width', 295, 707, 1279)}
  ${vw('height', 200, 110, 200)}
  ${vw('margin-bottom', 67, 17, 30)}
  img {
    position: absolute;
    transform: scaleX(-1);
    ${vw('width', 27, 21, 39)}
    ${vw('top', 76, 23, 44)}
    ${vw('left', 34, 135, 244)}
  }
  span {
    display: inline-block;
    font-weight: bold;
    text-transform: uppercase;
    position: absolute;
    ${vw('top', 74, 20, 44)}
    ${vw('left', 65, 56, 151)}
    ${vw('font-size', 10, 12, 14)}
    ${vw('line-height', 10, 12, 17)}
    ${vw('letter-spacing', 0.5, 0.8, 1.2)}
  }
  @media ${media.tablet} {
    background: url(${props => props.src});
    background-size: cover;
    background-repeat: no-repeat;
    img {
      transform: scaleX(1);
    }
  }
`;

const SliderWrapper = styled.div`
  ${vw('margin-bottom', 56, 0)}
`;

const Project = styled.div`
  position: relative;
  background: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  ${vw('width', 295, 225, 407)}
  ${vw('height', 290, 221, 400)}
  span {
    display: inline-block;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    position: absolute;
    ${vw('left', 8, 9, 16)}
    ${vw('bottom', 19, 10, 17)}
    ${vw('font-size', 8, 12, 14)}
    ${vw('letter-spacing', 0.4, 0.8, 1.2)}
    br {
      display: none;
    }
    @media ${media.tablet} {
      br {
        display: block;
      }
    }
    @media ${media.desktop} {
      br {
        display: none;
      }
    }
  }
`;

const Cards = styled.div`
  display: flex;
  justify-content: space-between;
  ${vw('margin-bottom', 0, 72, 120)}
`;

const Companies = styled.div`
  ${vw('padding-bottom', 14, 32, 0)}
`;

const Company = styled.div`
  ${vw('padding-bottom', 40, 40, 120)}
  &:nth-of-type(1) {
    img {
      ${vw('width', 93, 81, 159)}
    }
  }
  &:nth-of-type(2) {
    img {
      ${vw('width', 146, 124, 248)}
    }
  }
    &:nth-of-type(3) {
      img {
        ${vw('width', 111, 95, 188)}
      }
    }
  @media ${media.tablet} {
    display: grid;
    grid-template-areas: 'logo description';
    grid-template-columns: ${vwTablet(124)} auto;
    grid-column-gap: ${vwTablet(56)};
  }
  @media ${media.desktop} {
    grid-template-columns: ${vwDesktop(248)} auto;
    grid-column-gap: ${vwDesktop(78)};
  }
`;

const Container = styled.div`
  h2 {
    font-weight: bold;
    color: ${({ theme }) => theme.color.brandGreen};
    line-height: normal;
    ${vw('font-size', 20, 14, 16)}
    ${vw('margin-bottom', 16, 8, 16)}
  }
  p {
    ${vw('margin-bottom', 24, 0)}
  }
  @media ${media.tablet} {
    grid-area: description;
  }
`;