import React from 'react';
import styled from 'styled-components';

import Hero from 'components/Hero';
import Intro from 'components/Layout/Intro';
import Scheme from './elements/Scheme';
import Features from './sections/Features';
import data from 'src/data/interiors';


const Interiors = () => {
  return (
    <Root>
      <Hero
        image={require('src/assets/images/interiors/interiors_hero.jpg')}
        mobileImage={require('src/assets/images/interiors/mobile_interiors_hero.jpg')}
        title='HOMES THAT GET YOU MORE'
        shortTitle
        subtitle="INTERIORS"
      />
      <Intro
        title={<>More Space, <br/> More comfort</>}
        largeTitle
        text="Ascent offers homes that let you choose what matters most to you–an oversized balcony, or a den for a home office, or a dining table that can comfortably seat six. These generous layouts focus on seamless design and functionality with thoughtful storage solutions and sleek integrated appliances."
        tablet={304}
        desktop={521}
      />
      <Scheme 
        data={data.living}
      />
      <Scheme
        data={data.bathroom}
      />
      <Features 
        data={data.features}
      />
    </Root>
  )
}

export default Interiors

const Root = styled.div``;