import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { useCountUp } from 'react-countup';
import { Waypoint } from 'react-waypoint';

import vw, { vwTablet, vwDesktop, formatNumber } from 'src/styles/utils';
import media from 'src/styles/media';

const StatCard = (props) => {
  const { data } = props;

  const [hasStarted, setHasStarted] = useState(false);

  const { countUp, start } = useCountUp({
    start: 0,
    end: data.number,
    duration: 2,
    suffix: data.suffix || '',
  });

  const handleEnter = () => {
    if (!hasStarted) {
      start();
      setHasStarted(true);
    }
  };

  return (
    <Waypoint onEnter={() => handleEnter()} bottomOffset="10%">
      <Root lessPadding={data.lessPadding} morePadding={data.morePadding}>
        <img src={data.image} alt="Icon" />
        <div>
          <h3 className="stats grey">{formatNumber(countUp)}</h3>
          <p className="darkGrey">{data.text}</p>
        </div>
      </Root>
    </Waypoint>
  );
};

StatCard.propTypes = {
  data: PropTypes.object,
};

const Root = styled.div`
  background-color: ${({ theme }) => theme.color.lightGreen};
  display: flex;
  ${vw('padding-left', 20, 18, 42)}
  ${vw('padding-right', 20, 18, 42)}
  ${props => props.lessPadding && css`
    ${vw('padding-left', 20, 9, 42)}
    ${vw('padding-right', 20, 9, 42)}
  `}
  ${props => props.morePadding && css`
    ${vw('padding-left', 20, 9, 62)}
    ${vw('padding-right', 20, 9, 61)}
  `}
  ${vw('padding-top', 35, 20, 70)}
  ${vw('padding-bottom', 30, 18, 48)}
  ${vw('margin-bottom', 24, 0)}
  &:last-of-type {
    margin-bottom: 0;
  }
  h3 {
    white-space: nowrap;
    ${vw('margin-bottom', 2, 8, 20)}
  }
  p {
    letter-spacing: normal;
    ${vw('font-size', 12, 13, 16)}
    ${vw('line-height', 16, 21, 26)}
  }
  img {
    display: block;
    ${vw('width', 72, 63, 109)}
    ${vw('margin-right', 33, 0)}
    ${vw('margin-bottom', 0, 24, 38)}
  }
  @media ${media.tablet} {
    width: ${vwTablet(222)};
    height: ${vwTablet(241)};
    flex-direction: column;
    align-items: center;
    p,
    h3 {
      text-align: center;
    }
  }
  @media ${media.desktop} {
    width: ${vwDesktop(340)};
    height: ${vwDesktop(441)};
  }
`;

export default StatCard;
