import React from 'react';

export default [
  {
    title: (
      <>
        AN ARRIVAL THAT
        <br /> ENCOURAGES YOU <br />
        TO EXPLORE
      </>
    ),
    subtitle: 'LOBBY',
    text:
      'Ascent welcomes you and your guests home, with its airy double-height lobby and organic design details that bring the outdoors in. From here, a robust collection of indoor and outdoor amenities are yours to explore, beginning at the statement, floating staircase leading to an innovative co-working space, and beyond.',
    image: require('src/assets/images/amenities/lobby_rendering.jpg'),
    sizes: {
      image: {
        tablet: 362,
        desktop: 625,
      },
      text: {
        tablet: 285,
        desktop: 451,
      },
    },
    reverse: false,
  },
  {
    title: (
      <>
        SPACE WORTH
        <br />
        CELEBRATING IN
      </>
    ),
    subtitle: 'PARTY ROOM',
    text: `From day to night, Ascent is ready for you to host and entertain around all of life's milestones. The Party Room is the perfect space to invite friends and family, connect over good food and conversation, and celebrate together.`,
    image: require('src/assets/images/amenities/dining_rendering.jpg'),
    sizes: {
      image: {
        tablet: 384,
        desktop: 655,
      },
      text: {
        tablet: 243,
        desktop: 494,
      },
      margin: {
        tablet: 40,
        desktop: 140,
      },
    },
    reverse: true,
  },
  {
    title: (
      <>
        EXERCISE YOUR MIND,
        <br /> BODY, AND HEART
      </>
    ),
    subtitle: (
      <>
        FITNESS CENTRE,
        <br /> CO-WORK SPACE, SOCIAL LOUNGE
      </>
    ),
    text:
      'Try a new workout regimen in the Fitness Centre, collaborate on a business proposal in the Co-Work Space, or catch the hockey game with your neighbours in the Social Lounge–Ascent is an urban community where you can spend your whole day living, working, and playing from the comfort of home.',
    images: [
      require('src/assets/images/amenities/gym.jpg'),
      require('src/assets/images/amenities/co-working.jpg'),
      require('src/assets/images/amenities/social_lounge.jpg'),
    ],
    gallery: true,
    sizes: {
      image: {
        tablet: '100%',
      },
      text: {
        tablet: 303,
        desktop: 589,
      },
    },
    reverse: false,
    vertical: true,
  },
  {
    title: 'EXPLORE THE GREAT OUTDOORS HERE',
    subtitle: 'DINING TERRACE',
    text: `From the Level 1 children's play area, outdoor lounge, and dog run, to the Level 2 dining terrace, Ascent has a variety of opportunities for open-air recreation and relaxation. Cook up a new summer recipe on the BBQ, connect with friends by the fireside on the outdoor terrace, or simply take in the sunset in the cool of the evening.`,
    image: require('src/assets/images/amenities/outdoor_lounge_rendering.jpg'),
    sizes: {
      image: {
        tablet: 364,
        desktop: 625,
      },
      text: {
        tablet: 303,
        desktop: 469,
      },
    },
    reverse: false,
  },
  {
    title: (
      <>
        THE SKY
        <br className="tablet-only" /> IS THE LIMIT
      </>
    ),
    subtitle: 'ROOFTOP SUN TERRACE',
    text:
      'Catch your favourite sports game in the social lounge, or connect by the cozy fireside. Host a dinner in the party room and then retire outside for the evening. At greater heights, the beautifully landscaped sun terrace offers panoramic views to everyone in the building.',
    image: require('src/assets/images/amenities/rooftop_rendering.jpg'),
    sizes: {
      image: {
        tablet: 360,
        desktop: 694,
      },
      text: {
        tablet: 235,
        desktop: 476,
      },
      margin: {
        tablet: 72,
        desktop: 120,
      },
    },
    reverse: true,
  },
];
