import React from 'react';
import styled from 'styled-components';

import Hero from 'components/Hero';
import Intro from 'components/Layout/Intro';
import ContentBlock from './elements/ContentBlock';

const Home = () => {
  return (
    <Root>
      <Hero
        image={require('src/assets/images/home/hero_rendering.jpg')}
        mobileImage={require('src/assets/images/home/mobile_hero_rendering.jpg')}
        title={
          <>
            Your front door
            <br />
            to a rising downtown
          </>
        }
      />
      <Intro
        title="Own AT THE CENTRE"
        text="Ascent is a striking addition to the rapid growth of the Downtown Surrey skyline–at the top of what will soon be the largest city in British Columbia. Set just one block from the thoroughfare of King George Boulevard, Ascent stands at the top of this new downtown."
        tablet={283}
        desktop={524}
      />
      <ContentBlock
        image={require('src/assets/images/home/neighbourhood_rendering.jpg')}
        imageSize={{
          smallMobile: true,
          tablet: 384,
          desktop: 680,
        }}
        contentSize={{
          tablet: 324,
          desktop: 461,
        }}
        title="A RISING DOWNTOWN"
        subtitle="NEIGHBOURHOOD"
        text="Surrey’s urban centre is thriving–a hub for innovative industries and prestigious university campuses; actively programmed parks interspersed between restaurants and retail; continued investment in transportation networks and public infrastructure."
        buttons={[
          {
            text: 'explore neighbourhood',
            to: '/neighbourhood',
          },
        ]}
      />
      <ContentBlock
        image={require('src/assets/images/home/amenities_rendering.jpg')}
        imageSize={{
          tablet: 430,
          desktop: 720,
        }}
        contentSize={{
          tablet: 208,
          desktop: 426,
        }}
        largeMargin
        reverse
        title={
          <>
            MORE AT YOUR <br /><br />
            FRONT DOOR
          </>
        }
        subtitle="AMENITIES"
        text="Spanning three floors and culminating at the top of the building, the amenities at Ascent offer endless choices for recreation and relaxation."
        buttons={[
          {
            text: 'DISCOVER AMENITIES',
            to: '/amenities',
          },
        ]}
      />

      <ContentBlock
        image={require('src/assets/images/home/interiors_rendering.jpg')}
        imageSize={{
          tablet: 454,
          desktop: 720,
        }}
        contentSize={{
          tablet: 231,
          desktop: 445,
        }}
        largeMargin
        title={
          <>
            HOMES THAT
            <br /> GET YOU MORE
          </>
        }
        subtitle="INTERIORS &amp; FLOORPLANS"
        text="These generous layouts focus on seamless design and functionality with thoughtful storage solutions and sleek integrated appliances."
        buttons={[
          {
            text: 'VIEW INTERIORS',
            to: '/interiors',
          },
          {
            text: 'BROWSE FLOORPLANS',
            to: '/floorplans',
          },
        ]}
      />
      <ContentBlock
        image={require('src/assets/images/home/team_rendering.jpg')}
        imageSize={{
          smallMobile: true,
          tablet: 367,
          desktop: 680,
        }}
        contentSize={{
          tablet: 333,
          desktop: 474,
        }}
        reverse
        title="A TRUSTED DEVELOPER"
        subtitle="Team"
        text="For over 30 years the Maple Leaf Homes team has contributed to the growth of community with multi-family and commercial developments, building a reputation of quality and trust."
        buttons={[
          {
            text: 'LEARN MORE',
            to: '/team',
          },
        ]}
      />
    </Root>
  );
};

const Root = styled.div``;

export default Home;
