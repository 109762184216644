import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useSelector } from 'react-redux';

import data from 'src/data/floorplans/floorplans';

import vw, { vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

const ExploreByType = (props) => {
  const { active, filters, activeFilter, handleUnit } = props;
  const browser = useSelector((state) => state.browser);

  return (
    <Root>
      <Grid>
        {data.sort((a, b) => {
          return (a.sold - b.sold)
        }).map((plan, index) => (
          <Card 
            key={index}
            onClick={() => handleUnit(plan.id)}
            highlight={activeFilter.some(filter => plan.type.includes(filter)) || activeFilter.length === 0}
            disable={plan.sold}
          >
            <Tag disable={plan.sold}>
              <img 
                src={
                  browser.is.mobile ? 
                  require('src/assets/images/floorplans/mobile_card_badge_shape.png') :
                  require('src/assets/images/floorplans/card_badge_shape.png') 
                } 
                alt='' />
              <span>Sold <br/> Out</span>
            </Tag>
            <Label
              highlight={activeFilter.some(filter => plan.type.includes(filter))}
              disable={plan.sold}
            >
              {plan.id}
            </Label>
            <Line
              src={plan.sold ? 
              require('src/assets/images/icons/grey_diagonal_line.svg') :
              require('src/assets/images/icons/green_diagonal_line.svg')
            }
              alt="Line"
            />
            <Info
              disable={plan.sold}
            >
              <p>{plan.bed > 0 ? `${plan.bed} bed` : 'studio'} {plan.den && `+ den`}</p>
              <p>Level {plan.levels}</p>
              <p>{plan.size} SQFT</p>
            </Info>
          </Card>
        ))}
      </Grid>
    </Root>
  )
}

export default ExploreByType;

const load = keyframes`
  from {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }
  to {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
`;

const Root = styled.div`
  animation: ${load} 0.3s linear;
  ${vw('padding-top', 88, 131, 187)}
  ${vw('padding-right', 12, 30, 74)}
  ${vw('padding-bottom', 48, 40, 80)}
  ${vw('padding-left', 12, 30, 74)}
`;

const Grid = styled.div`
  display: grid;
  ${vw('grid-row-gap', 12, 39, 48)}
  ${vw('grid-column-gap', 0, 39, 63)}
  @media ${media.tablet} {
    grid-template-columns: repeat(3, 1fr);
  }
  @media ${media.desktop} {
    grid-template-columns: repeat(5, 1fr);
  }
`;

const Card = styled.div`
  cursor: pointer;  
  pointer-events: ${props => props.disable ? 'none' : 'auto'};
  position: relative;
  display: ${props => props.highlight ? 'flex' : 'none'};
  background-color: #fff;
  border: 2px solid ${props => props.disable ? '#ddd' : props.theme.color.brandGrey};
  align-items: center;
  box-shadow: 0 10px 15px 0 rgba(95, 128, 36, 0.1);
  transition: all 0.3s ease;
  ${vw('width', 295, 210, 208)}
  ${vw('height', 80, 200, 208)}
  ${vw('padding-top', 0, 34, 31)}
  ${vw('padding-right', 0, 0)}
  ${vw('padding-left', 19, 19, 24)}
  &:hover {
    background-color: ${({ theme }) => theme.color.brandGreen};
    >div {
      color: ${({ theme }) => theme.color.darkGrey};
    }
  }
  @media ${media.tablet} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Label = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  color: ${props => props.disable ? '#ddd' : props.theme.color.brandGreen};
  width: ${vwMobile(79)};
  transition: all 0.3s ease;
  ${vw('font-size', 40, 50, 54)}
  ${vw('line-height', 48, 42, 54)}
  ${vw('letter-spacing', 0.5, 1, 1)}
  ${vw('margin-right', 82, 0)}
  ${vw('margin-bottom', 0, 30, 44)}
  @media ${media.tablet} {
    width: auto;
    text-align: left;
  }
`;

const Info = styled.div`
  p {
    color: ${props => props.disable ? '#ddd' : props.theme.darkGrey};
    text-transform: uppercase;
    line-height: normal;
    &:nth-of-type(1) {
      font-weight: 600;
      ${vw('font-size', 14, 14, 16)}
      ${vw('letter-spacing', 0, 1, 1.6)}
    }
    &:nth-of-type(2) {
      ${vw('font-size', 10, 10)}
      ${vw('letter-spacing', 0.5, 0.71)}
      ${vw('margin-bottom', 6, 8, 0)}
      @media ${media.desktop} {
        display: none;
      }
    }
    &:nth-of-type(3) {
      ${vw('font-size', 12, 14, 16)}
      ${vw('letter-spacing', 0.4, 1, 1.6)}
      @media ${media.tablet} {
        font-weight: 600;
      }
    }
  }
`;

const Line = styled.img`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  @media ${media.tablet} {
    display: none;
  }
`;

const Tag = styled.div`
  display: ${props => props.disable ? 'block': 'none'};
  position: absolute;
  top: 0;
  right: 0;
  ${vw('width', 36, 61.4)}
  ${vw('height', 55, 117)}
  span {
    position: absolute;
    top: 0;
    right: 0;
    text-align: center;
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.brandGrey};
    font-weight: 600;
    ${vw('font-size', 8, 12)}
    ${vw('line-height', 8, 12)}
    ${vw('letter-spacing', 0, 0)}
    ${vw('top', 2, 9)}
    ${vw('right', 2, 5.7)}
  }
  img {
    width: 100%;
    height: 100%;
  }
`;