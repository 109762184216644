import React from 'react';
import styled from 'styled-components';

import vw, { vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Intro = () => {
  return (
    <Root>
      <Container>
        <span>A Trusted Developer</span>
        <h2>Maple Leaf Homes</h2>
        <p>For over 30 years the Maple Leaf Homes team has contributed to the growth of community with multi family and commercial developments, building a reputation of quality and trust.</p>
        <p>Most notably, their Delta Rise project has achieved an iconic presence as the tallest tower in the city. The family-run developer works with exceptional people to create a residential experience where the sky is the limit.</p>
        <img src={require('src/assets/images/team/maple-leaf-homes-logo.svg')} alt='' />
      </Container>
      <Rendering src={require('src/assets/images/team/ascent_team_rendering.jpg')} alt='' />
    </Root>
  )
}

export default Intro;

const Root = styled.div`
  display: flex;
  flex-direction: column-reverse;
  ${vw('margin-bottom', 56, 72, 120)}
  @media ${media.tablet} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  @media ${media.desktop} {
    justify-content: center;
  }
`;

const Container = styled.div`
  span {
    font-family: ${({ theme }) => theme.font.family};
    font-weight: 600;
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.brandGrey};
    ${vw('font-size', 12, 13, 14)}
    ${vw('letter-spacing', 0.4, 1, 1.2)}
    ${vw('margin-bottom', 1, 2, 8)}
  }
  h2 {
    font-family: ${({ theme }) => theme.font.family};
    font-weight: bold;
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.brandGreen};
    line-height: normal;
    ${vw('font-size', 20, 28, 44)}
    ${vw('letter-spacing', 0.63, 0.76, 1.2)}
    ${vw('margin-bottom', 16, 16, 24)}
  }
  p {
    ${vw('width', 287, 289, 430)}
    ${vw('margin-bottom', 8, 8, 24)}
  }
  img {
    ${vw('width', 144, 147, 238)}
    ${vw('margin-top', 24, 16, 16)}
  }
`;

const Rendering = styled.img`
  ${vw('width', 295, 286, 419)}
  ${vw('height', 428, 415, 608)}
  ${vw('margin-bottom', 24, 0)}
  @media ${media.desktop} {
    margin-left: ${vwDesktop(161)};
  }
`;