import React from 'react';

export default {
    width: '720px',
    height: '470px',
    viewBox: '0 0 720 470',
    defs: (
        <defs>
            <filter x="-12.7%" y="-17.0%" width="125.3%" height="134.1%" filterUnits="objectBoundingBox" id="filter-1">
                <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                <feGaussianBlur stdDeviation="11" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                <feColorMatrix values="0 0 0 0 0.37254902   0 0 0 0 0.501960784   0 0 0 0 0.141176471  0 0 0 0.303646061 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
                <feMerge>
                    <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                    <feMergeNode in="SourceGraphic"></feMergeNode>
                </feMerge>
            </filter>
            <filter x="-11.6%" y="-11.4%" width="123.1%" height="122.9%" filterUnits="objectBoundingBox" id="filter-2">
                <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                <feGaussianBlur stdDeviation="9.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                <feColorMatrix values="0 0 0 0 0.37254902   0 0 0 0 0.501960784   0 0 0 0 0.141176471  0 0 0 0.303646061 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
                <feMerge>
                    <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                    <feMergeNode in="SourceGraphic"></feMergeNode>
                </feMerge>
            </filter>
        </defs>
    ),
    outline: (
        <g>
            <g id="level-2" filter="url(#filter-1)" transform="translate(364.000000, 103.000000)">
                <polygon id="Path" fill="#EFEFEF" points="79.4669636 250.240506 0.839243499 250.563414 65.496707 41.353424 80.4415252 41.353424 80.4415252 0 156.369855 0 156.369855 16.3945527 236.702845 16.3945527 236.702845 0 336.879255 0 336.879255 76.1000879 313.370048 76.1000879 313.334029 149.607595 355 149.607595 355 205.291139 352.311873 205.291139 352.311873 251.582278 309.97387 251.582278 309.97387 241.518987 287.124789 241.518987 286.452757 251.582278 242.77069 251.582278 242.77069 241.518987 219.249577 241.518987 219.249577 251.582278 174.895479 251.582278 174.895479 241.518987 151.374366 241.518987 151.374366 251.582278 107.692299 251.582278 107.692299 241.518987 84.843218 241.518987 84.843218 250.240506"></polygon>
                <polygon id="Stroke-6" stroke="#6D6C6F" strokeWidth="1.344" strokeLinecap="round" strokeLinejoin="round" points="244.219858 96.4398734 255.130024 96.4398734 255.130024 73.7974684 244.219858 73.7974684"></polygon>
                <polygon id="Stroke-7" stroke="#6D6C6F" strokeWidth="1.344" strokeLinecap="round" strokeLinejoin="round" points="244.219858 138.370253 255.130024 138.370253 255.130024 113.212025 244.219858 113.212025"></polygon>
                <polygon id="Path" stroke="#6D6C6F" strokeWidth="1.344" strokeLinecap="round" strokeLinejoin="round" points="140.992908 104.825949 229.113475 104.825949 229.113475 77.1518987 140.992908 77.1518987"></polygon>
                <polygon id="Path" stroke="#6D6C6F" strokeWidth="1.344" strokeLinecap="round" strokeLinejoin="round" points="229.113475 104.825949 229.113475 127.468354 140.992908 127.468354 140.992908 104.825949"></polygon>
                <path d="M169.107565,127.468354 L169.107565,104.825949 L169.107565,127.468354 Z" id="Path" stroke="#6D6C6F" strokeWidth="1.344" strokeLinecap="round" strokeLinejoin="round"></path>
                <path d="M198.481087,127.468354 L198.481087,104.825949 L198.481087,127.468354 Z" id="Path" stroke="#6D6C6F" strokeWidth="1.344" strokeLinecap="round" strokeLinejoin="round"></path>
                <polyline id="Path" stroke="#6D6C6F" strokeWidth="1.344" strokeLinecap="round" strokeLinejoin="round" points="140.992908 127.468354 168.979575 104.825949 198.430826 127.468354 229.113475 104.825949"></polyline>
                <polyline id="Path" stroke="#6D6C6F" strokeWidth="1.344" strokeLinecap="round" strokeLinejoin="round" points="229.113475 127.468354 198.430826 104.825949 168.979575 127.468354 140.992908 104.825949"></polyline>
                <line x1="206.034279" y1="91.4082278" x2="206.034279" y2="104.825949" id="Path" stroke="#6D6C6F" strokeWidth="1.344" strokeLinecap="round" strokeLinejoin="round"></line>
                <line x1="203.516548" y1="77.1518987" x2="203.516548" y2="90.5696203" id="Path" stroke="#6D6C6F" strokeWidth="1.344" strokeLinecap="round" strokeLinejoin="round"></line>
                <line x1="164.072104" y1="77.1518987" x2="164.072104" y2="90.5696203" id="Path" stroke="#6D6C6F" strokeWidth="1.344" strokeLinecap="round" strokeLinejoin="round"></line>
                <line x1="166.589835" y1="91.4082278" x2="166.589835" y2="104.825949" id="Path" stroke="#6D6C6F" strokeWidth="1.344" strokeLinecap="round" strokeLinejoin="round"></line>
                <path d="M163.652482,90.9889241 L206.453901,90.9889241 L163.652482,90.9889241 Z" id="Path" stroke="#6D6C6F" strokeWidth="1.344" strokeLinecap="round" strokeLinejoin="round"></path>
                <polyline id="Path" stroke="#6D6C6F" strokeWidth="1.344" strokeLinecap="round" strokeLinejoin="round" points="78.7428453 250.743671 0 250.743671 64.7530204 41.3831739 79.7199254 41.3831739 79.7199254 0 155.76047 0 155.76047 16.4063471 236.212183 16.4063471 236.212183 0 336.536643 0 336.536643 76.1548349 312.992692 76.1548349 312.992692 152.060718 244.302733 152.060718 244.302733 138.34795 255.095585 138.34795 255.095585 74.4407389 245.774486 74.4407389 245.774486 47.2559932 148.152327 47.2559932 148.152327 43.3421407 118.966658 43.3421407 118.966658 61.4625838 228.608129 61.4625838 228.608129 77.3751079"></polyline>
                <polyline id="Path" stroke="#6D6C6F" strokeWidth="1.344" strokeLinecap="round" strokeLinejoin="round" points="101.548463 167.721519 101.548463 129.159158 86.8557458 124.76191 93.71017 102.067879 50.3546099 88.8924051"></polyline>
                <path d="M140.573286,77.1518987 L140.573286,61.2183544 L140.573286,77.1518987 Z" id="Path" stroke="#6D6C6F" strokeWidth="1.344" strokeLinecap="round" strokeLinejoin="round"></path>
                <polyline id="Path" stroke="#6D6C6F" strokeWidth="1.344" strokeLinecap="round" strokeLinejoin="round" points="123.368794 142.563291 123.368794 125.462899 125.886525 125.462899 125.886525 61.2183544"></polyline>
                <polygon id="Path" stroke="#6D6C6F" strokeWidth="1.344" fill="#EFEFEF" strokeLinecap="round" strokeLinejoin="round" points="107.423168 238.378172 107.359213 265 91.4775414 265 91.4775414 238.164557"></polygon>
                <polygon id="Path" stroke="#6D6C6F" strokeWidth="1.344" fill="#EFEFEF" strokeLinecap="round" strokeLinejoin="round" points="175.067046 238.378172 175.401891 265 159.456265 265 159.456265 238.164557"></polygon>
                <polygon id="Path" stroke="#6D6C6F" strokeWidth="1.344" fill="#EFEFEF" strokeLinecap="round" strokeLinejoin="round" points="242.541371 238.346347 242.355394 265 226.595745 265 226.595745 238.164557"></polygon>
                <polygon id="Path" stroke="#6D6C6F" strokeWidth="1.344" fill="#EFEFEF" strokeLinecap="round" strokeLinejoin="round" points="310.311352 238.164557 310.520095 265 294.574468 265 294.830184 238.835443"></polygon>
                <text id="Amenity" fontFamily="OpenSans-Semibold, Open Sans" fontSize="14" fontWeight="500" line-spacing="20" letterSpacing="1" fill="#2A2A2A">
                    <tspan x="250.884505" y="34.0224096">AMENITY</tspan>
                </text>
            </g>
            <text id="Level-2" fontFamily="OpenSans-Regular, Open Sans" fontSize="12" fontWeight="normal" letterSpacing="0.818181851" fill="#2A2A2A">
                <tspan x="541" y="421">LEVEL 2</tspan>
            </text>
            <g id="level-1" filter="url(#filter-2)" transform="translate(0.000000, 54.000000)">
                <polygon id="Path" fill="#EFEFEF" points="35.2923833 277.385787 0 277.385787 55.2458845 97.4475466 68.0603808 97.4475466 68.0603808 61.8794201 133.393305 61.8794201 133.393305 75.9803933 182.130405 75.9803933 203.008432 8.10668622e-14 342 37.5459098 327.005059 93.5759599 342 97.6193656 342 117.836394 288.36425 117.836394 288.36425 127.656093 268.178752 127.656093 267.602024 190.617696 303.359191 190.617696 303.359191 237.983306 301.052277 237.983306 301.052277 278.417362 298.168634 278.417362 298.168634 337.335559 261.25801 337.335559 87.6627319 337.335559 66.3237774 337.335559 67.4772344 277.262104 58.2495784 277.262104 58.2495784 294.013356 35.1804384 294.013356"></polygon>
                <polygon id="Stroke-17" stroke="#6D6C6F" strokeWidth="1.152" strokeLinecap="round" strokeLinejoin="round" points="209.022291 145.008516 218.265534 145.008516 218.265534 128.381995 209.022291 128.381995"></polygon>
                <polygon id="Stroke-18" stroke="#6D6C6F" strokeWidth="1.152" strokeLinecap="round" strokeLinejoin="round" points="209.022291 180.787105 218.265534 180.787105 218.265534 159.109489 209.022291 159.109489"></polygon>
                <polygon id="Path" stroke="#6D6C6F" strokeWidth="1.152" strokeLinecap="round" strokeLinejoin="round" points="120.161112 151.953771 195.791149 151.953771 195.791149 128.378487 120.161112 128.378487"></polygon>
                <polygon id="Path" stroke="#6D6C6F" strokeWidth="1.152" strokeLinecap="round" strokeLinejoin="round" points="195.791149 151.953771 195.791149 171.526764 120.164613 171.526764 120.164613 151.953771"></polygon>
                <path d="M144.113016,171.526764 L144.113016,151.953771 L144.113016,171.526764 Z" id="Path" stroke="#6D6C6F" strokeWidth="1.152" strokeLinecap="round" strokeLinejoin="round"></path>
                <path d="M169.321861,171.526764 L169.321861,151.953771 L169.321861,171.526764 Z" id="Path" stroke="#6D6C6F" strokeWidth="1.152" strokeLinecap="round" strokeLinejoin="round"></path>
                <polyline id="Path" stroke="#6D6C6F" strokeWidth="1.152" strokeLinecap="round" strokeLinejoin="round" points="120.160061 171.531324 144.104963 151.958331 169.31731 171.531324 195.790098 151.958331"></polyline>
                <polyline id="Path" stroke="#6D6C6F" strokeWidth="1.152" strokeLinecap="round" strokeLinejoin="round" points="195.790098 171.531324 169.31731 151.958331 144.104963 171.531324 120.160061 151.958331"></polyline>
                <line x1="176.039318" y1="140.167532" x2="176.039318" y2="151.953421" id="Path" stroke="#6D6C6F" strokeWidth="1.152" strokeLinecap="round" strokeLinejoin="round"></line>
                <line x1="173.73551" y1="128.381644" x2="173.73551" y2="140.167532" id="Path" stroke="#6D6C6F" strokeWidth="1.152" strokeLinecap="round" strokeLinejoin="round"></line>
                <line x1="139.700068" y1="128.381644" x2="139.700068" y2="140.167532" id="Path" stroke="#6D6C6F" strokeWidth="1.152" strokeLinecap="round" strokeLinejoin="round"></line>
                <line x1="142.010878" y1="140.167532" x2="142.010878" y2="151.953421" id="Path" stroke="#6D6C6F" strokeWidth="1.152" strokeLinecap="round" strokeLinejoin="round"></line>
                <line x1="139.696916" y1="140.167532" x2="176.039668" y2="140.167532" id="Stroke-22" stroke="#6D6C6F" strokeWidth="1.152" strokeLinecap="round" strokeLinejoin="round"></line>
                <polygon id="Stroke-26" stroke="#6D6C6F" strokeWidth="1.152" strokeLinecap="round" strokeLinejoin="round" points="35.2913329 294.012307 58.1893673 294.012307 58.1893673 264.551095 35.2913329 264.551095"></polygon>
                <path d="M67.220086,277.385787 L58.1939189,277.385787 L67.220086,277.385787 Z" id="Path" stroke="#6D6C6F" strokeWidth="1.152" strokeLinecap="round" strokeLinejoin="round"></path>
                <polyline id="Path" stroke="#6D6C6F" strokeWidth="1.152" strokeLinecap="round" strokeLinejoin="round" points="35.2923833 277.385787 0 277.385787 55.2458845 97.4475466 68.0603808 97.4475466 68.0603808 61.8794201 133.393305 61.8794201 133.393305 75.9803933 182.130405 75.9803933"></polyline>
                <polyline id="Path" stroke="#6D6C6F" strokeWidth="1.152" strokeLinecap="round" strokeLinejoin="round" points="206.292383 61.037571 221.207617 5.05109489 202.507555 9.85032868e-14 182.130405 75.9803933"></polyline>
                <polyline id="Path" stroke="#6D6C6F" strokeWidth="1.152" strokeLinecap="round" strokeLinejoin="round" points="202.507555 -2.46258217e-14 342 37.4622871 326.871192 93.4487632"></polyline>
                <polyline id="Path" stroke="#6D6C6F" strokeWidth="1.152" strokeLinecap="round" strokeLinejoin="round" points="267.420332 190.46837 209.016339 190.46837 209.016339 128.381995 195.788698 128.381995"></polyline>
                <polyline id="Path" stroke="#6D6C6F" strokeWidth="1.152" strokeLinecap="round" strokeLinejoin="round" points="236.963145 69.2420925 229.817138 95.9708029 201.040541 95.9708029"></polyline>
                <path d="M209.016339,128.381995 L209.016339,95.9708029 L209.016339,128.381995 Z" id="Path" stroke="#6D6C6F" strokeWidth="1.152" strokeLinecap="round" strokeLinejoin="round"></path>
                <path d="M201.040541,80.607056 L206.292383,61.037571 L201.040541,80.607056 Z" id="Path" stroke="#6D6C6F" strokeWidth="1.152" strokeLinecap="round" strokeLinejoin="round"></path>
                <polyline id="Path" stroke="#6D6C6F" strokeWidth="1.152" strokeLinecap="round" strokeLinejoin="round" points="133.599877 128.381995 133.599877 94.2906123 144.943857 94.2906123 144.943857 103.757908 171.62672 103.757908 171.62672 75.9803933 153.980528 75.9803933 153.980528 103.757908"></polyline>
                <line x1="77.2816189" y1="337.120881" x2="297.98363" y2="336.972608" id="Path" stroke="#6D6C6F" strokeWidth="1.152" strokeLinecap="round" strokeLinejoin="round"></line>
                <path d="M201.040541,95.9708029 L201.040541,80.607056 L201.040541,95.9708029 Z" id="Path" stroke="#6D6C6F" strokeWidth="1.152" strokeLinecap="round" strokeLinejoin="round"></path>
                <polyline id="Path" stroke="#6D6C6F" strokeWidth="1.152" strokeLinecap="round" strokeLinejoin="round" points="110.288698 171.530272 82.9756143 171.530272 74.7862408 168.790754 87.1770885 128.381995 120.158661 128.381995"></polyline>
                <path d="M80.6718059,149.217762 L42.8550369,137.431873 L80.6718059,149.217762 Z" id="Path" stroke="#6D6C6F" strokeWidth="1.152" strokeLinecap="round" strokeLinejoin="round"></path>
                <polyline id="Path" stroke="#6D6C6F" strokeWidth="1.152" strokeLinecap="round" strokeLinejoin="round" points="110.288698 128.381995 110.288698 171.530272 120.158661 171.530272"></polyline>
                <line x1="66.4573319" y1="277.988005" x2="66.4573319" y2="321.136282" id="Path" stroke="#6D6C6F" strokeWidth="1.152" strokeLinecap="round" strokeLinejoin="round"></line>
                <polyline id="Path" stroke="#6D6C6F" strokeWidth="1.152" fill="#EFEFEF" strokeLinecap="round" strokeLinejoin="round" points="297.464373 313.798919 298.094595 313.798919 298.094595 337.156725 261.541769 337.156725 261.541769 314.851231"></polyline>
                <polyline id="Path" stroke="#6D6C6F" strokeWidth="1.152" strokeLinecap="round" strokeLinejoin="round" points="261.541769 314.430306 261.541769 313.798919 297.044226 313.798919"></polyline>
                <polyline id="Path" stroke="#6D6C6F" strokeWidth="1.152" fill="#EFEFEF" strokeLinecap="round" strokeLinejoin="round" points="239.694103 313.798919 251.458231 313.798919 251.458231 337.156725 203.564926 337.156725 203.564926 314.851231"></polyline>
                <polygon id="Stroke-30" stroke="#6D6C6F" strokeWidth="1.152" fill="#EFEFEF" strokeLinecap="round" strokeLinejoin="round" points="240.1132 313.79927 251.453679 313.79927 251.453679 282.022972 240.1132 282.022972"></polygon>
                <polyline id="Path" stroke="#6D6C6F" strokeWidth="1.152" fill="#EFEFEF" strokeLinecap="round" strokeLinejoin="round" points="145.790104 314.854037 145.790104 337.159532 193.68691 337.159532 193.68691 313.801725 181.709208 313.801725"></polyline>
                <polyline id="Path" stroke="#6D6C6F" strokeWidth="1.152" strokeLinecap="round" strokeLinejoin="round" points="181.499134 313.801725 145.790104 313.801725 145.790104 314.433112"></polyline>
                <polyline id="Path" stroke="#6D6C6F" strokeWidth="1.152" strokeLinecap="round" strokeLinejoin="round" points="203.564926 314.430306 203.564926 313.798919 239.273956 313.798919"></polyline>
                <polygon id="Stroke-32" stroke="#6D6C6F" strokeWidth="1.152" fill="#EFEFEF" strokeLinecap="round" strokeLinejoin="round" points="182.34293 313.79927 193.683409 313.79927 193.683409 282.022972 182.34293 282.022972"></polygon>
                <polyline id="Stroke-33" stroke="#6D6C6F" strokeWidth="1.152" fill="#EFEFEF" strokeLinecap="round" strokeLinejoin="round" points="123.36826 313.652755 87.6627319 313.652755 87.6627319 337.010561 135.552535 337.010561 135.552535 313.652755 123.788408 313.652755"></polyline>
                <polygon id="Stroke-34" stroke="#6D6C6F" strokeWidth="1.152" fill="#EFEFEF" strokeLinecap="round" strokeLinejoin="round" points="124.362586 313.79927 135.706566 313.79927 135.706566 282.022972 124.362586 282.022972"></polygon>
                <polygon id="Stroke-35" stroke="#6D6C6F" strokeWidth="1.152" fill="#EFEFEF" strokeLinecap="round" strokeLinejoin="round" points="66.382242 346 77.9362961 346 77.9362961 282.022972 66.382242 282.022972"></polygon>
                <line x1="298.093544" y1="313.798919" x2="298.093544" y2="278.441255" id="Stroke-38" stroke="#6D6C6F" strokeWidth="1.152" strokeLinecap="round" strokeLinejoin="round"></line>
                <polyline id="Path" stroke="#6D6C6F" strokeWidth="1.152" strokeLinecap="round" strokeLinejoin="round" points="303.359191 210.834725 303.549509 190.46837 267.420332 190.46837 267.420332 127.329684 288.007555 127.329684 288.007555 117.858881 342 117.858881 342 97.4475466 206.292383 61.037571"></polyline>
            </g>
            <text id="Level-1" fontFamily="OpenSans-Regular, Open Sans" fontSize="12" fontWeight="normal" letterSpacing="0.818181851" fill="#2A2A2A">
                <tspan x="146" y="421">LEVEL 1</tspan>
            </text>
        </g>
    ),
    compass: (
        <g id="Group" transform="translate(666.000000, 439.000000)">
            <g id="Group-3" transform="translate(-0.000000, -0.000000)">
                <polygon id="Triangle" fill="#6D6C6F" points="0.373344996 2.31092474 24.9689907 -3.66818172e-13 17.5516395 8.46906839 17.7779259 19.713931"></polygon>
                <polygon id="Path" fill="#484747" points="-2.3037041e-14 2.28628273 24.4764585 0.353004374 17.1911927 8.45150412 17.1929131 8.51412667"></polygon>
                <ellipse id="Oval" stroke="#6D6C6F" strokeWidth="2.08091907" fill="#FFFFFF" cx="38.5996674" cy="15.6738079" rx="14.3598731" ry="14.2857326"></ellipse>
                <path d="M35.7097304,21.1989884 L35.7091288,16.1978729 C35.7035141,15.5970509 35.6585964,14.6503812 35.5743759,13.357864 L35.5743759,13.357864 L35.6384912,13.357864 L40.1693045,21.1989884 L42.9761291,21.1989884 L42.9761291,10.8339577 L40.9885553,10.8339577 L40.9885553,15.7399777 C40.9885553,16.2976948 41.0241749,17.2500677 41.0954141,18.5970963 L41.0954141,18.5970963 L41.0455467,18.5970963 L36.5218573,10.8339577 L33.7364044,10.8339577 L33.7364044,21.1989884 L35.7097304,21.1989884 Z" id="N" fill="#6D6C6F" fillRule="nonzero"></path>
            </g>
        </g>
    ),
    units: [
        {
            id: 'th3',
            mockup: (
                <g id="th3_2" transform="translate(506.000000, 255.000000)">
                    <polygon id="shape" stroke="#6D6C6F" strokeWidth="2.016" fill="#FFFFFF" strokeLinecap="round" points="73.092963 6.82393984e-14 0 6.82393984e-14 0 25.4912374 30.8040741 25.4912374 30.8040741 51.4686057 13.692963 51.4686057 13.692963 56.61996 5.87481481 56.61996 5.87481481 90.1997631 33 90.1997631 33 100 77 100 77 90.1997631 73.092963 90.1997631"></polygon>
                    <g id="type" transform="translate(15.108809, 74.190544)" fill="#2A2A2A" fontFamily="OpenSans-Semibold, Open Sans" fontSize="8" fontWeight="500" letterSpacing="0.4" line-spacing="12">
                        <text id="2-Bed+Den">
                            <tspan x="1.55026042" y="9.02409639">2 BED+DEN</tspan>
                        </text>
                    </g>
                    <g id="th3" transform="translate(24.271739, 52.100840)" fill="#97C93D" fontFamily="OpenSans-Bold, Open Sans" fontSize="16.8" fontWeight="bold">
                        <text id="TH3">
                            <tspan x="0.234324049" y="18.0461847">TH3</tspan>
                        </text>
                    </g>
                </g>
            ),
        },
        {
            id: 'th4',
            mockup: (
                <g id="th4_2" transform="translate(444.000000, 246.000000)">
                    <polygon id="shape" stroke="#6D6C6F" strokeWidth="2.016" fill="#FFFFFF" strokeLinecap="round" points="94 34.5383722 62.9893102 34.5383722 62.9893102 9.0632608 62.9893102 0 44.2951263 0 44.2951263 25.9650174 0 25.9650174 0 107.779318 4.9134779 107.779318 4.9134779 99.2059628 28.2955626 99.2059628 28.2955626 109 72.836773 109 72.836773 99.2059628 68.9035298 99.2059628 68.9035298 65.6474025 76.7741175 65.6474025 76.7741175 60.4993071 94 60.4993071"></polygon>
                    <g id="type" transform="translate(12.000000, 67.000000)" fill="#2A2A2A" fontFamily="OpenSans-Semibold, Open Sans" fontSize="8" fontWeight="500" letterSpacing="0.4" line-spacing="12">
                        <text id="2-Bed+Den">
                            <tspan x="0.48515625" y="9">2 BED+DEN</tspan>
                        </text>
                    </g>
                    <g id="th4" transform="translate(9.000000, 35.000000)" fill="#97C93D" fontFamily="OpenSans-Bold, Open Sans" fontSize="22" fontWeight="bold">
                        <text id="TH4">
                            <tspan x="5.93457031" y="24">TH4</tspan>
                        </text>
                    </g>
                </g>
            ),
        },
        {
            id: 'th2',
            mockup: (
                <g id="th2_2" transform="translate(579.000000, 255.000000)">
                    <polygon id="shape" stroke="#6D6C6F" strokeWidth="2.016" fill="#FFFFFF" strokeLinecap="round" points="68.0504595 16.4619165 56.9580456 16.4619165 56.9580456 6.82393984e-14 0 6.82393984e-14 0 90.4176904 3.94132085 90.4176904 27.3673155 90.4176904 27.3673155 100 72 100 72 90.4176904 68.0504595 90.4176904"></polygon>
                    <g id="type" transform="translate(7.623529, 51.847727)" fill="#2A2A2A" fontFamily="OpenSans-Semibold, Open Sans" fontSize="8" fontWeight="500" letterSpacing="0.4" line-spacing="12">
                        <text id="2-Bed+Den">
                            <tspan x="3.5910386" y="9.02409639">2 BED+DEN</tspan>
                        </text>
                    </g>
                    <g id="th2" transform="translate(7.623529, 19.562063)" fill="#97C93D" fontFamily="OpenSans-Bold, Open Sans" fontSize="22" fontWeight="bold">
                        <text id="TH2">
                            <tspan x="6.04045267" y="24">TH2</tspan>
                        </text>
                    </g>
                </g>
            ),
        },
        {
            id: 'th1',
            mockup: (
                 <g id="th1_2" transform="translate(637.000000, 253.000000)">
                    <polygon id="shape" stroke="#6D6C6F" strokeWidth="2.016" fill="#FFFFFF" strokeLinecap="round" points="80.5555829 55.3945532 83 55.3945532 83 0 41.3836963 0 41.3836963 2.44028869 0 2.44028869 0 18.7902229 11.01416 18.7902229 11.01416 92.2429124 38.1924873 92.2429124 38.1924873 102 80.5555829 102"></polygon>
                    <g id="type" transform="translate(32.696970, 52.888525)" fill="#2A2A2A" fontFamily="OpenSans-Semibold, Open Sans" fontSize="8" fontWeight="500" letterSpacing="0.4" line-spacing="12">
                        <text id="3-Bed">
                            <tspan x="1.70247396" y="9">3 BED</tspan>
                        </text>
                    </g>
                    <g id="th1" transform="translate(20.121212, 20.901639)" fill="#97C93D" fontFamily="OpenSans-Bold, Open Sans" fontSize="22" fontWeight="bold">
                        <text id="TH1">
                            <tspan x="5.76285314" y="24">TH1</tspan>
                        </text>
                    </g>
                </g>
            ),
        },
        {
            id: 'th4',
            mockup: (
                <g id="th4_1" transform="translate(66.000000, 259.000000)">
                    <polygon id="shape" stroke="#6D6C6F" strokeWidth="1.728" fill="#FFFFFF" strokeLinecap="round" points="58.6521396 -1.22536777e-14 -4.49451231e-14 -1.22536777e-14 -4.49451231e-14 69.9527509 4.18918919 69.9527509 4.18918919 62.622007 24.0913288 62.622007 24.0913288 71 62 71 62 62.622007 58.6521396 62.622007"></polygon>
                    <g id="type" transform="translate(3.593719, 40.540363)" fill="#2A2A2A" fontFamily="OpenSans-Semibold, Open Sans" fontSize="8" fontWeight="500" letterSpacing="0.4" line-spacing="12">
                        <text id="2-Bed+Den">
                            <tspan x="1.32489984" y="9">2 BED+DEN</tspan>
                        </text>
                    </g>
                    <g id="th4" transform="translate(5.719297, 8.972027)" fill="#97C93D" fontFamily="OpenSans-Bold, Open Sans" fontSize="22" fontWeight="bold">
                        <text id="TH4">
                            <tspan x="1.93625665" y="24">TH4</tspan>
                        </text>
                    </g>
                </g>
            ),
        },
        {
            id: 'th3',
            mockup: (
                <g id="th3_1" transform="translate(125.000000, 244.000000)">
                    <polygon id="shape" stroke="#6D6C6F" strokeWidth="1.728" fill="#FFFFFF" strokeLinecap="round" points="57.6493499 9.03257513e-14 -4.08932504e-15 9.03257513e-14 -4.08932504e-15 79.5786116 23.2623862 79.5786116 23.2623862 88 61 88 61 79.5786116 57.6493499 79.5786116"></polygon>
                    <g id="type" transform="translate(3.541017, 57.389552)" fill="#2A2A2A" fontFamily="OpenSans-Semibold, Open Sans" fontSize="8" fontWeight="500" letterSpacing="0.4" line-spacing="12">
                        <text id="2-Bed+Den">
                            <tspan x="1.4034569" y="9">2 BED+DEN</tspan>
                        </text>
                    </g>
                    <g id="th3" transform="translate(5.726225, 25.670094)" fill="#97C93D" fontFamily="OpenSans-Bold, Open Sans" fontSize="22" fontWeight="bold">
                        <text id="TH3">
                            <tspan x="1.9554237" y="24">TH3</tspan>
                        </text>
                    </g>
                </g>
            ),
        },
        {
            id: 'th2',
            mockup: (
                <g id="th2_1" transform="translate(183.000000, 259.000000)">
                    <polygon id="shape" stroke="#6D6C6F" strokeWidth="1.728" fill="#FFFFFF" strokeLinecap="round" points="57.6608254 -1.22536777e-14 -2.44519182e-14 -1.22536777e-14 -2.44519182e-14 62.622007 23.1827159 62.622007 23.1827159 71 61 71 61 62.622007 57.6608254 62.622007"></polygon>
                    <g id="type" transform="translate(5.067316, 40.540363)" fill="#2A2A2A" fontFamily="OpenSans-Semibold, Open Sans" fontSize="8" fontWeight="500" letterSpacing="0.4" line-spacing="12">
                        <text id="2-Bed+Den">
                            <tspan x="1.4034569" y="9">2 BED+DEN</tspan>
                        </text>
                    </g>
                    <g id="th2" transform="translate(8.772391, 12.319823)" fill="#97C93D" fontFamily="OpenSans-Bold, Open Sans" fontSize="22" fontWeight="bold">
                        <text id="TH2">
                            <tspan x="0.434570312" y="24">TH2</tspan>
                        </text>
                    </g>
                </g>
            ),
        },
        {
            id: 'th1',
            mockup: (
                <g id="th1_1" transform="translate(241.000000, 259.000000)">
                    <polygon id="shape" stroke="#6D6C6F" strokeWidth="1.728" fill="#FFFFFF" strokeLinecap="round" points="45.9744869 3.76660603 45.9744869 8.16911845e-15 -2.87992749e-14 8.16911845e-15 -2.87992749e-14 62.622007 23.4073024 62.622007 23.4073024 71 59.8944325 71 59.8944325 30.7856335 62 30.7856335 62 3.76660603"></polygon>
                    <g id="type" transform="translate(18.783237, 40.523414)" fill="#2A2A2A" fontFamily="OpenSans-Semibold, Open Sans" fontSize="8" fontWeight="500" letterSpacing="0.4" line-spacing="12">
                        <text id="3-Bed">
                            <tspan x="0.785807292" y="9.01694915">3 BED</tspan>
                        </text>
                    </g>
                    <g id="th1" transform="translate(8.249607, 8.972027)" fill="#97C93D" fontFamily="OpenSans-Bold, Open Sans" fontSize="22" fontWeight="bold">
                        <text id="TH1">
                            <tspan x="2.09535665" y="24">TH1</tspan>
                        </text>
                    </g>
                </g>
            ),
        },
    ]
}