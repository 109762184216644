import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Button = (props) => {
  const { to, handleClick, text, href, width } = props;

  const handleType = () => {
    if (to) {
      return Link;
    } else if (href) {
      return 'a';
    } else {
      return 'button';
    }
  };
  return (
    <Root
      as={handleType()}
      to={to}
      onClick={handleClick ? handleClick : (f) => f}
      width={width}
      className="button"
      {...props}
    >
      {text}
    </Root>
  );
};

Button.propTypes = {
  to: PropTypes.string,
  text: PropTypes.string,
  handleClick: PropTypes.func,
  href: PropTypes.string,
};

const Root = styled.button`
  display: block;
  background-color: ${({ theme }) => theme.color.brandGreen};
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
  width: ${(props) =>
    props.width && props.width.mobile ? props.width.mobile : 'fit-content'};

  white-space: nowrap;
  position: relative;
  z-index: 1;
  ${vw('height', 32, 40, 48)}
  ${vw('padding-top', 8, 10, 16)};
  ${vw('padding-bottom', 8, 10, 16)};
  ${vw('padding-left', 12, 26, 26)};
  ${vw('padding-right', 12, 26, 26)};
  @media ${media.tablet} {
    width: ${(props) =>
      props.width && props.width.tablet ? props.width.tablet : 'fit-content'};
  }
  @media ${media.desktop} {
    box-shadow: none;
    transition: 0.3s ease;
    cursor: pointer;
    width: ${(props) =>
      props.width && props.width.desktop ? props.width.desktop : 'fit-content'};
    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transform: scaleX(0.5) skew(-45deg);
      transform-origin: 50% 50%;
      background-color: ${({ theme }) => theme.color.brandGreen};
      transition: transform 0s ease 0.4s, opacity 0.3s ease 0s;
    }
    &:hover::after {
      opacity: 1;
      transform: scaleX(1) skew(-45deg);
      transition: all 0.4s ease 0s;
    }
    &:hover{
      background-color: transparent;
    }
  }
`;

export default Button;
