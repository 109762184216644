import React from 'react';

export default {
  projects: [
    {
      name: 'Delta Rise',
      image: require('src/assets/images/team/delta_rise.jpg'),
    },
    {
      name: 'Delta Gardens',
      image: require('src/assets/images/team/delta_gardens.jpg'),
    },
    {
      name: <>North Delta Centre <br/> for the Arts</>,
      image: require('src/assets/images/team/north_delta_centre_for_the_arts.jpg'),
    },
  ],
  team: [
    {
      name: 'DA ARCHITECTS',
      description: 'Vancouver-based DA Architects + Planners is dedicated to craftsmanship, design, collaboration, and community building. At the core of their values, they strive for architecture that is design forward, community minded, and always maintains the best of the natural environment.',
      logo: require('src/assets/images/team/DA_architects_logo.svg')
    },
    {
      name: 'COLLABORATIVE DESIGN STUDIO',
      description: 'The Collaborative Design Studio is a full-service interior design firm based in the Greater Vancouver Area, specializing in multi-family and single-family developments. They stay true to an innovative process and collectively approach every new project as an exciting and creative opportunity.',
      logo: require('src/assets/images/team/collaborative_design_studio_logo.svg')
    },
    {
      name: 'RENNIE',
      description: 'For more than 40 years, rennie’s full-service brokerage, developer services, and dedicated intelligence divisions have combined to help buyers, sellers, developers, and institutions achieve their goals. A family-run and owned company founded on considered collaboration, rennie offers a depth of experience and a level of service that sets them apart.',
      logo: require('src/assets/images/logo/marketingbyrennie.svg')
    },
  ]
}