import React, { useState } from 'react';
import styled from 'styled-components';
import Insight from 'src/plugins/Insight';

import Heading from 'components/Heading';
import Form from './elements/Form';

import vw from 'src/styles/utils';

const Register = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [bedroom, setBedroom] = useState('');
  const [survey, setSurvey] = useState('');
  const [realtor, setRealtor] = useState('');

  const handleSubmit = (e) => {
    const data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phone,
      postal_code: postalCode,
      bedroom: bedroom,
      survey: survey,
      realtor: realtor,
    };
    window.history.pushState('Thank You', 'Thank You', '/thank-you');
    Insight(data);
  };

  return (
    <Root>
      <Heading
        title={<>Register for more <br/> project updates from <br/> Maple Leaf Homes</>}
        subtitle='Ascent is sold out.'
        small
      />
      <Form
        handleSubmit={handleSubmit}
        setFirstName={setFirstName}
        setLastName={setLastName}
        setEmail={setEmail}
        setPhone={setPhone}
        setPostalCode={setPostalCode}
        setBedroom={setBedroom}
        setSurvey={setSurvey}
        setRealtor={setRealtor}
      />
    </Root>
  )
}

export default Register;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${vw('padding-right', 13, 132, 136)}
  ${vw('padding-bottom', 56, 72, 120)}
  ${vw('padding-left', 13, 131, 139)}
`;