import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import vw, { vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Map = (props) => {
  const { activeCategory, data, $ref } = props;
  const [isZoomed, setIsZoomed]= useState(false);
  
  useEffect(() => {
    $(`.magnify`).magnifik({
      ratio: 2.6,
      imageStyle: {
        position: 'absolute',
        top: '45%',
        transform: 'translateY(-50%)',
      },
      stageHTML: function() {
        return '<div class="' +this._getClass('canvas') +'"><img class="' + this._getClass('thumb') +'"><img class="' + this._getClass('full') +'"><span style="font-size:14px; line-height: 18px; color: #000000; font-family: Open Sans; font-weight: bold; position: fixed; bottom: 7.5%; left: 50%; transform: translateX(-50%); letter-spacing: 0.5px;" >Tap to Close</span></div>';
      }
    });


    $(`.magnify`).on('magnifik:close', () => {
      setIsZoomed(false);
      $('.tap-to-close').remove();
      window.scrollTo({
        top: $ref.current.getBoundingClientRect().top + window.scrollY,
      });
    })

    $(`.magnify`).on('magnifik:open', () => {
      setIsZoomed(true);
    })
  })


  return (
    <Root>
      <Base
        src={require('src/assets/images/neighbourhood/map_base.png')}
        alt="Location Map"
      />
      <AllPoints
        src={require('src/assets/images/neighbourhood/map_base_withPOI.jpg')}
        alt="Location Map"
        style={{ opacity: activeCategory === null ? 1 : 0 }}
      />
      <Zoom src={require('src/assets/images/neighbourhood/zoom-in.svg')} alt='' />
      {data.map((item, index) => (
        activeCategory === index && 
        <Image
          src={item.mapImage}
          alt={item.title}
          key={index}
          className={`magnify`}
        />
      ))}
    </Root>
  );
};

Map.propTypes = {
  activeCategory: PropTypes.number,
  data: PropTypes.array,
};

const Root = styled.div`
  position: relative;
  width: 100%;
  ${vw('margin-bottom', 24, 0)}
  @media ${media.tablet} {
    width: ${vwTablet(536)};
    height: auto;
    align-self: flex-start;
  }
  @media ${media.desktop} {
    width: ${vwDesktop(983)};
  }
`;

const Base = styled.img`
  width: 100%;
  height: auto;
`;

const AllPoints = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  transition: 0.4s ease;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  transition: 0.4s ease;
  @media ${media.tablet} {
    pointer-events: none;
  }
`;

const Zoom = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 3;
  @media ${media.tablet} {
    display: none;
  }
`;

export default Map;
