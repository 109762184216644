import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import vw, { vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Gallery = (props) => {
  const { images } = props;
  const [activeSlide, setActiveSlide] = useState(0);

  const $gallery = useRef();

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    beforeChange: (current, next) => {
      setActiveSlide(next);
    },
  };

  const handleNext = () => {
    $gallery.current.slickNext();
  };

  const handlePrev = () => {
    $gallery.current.slickPrev();
  };

  return (
    <Root>
      <Arrow
        src={require('src/assets/images/amenities/gallery_arrow_left.svg')}
        alt="Previous"
        onClick={handlePrev}
      />
      <Slider ref={$gallery} {...settings}>
        {images.map((item, index) => (
          <Image src={item} key={index} alt="" />
        ))}
      </Slider>
      <Arrow
        src={require('src/assets/images/amenities/gallery_arrow_right.svg')}
        alt="Previous"
        onClick={handleNext}
        next
      />
      <Nav>
        <h4>
          {activeSlide + 1}/{images.length}
        </h4>
      </Nav>
    </Root>
  );
};

Gallery.propTypes = {
  images: PropTypes.array,
};

const Root = styled.div`
  position: relative;
  width: 100%;
  ${vw('height', 123, 273, 498)}
`;

const Nav = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  ${vw('bottom', 4, 16)}
  @media ${media.tablet} {
    transform: translateX(0);
    left: auto;
    right: ${vwTablet(12)};
  }
  @media ${media.desktop} {
    right: ${vwDesktop(17)};
  }
  h4 {
    font-family: ${({ theme }) => theme.font.family};
    font-weight: bold;
    line-height: normal;
    color: white;
    ${vw('font-size', 10, 16, 18)}
    ${vw('letter-spacing', 1, 0.89, 1)}
    @media ${media.tablet} {
      line-height: 1.63;
    }
    @media ${media.desktop} {
      line-height: 1.44;
    }
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const Arrow = styled.img`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  cursor: pointer;
  ${(props) => (props.next ? vw('right', 12, 0, 0) : vw('left', 12, -1, 0))}
  ${vw('width', 16, 40, 48)}
  ${vw('height', 16, 40, 48)}
  @media ${media.tablet} {
    box-shadow: 0 2px 10px 0 rgba(42, 42, 42, 0.4);
  }
`;

export default Gallery;
