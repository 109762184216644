import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import vw, { vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Register = (props) => {
  const { handleClick } = props;
  return (
    <StyledLink to='/register' className="button" onClick={handleClick}>
      register for updates
    </StyledLink>
  );
};

const StyledLink = styled(Link)`
  background-color: ${({ theme }) => theme.color.brandGreen};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  ${vw('padding-left', 12, 26, 26)}
  ${vw('padding-right', 12, 26, 26)}
  @media ${media.tablet} {
    height: ${vwTablet(40)};
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.1);
  }
  @media ${media.desktop} {
    position: relative;
    height: ${vwDesktop(40)};
    box-shadow: none;
    transition: 0.3s ease;
    cursor: pointer;
    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transform: scaleX(0.5) skew(-45deg);
      transform-origin: 50% 50%;
      background-color: ${({ theme }) => theme.color.brandGreen};
      transition: transform 0s ease 0.4s, opacity 0.3s ease 0s;
    }
    &:hover::after,
    &:focus::after {
      opacity: 1;
      transform: scaleX(1) skew(-45deg);
      transition: all 0.4s ease 0s;
    }
    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
`;

export default Register;
