import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import vw, { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Category = (props) => {
  const { data, active, handleClick } = props;

  return (
    <Root>
      <Title color={data.color} onClick={handleClick}>
        <p className="button-map">{data.title}</p>
        <img
          src={require('src/assets/images/neighbourhood/map_menu_dropdown_arrow.svg')}
          alt="Open"
          style={{ transform: active ? 'scaleY(1)' : 'scaleY(-1)' }}
        />
      </Title>
      <Wrapper active={active}>
        <ul>
          {data.points.map((item, index) => (
            <li key={index} className="darkGrey map-point">
              <span>{index + 1}</span>
              <div>{item}</div>
            </li>
          ))}
        </ul>
      </Wrapper>
    </Root>
  );
};

Category.propTypes = {
  data: PropTypes.array,
  active: PropTypes.bool,
  handleClick: PropTypes.func,
};

const Root = styled.div`
  ${vw('margin-bottom', 20, 17, 40)}
  &:last-of-type {
    margin-bottom: 0;
  }
  @media ${media.tablet} {
    width: ${vwTablet(170)};
  }
  @media ${media.desktop} {
    width: ${vwDesktop(309)};
  }
`;

const Title = styled.div`
  background-color: ${(props) => props.color};
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 10px 15px 0 #dddddd;
  cursor: pointer;
  ${vw('padding-left', 14, 4, 18)}
  ${vw('padding-right', 14, 4, 18)}
  ${vw('padding-top', 11, 4, 12)}
  ${vw('padding-bottom', 10, 5, 12)}
  ${vw('border-radius', 2)}
  img {
    display: block;
    height: auto;
    transition: 0.4s ease;
    ${vw('width', 10, 10, 13)}
  }
`;

const Wrapper = styled.div`
  max-height: ${(props) => (props.active ? vwMobile(280) : 0)};
  overflow-y: auto;
  transition: 0.4s ease;
  ${(props) =>
    props.active ? vw('margin-top', 16, 16, 22) : vw('margin-top', 0)}
  ul {
    ${vw('margin-bottom', 14, 0)}
    ${vw('margin-left', 16, 6, 27)}
  }
  .map-point {
    font-weight: normal;
    letter-spacing: normal;
    list-style-type: none;
    display: flex;
    align-items: flex-start;
    ${vw('font-size', 12, 12, 14)}
    ${vw('line-height', 16, 18, 32)}
    ${vw('margin-bottom', 7, 10)}
    &:last-of-type {
      margin-bottom: 0;
    }
    div {
      ${vw('max-width', 253, 150, 236)}
    }
  }
  span {
    display: flex;
    justify-content: flex-end;
    font-weight: bold;
    ${vw('width', 14)}
    ${vw('margin-right', 18, 9, 12)}
  }
  @media ${media.tablet} {
    max-height: ${(props) => (props.active ? vwTablet(370) : 0)};
  }
  @media ${media.desktop} {
    max-height: ${(props) =>
      props.active
        ? `min(${vwDesktop(400)}, calc(100vh - ${vwDesktop(320)} - ${vwDesktop(
            96
          )}))`
        : 0};
  }
`;

export default Category;
