import React from 'react';

export default {
    width: '720px',
    height: '470px',
    viewBox: '0 0 720 470',
    defs: (
        <defs>
            <filter x="-8.1%" y="-12.6%" width="116.2%" height="125.1%" filterUnits="objectBoundingBox" id="filter-1">
                <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                <feGaussianBlur stdDeviation="11.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                <feColorMatrix values="0 0 0 0 0.37254902   0 0 0 0 0.501960784   0 0 0 0 0.141176471  0 0 0 0.303646061 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
                <feMerge>
                    <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                    <feMergeNode in="SourceGraphic"></feMergeNode>
                </feMerge>
            </filter>
        </defs>
    ),
    outline: (
        <g id="lines" filter="url(#filter-1)" transform="translate(-0.000000, 48.000000)">
            <polygon id="Path" stroke="#6D6C6F" strokeWidth="1.4" fill="#EFEFEF" strokeLinecap="round" strokeLinejoin="round" points="117.433155 123.853906 117.433155 0 576 0 478.973262 314.628667 448.934759 314.628667 450.769009 364.308271 344.427609 364.308271 343.02838 372 0.217286407 372 58.2852879 182.503759 86.2698669 182.503759 86.2698669 125.165414"></polygon>
            <line x1="308.02139" y1="206.947568" x2="308.02139" y2="171.169688" id="Stroke-12" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></line>
            <line x1="261.811123" y1="206.947568" x2="261.811123" y2="171.169688" id="Stroke-13" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></line>
            <polyline id="Stroke-14" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" points="356.149733 171.169047 356.149733 206.946927 217.918717 206.946927 217.918717 171.169047"></polyline>
            <polygon id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" points="217.918075 171.167764 356.149091 171.167764 356.149091 123.857111 217.918075 123.857111"></polygon>
            <polyline id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" points="217.925134 206.941157 261.818182 171.169688 308.02139 206.941157 356.149733 171.169688"></polyline>
            <polyline id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" points="356.149733 206.941157 308.02139 171.169688 261.818182 206.941157 217.925134 171.169688"></polyline>
            <line x1="320.342246" y1="147.311143" x2="320.342246" y2="171.1652" id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></line>
            <line x1="316.113369" y1="123.860957" x2="316.113369" y2="147.311143" id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></line>
            <line x1="253.739037" y1="123.860957" x2="253.739037" y2="147.311143" id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></line>
            <line x1="257.967914" y1="147.311143" x2="257.967914" y2="171.1652" id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></line>
            <line x1="253.733262" y1="147.311143" x2="320.342888" y2="147.311143" id="Stroke-18" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></line>
            <polygon id="Stroke-19" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" points="380.790802 223.85696 397.725561 223.85696 397.725561 184.239095 380.790802 184.239095"></polygon>
            <polygon id="Stroke-20" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" points="380.790802 158.45543 397.725561 158.45543 397.725561 123.8507 380.790802 123.8507"></polygon>
            <path d="M356.161283,123.853265 L380.796578,123.853265 L356.161283,123.853265 Z" id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M217.930267,123.853265 L193.673583,123.853265 L217.930267,123.853265 Z" id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></path>
            <polyline id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" points="86.631016 182.312693 58.1454545 182.312693 -1.98842393e-13 371.177078 332.278075 371.177078"></polyline>
            <line x1="153.619251" y1="338.860953" x2="153.619251" y2="371.177078" id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></line>
            <polyline id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" points="117.433155 123.853906 117.433155 0 576 0 478.973262 314.628667 448.934759 314.628667"></polyline>
            <line x1="450.86631" y1="123.853906" x2="537.875936" y2="123.853906" id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></line>
            <text id="Outdoor-amenity" fontFamily="OpenSans-Semibold, Open Sans" fontSize="14" fontWeight="500" line-spacing="20" letterSpacing="1" fill="#2A2A2A">
                <tspan x="271.398841" y="67.4360902">OUTDOOR AMENITY</tspan>
            </text>
        </g>
    ),
    compass: (
        <g id="Group" transform="translate(666.000000, 439.000000)">
            <g id="Group-3" transform="translate(-0.000000, -0.000000)">
                <polygon id="Triangle" fill="#6D6C6F" points="0.373344996 2.31092474 24.9689907 -3.66818172e-13 17.5516395 8.46906839 17.7779259 19.713931"></polygon>
                <polygon id="Path" fill="#484747" points="-2.3037041e-14 2.28628273 24.4764585 0.353004374 17.1911927 8.45150412 17.1929131 8.51412667"></polygon>
                <ellipse id="Oval" stroke="#6D6C6F" strokeWidth="2.08091907" fill="#FFFFFF" cx="38.5996674" cy="15.6738079" rx="14.3598731" ry="14.2857326"></ellipse>
                <path d="M35.7097304,21.1989884 L35.7091288,16.1978729 C35.7035141,15.5970509 35.6585964,14.6503812 35.5743759,13.357864 L35.5743759,13.357864 L35.6384912,13.357864 L40.1693045,21.1989884 L42.9761291,21.1989884 L42.9761291,10.8339577 L40.9885553,10.8339577 L40.9885553,15.7399777 C40.9885553,16.2976948 41.0241749,17.2500677 41.0954141,18.5970963 L41.0954141,18.5970963 L41.0455467,18.5970963 L36.5218573,10.8339577 L33.7364044,10.8339577 L33.7364044,21.1989884 L35.7097304,21.1989884 Z" id="N" fill="#6D6C6F" fillRule="nonzero"></path>
            </g>
        </g>
    ),
    units: [
        {
            id: 'e2',
            mockup: (
                <g id="e2" transform="translate(333.000000, 172.000000)">
                    <polygon id="shape" stroke="#6D6C6F" strokeWidth="2.1" fill="#FFFFFF" strokeLinecap="round" points="119 1.99161644e-14 65.6818182 1.99161644e-14 65.6818182 34.6784489 48.6818182 34.6784489 48.6818182 60.5106169 65.6818182 60.5106169 65.6818182 100.219304 48.6818182 100.219304 48.6818182 111.397611 23.9609848 111.397611 23.9609848 117.943987 3.09090909 117.943987 3.09090909 215.465079 -8.97897683e-14 215.465079 -8.97897683e-14 249 11.9772727 249 11.9772727 241.290823 119 241.290823"></polygon>
                    <g id="type" transform="translate(16.574517, 187.411920)" fill="#2A2A2A" fontFamily="OpenSans-Semibold, Open Sans" fontSize="14" fontWeight="500" letterSpacing="1" line-spacing="20">
                        <text id="2-Bed+Den">
                            <tspan x="0.499023438" y="15">2 BED+DEN</tspan>
                        </text>
                    </g>
                    <g transform="translate(36.574517, 132.781778)" fill="#97C93D" fontFamily="OpenSans-Bold, Open Sans" fontSize="40" fontWeight="bold" id="E2">
                        <text>
                            <tspan x="0.3828125" y="43">E2</tspan>
                        </text>
                    </g>
                </g>
            ),
        },
        {
            id: 'd3',
            mockup: (
                <g id="d3" transform="translate(154.000000, 290.000000)">
                    <polygon id="shape" stroke="#6D6C6F" strokeWidth="2.1" fill="#FFFFFF" strokeLinecap="round" points="182 1.98097448e-14 64.3940678 1.98097448e-14 64.3940678 4.2173913 40.4937147 4.2173913 40.4937147 9.20158103 -1.99134946e-13 9.20158103 -1.99134946e-13 97 178.915254 97 182 97"></polygon>
                    <g id="type" transform="translate(69.235780, 66.121462)" fill="#2A2A2A" fontFamily="OpenSans-Semibold, Open Sans" fontSize="14" fontWeight="500" letterSpacing="1" line-spacing="20">
                        <text id="2-Bed">
                            <tspan x="0.0209960938" y="15">2 BED</tspan>
                        </text>
                    </g>
                    <g transform="translate(64.735780, 10.695843)" fill="#97C93D" fontFamily="OpenSans-Bold, Open Sans" fontSize="40" fontWeight="bold" id="D3">
                        <text>
                            <tspan x="0.279296875" y="43">D3</tspan>
                        </text>
                    </g>
                </g>
            ),
        },
        {
            id: 'e3',
            mockup: (
                <g id="e3" transform="translate(50.000000, 172.000000)">
                    <polygon id="shape" stroke="#6D6C6F" strokeWidth="2.1" fill="#FFFFFF" strokeLinecap="round" points="143.609617 1.98731755e-14 36.8623617 1.98731755e-14 36.8623617 121.920302 0 121.920302 0 215 103.675392 215 103.675392 126.920451 144 126.920451 144 121.920302 143.609617 121.920302"></polygon>
                    <g id="type" transform="translate(47.570203, 75.023779)" fill="#2A2A2A" fontFamily="OpenSans-Semibold, Open Sans" fontSize="14" fontWeight="500" letterSpacing="1" line-spacing="20">
                        <text id="2-Bed+Den">
                            <tspan x="0.499023438" y="15">2 BED+DEN</tspan>
                        </text>
                    </g>
                    <g transform="translate(67.570203, 20.468717)" fill="#97C93D" fontFamily="OpenSans-Bold, Open Sans" fontSize="40" fontWeight="bold" id="E3">
                        <text>
                            <tspan x="0.3828125" y="43">E3</tspan>
                        </text>
                    </g>
                </g>
            ),
        },
    ]
}