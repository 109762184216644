import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { setLoaded } from 'src/redux/pageLoad';

import vw from 'src/styles/utils';

const LoadAnimation = () => {
  const dispatch = useDispatch();
  const $animation = React.createRef();

  const handleLoad = () => {
    setTimeout(() => {
      dispatch(setLoaded());
    }, 3000);
  };

  useEffect(() => {
    $animation.current.addEventListener('animationend', () => handleLoad());
    return $animation.current.removeEventListener('animationend', () =>
      handleLoad()
    );
  }, [$animation, dispatch]);

  return (
    <Root ref={$animation}>
      <Left src={require('src/assets/images/loading/left.png')} alt='' />
      <Logo src={require('src/assets/images/loading/loading_a.svg')} alt='' />
      <Right src={require('src/assets/images/loading/right.png')} alt='' />
    </Root>
  );
};

LoadAnimation.propTypes = {
  handleLoad: PropTypes.func,
};

export default LoadAnimation;

const pulse = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
`;

const fadeBackground = keyframes`
  0% {
    background-color: ${({ theme }) => theme.color.brandGreen};
  }
  100% {
    background-color: transparent;
  }
`;


const left = keyframes`
  0% {
    left: 0%;
  }
  100% {
    left: -100%;
  }
`;

const right = keyframes`
  0% {
    right: 0%;
  }
  100% {
    right: -100%;
  }
`;

const Root = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999999999999999999999999999;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  background-color: ${({ theme }) => theme.color.brandGreen};
  animation: ${fadeBackground} 0.3s ease-out forwards;
  animation-delay: 2s;
`;

const Left = styled.img`
  position: absolute;
  width: 68%;
  height: 100%;
  left: 0;
  animation: ${left} 1s ease-out forwards;
  animation-delay: 3.3s;
`;

const Logo = styled.img`
  animation-name: ${pulse}, ${fadeOut};
  animation-duration: 1.25s, 0.3s;
  animation-timing-function: ease-out, ease-out;
  animation-iteration-count: 2.5, 1;
  animation-fill-mode: none, forwards;
  animation-delay: 0s, 3s;
  z-index: 1;
  ${vw('width', 50, 75, 100)}
`;

const Right = styled.img`
  position: absolute;
  width: 68%;
  height: 100%;
  right: 0%;
  animation: ${right} 1s ease-out forwards;
  animation-delay: 3.3s;
`;