import React from 'react';

export default {
    width: '720px',
    height: '470px',
    viewBox: '0 0 720 470',
    defs: (
        <defs>
            <filter x="-7.8%" y="-11.2%" width="115.5%" height="122.2%" filterUnits="objectBoundingBox" id="filter-1">
                <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                <feGaussianBlur stdDeviation="11.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                <feColorMatrix values="0 0 0 0 0.37254902   0 0 0 0 0.501960784   0 0 0 0 0.141176471  0 0 0 0.303646061 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
                <feMerge>
                    <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                    <feMergeNode in="SourceGraphic"></feMergeNode>
                </feMerge>
            </filter>
        </defs>
    ),
    outline: (
        <g id="lines" filter="url(#filter-1)" transform="translate(0.000000, 21.000000)">
            <polygon id="Path" fill="#EFEFEF" points="530.157476 229.925259 601 0.38514519 498.592767 0.38514519 498.592767 5.38561357 395.268814 5.60134003 395.268814 31.5075377 365.186409 32.2077052 365.186409 0 268.642875 0.700167504 268.642875 5.60134003 149.012845 5.60134003 149.012845 58.1139028 111.934531 58.1139028 74.8562179 175.041876 79.7533536 176.442211 32.1811778 329.778894 27.284042 328.378559 0 417.299832 146.214482 417.299832 146.214482 412.39866 211.976019 412.39866 211.976019 417.299832 251.852696 418 251.852696 412.39866 317.614233 412.39866 317.614233 417.299832 358.190501 418 358.190501 412.39866 423.952038 412.39866 423.952038 418 464.528305 417.299832 464.528305 413.098827 523.993525 413.098827 523.993525 418 591.853835 417.299832 591.853835 357.085427 574.364064 357.085427 574.364064 328.378559 580.660382 328.378559 580.660382 240.857621 514.898845 240.857621 514.898845 229.654941"></polygon>
            <polygon id="Stroke-7" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" points="407.336174 157.517322 424.276777 157.517322 424.276777 122.475528 407.336174 122.475528"></polygon>
            <polygon id="Stroke-8" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" points="407.336174 222.992004 424.276777 222.992004 424.276777 183.322049 407.336174 183.322049"></polygon>
            <polygon id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" points="244.47537 170.227113 383.080309 170.227113 383.080309 127.084432 244.47537 127.084432"></polygon>
            <polygon id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" points="383.080309 170.227113 383.080309 206.045615 244.481787 206.045615 244.481787 170.227113"></polygon>
            <path d="M288.373351,206.045615 L288.373351,170.227113 L288.373351,206.045615 Z" id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M334.568581,206.045615 L334.568581,170.227113 L334.568581,206.045615 Z" id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></path>
            <polyline id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" points="244.474087 206.05396 288.365651 170.235458 334.56088 206.05396 383.079026 170.235458"></polyline>
            <polyline id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" points="383.079026 206.05396 334.56088 170.235458 288.365651 206.05396 244.474087 170.235458"></polyline>
            <line x1="346.893512" y1="148.65834" x2="346.893512" y2="170.226471" id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></line>
            <line x1="342.658361" y1="127.09021" x2="342.658361" y2="148.65834" id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></line>
            <line x1="280.677569" y1="127.09021" x2="280.677569" y2="148.65834" id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></line>
            <line x1="284.906303" y1="148.65834" x2="284.906303" y2="170.226471" id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></line>
            <line x1="280.669227" y1="148.65834" x2="346.891586" y2="148.65834" id="Stroke-12" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></line>
            <polyline id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" points="530.157476 229.925259 601 0.38514519 498.592767 0.38514519 498.592767 5.38561357"></polyline>
            <line x1="552.103258" y1="125.172187" x2="562.498628" y2="125.172187" id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></line>
            <polyline id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" points="365.371603 31.1967604 365.371603 4.77598977e-12 267.969549 4.77598977e-12 267.969549 5.38561357"></polyline>
            <polyline id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" points="575.589094 357.410243 592.144684 357.410243 592.144684 417.486473 524.003673 417.486473 524.003673 413.249876"></polyline>
            <polyline id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" points="423.893689 412.09444 423.893689 417.486473 464.313713 417.486473 464.313713 413.249876"></polyline>
            <polyline id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" points="317.636319 412.09444 317.636319 417.486473 358.056343 417.486473 358.056343 412.09444"></polyline>
            <polyline id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" points="211.757546 412.09444 211.757546 417.486473 252.17757 417.486473 252.17757 412.09444"></polyline>
            <polyline id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" points="32.3411525 330.064934 27.3359741 328.524353 -1.13620383e-13 417.486473 145.913783 417.486473 145.913783 412.09444"></polyline>
            <polyline id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" points="79.6914231 176.777149 74.6798279 175.236568 110.492521 58.1524303 148.993893 58.1524303"></polyline>
            <line x1="112.032576" y1="122.863241" x2="90.8632379" y2="122.863241" id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></line>
            <line x1="515.148357" y1="229.927185" x2="530.157476" y2="229.927185" id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></line>
            <line x1="103.17726" y1="417.486473" x2="103.17726" y2="377.822938" id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></line>
        </g>
    ),
    compass: (
        <g id="Group" transform="translate(666.000000, 439.000000)">
            <g id="Group-3" transform="translate(-0.000000, -0.000000)">
                <polygon id="Triangle" fill="#6D6C6F" points="0.373344996 2.31092474 24.9689907 2.95574981e-16 17.5516395 8.46906839 17.7779259 19.713931"></polygon>
                <polygon id="Path" fill="#484747" points="0 2.28628273 24.4764585 0.353004374 17.1911927 8.45150412 17.1929131 8.51412667"></polygon>
                <ellipse id="Oval" stroke="#6D6C6F" strokeWidth="2.08091907" fill="#FFFFFF" cx="38.5996674" cy="15.6738079" rx="14.3598731" ry="14.2857326"></ellipse>
                <path d="M35.7097304,21.1989884 L35.7091288,16.1978729 C35.7035141,15.5970509 35.6585964,14.6503812 35.5743759,13.357864 L35.5743759,13.357864 L35.6384912,13.357864 L40.1693045,21.1989884 L42.9761291,21.1989884 L42.9761291,10.8339577 L40.9885553,10.8339577 L40.9885553,15.7399777 C40.9885553,16.2976948 41.0241749,17.2500677 41.0954141,18.5970963 L41.0954141,18.5970963 L41.0455467,18.5970963 L36.5218573,10.8339577 L33.7364044,10.8339577 L33.7364044,21.1989884 L35.7097304,21.1989884 Z" id="N" fill="#6D6C6F" fill-rule="nonzero"></path>
            </g>
        </g>
    ),
    units: [
        {
            id: 'g',
            mockup: (
                <g id="g" transform="translate(265.000000, 52.000000)">
                    <polygon id="shape" stroke="#6D6C6F" strokeWidth="2.1" fill="#FFFFFF" strokeLinecap="round" points="0 0 0 96 117.622041 96 117.622041 69.3958096 118 69.3958096 118 48.5751389 84.9446254 48.5751389 84.9446254 0"></polygon>
                    <g id="type" transform="translate(16.626264, 61.809814)" fill="#2A2A2A" fontFamily="OpenSans-Semibold, Open Sans" fontSize="14" fontWeight="500" letterSpacing="1" lineSpacing="20">
                        <text id="Studio">
                            <tspan x="0.211425781" y="15">STUDIO</tspan>
                        </text>
                    </g>
                    <g transform="translate(31.126264, 14.168224)" fill="#97C93D" fontFamily="OpenSans-Bold, Open Sans" fontSize="40" fontWeight="bold" lineSpacing="48">
                        <text>
                            <tspan x="0" y="43">G</tspan>
                        </text>
                    </g>
                </g>
            ),
        },
        {
            id: 'f',
            mockup: (
                <g id="f" transform="translate(350.000000, 26.000000)">
                    <polygon id="shape" stroke="#6D6C6F" strokeWidth="2.1" fill="#FFFFFF" strokeLinecap="round" points="202 120 202 0 44.7239822 0 44.338486 25.8534755 0 25.8534755 0 74.4670132 33.1526718 74.4670132 33.1526718 95.3041428 57.0534351 95.3041428 57.0534351 117.305322 74.0152672 117.305322 74.0152672 116.919449 165.005216 116.919449 165.005216 120"></polygon>
                    <g id="type" transform="translate(95.470623, 76.884291)" fill="#2A2A2A" fontFamily="OpenSans-Semibold, Open Sans" fontSize="14" fontWeight="500" letterSpacing="1" lineSpacing="20">
                        <text id="3-Bed">
                            <tspan x="0.0209960938" y="15">3 BED</tspan>
                        </text>
                    </g>
                    <g transform="translate(106.470623, 22.218084)" fill="#97C93D" fontFamily="OpenSans-Bold, Open Sans" fontSize="40" fontWeight="bold">
                        <text>
                            <tspan x="0.0234375" y="43">F</tspan>
                        </text>
                    </g>
                </g>
            ),
        },
        {
            id: 'd1',
            mockup: (
                <g id="d1" transform="translate(407.000000, 243.000000)">
                    <polygon id="shape" stroke="#6D6C6F" strokeWidth="2.1" fill="#FFFFFF" strokeLinecap="round" points="174 17.6929046 108.035475 17.6929046 108.035475 22.3148554 70.2147001 22.3148554 70.2147001 15.3851345 59.4188685 15.3851345 59.4188685 1.92314181 16.9814124 1.92314181 16.9814124 0 0 0 0 22.6930733 21.2315879 22.6930733 21.2315879 47.3092884 30.1048742 47.3092884 30.1048742 90.0030365 60.5698237 90.0030365 60.5698237 155.774486 57.0976682 155.774486 57.0976682 190 116.908762 190 116.908762 154.620601 168.598869 154.620601 168.598869 105.003543 174 105.003543"></polygon>
                    <g id="type" transform="translate(85.744137, 111.845399)" fill="#2A2A2A" fontFamily="OpenSans-Semibold, Open Sans" fontSize="14" fontWeight="500" letterSpacing="1" lineSpacing="20">
                        <text id="2-Bed">
                            <tspan x="2.52099609" y="15">2 BED </tspan>
                        </text>
                    </g>
                    <g transform="translate(83.744137, 57.297899)" fill="#97C93D" fontFamily="OpenSans-Bold, Open Sans" fontSize="40" fontWeight="bold">
                        <text>
                            <tspan x="0.279296875" y="43">D1</tspan>
                        </text>
                    </g>
                </g>
            ),
        },
        {
            id: 'c3',
            mockup: (
                <g id="c3" transform="translate(32.000000, 248.000000)">
                    <polygon id="shape" stroke="#6D6C6F" strokeWidth="2.1" fill="#FFFFFF" strokeLinecap="round" points="116.929487 77.6892175 145.775641 77.6892175 145.775641 51.9188854 150 51.9188854 150 17.3020215 87.6923077 17.3020215 31.5384615 0 0 102.299244 35.3846154 150 116.929487 150"></polygon>
                    <g id="type" transform="translate(50.183721, 92.532764)" fill="#2A2A2A" fontFamily="OpenSans-Semibold, Open Sans" fontSize="14" fontWeight="500" letterSpacing="1" lineSpacing="20">
                        <text id="2-Bed">
                            <tspan x="2.52099609" y="15">2 BED </tspan>
                        </text>
                    </g>
                    <g transform="translate(50.183721, 37.984836)" fill="#97C93D" fontFamily="OpenSans-Bold, Open Sans" fontSize="40" fontWeight="bold">
                        <text>
                            <tspan x="0.33984375" y="43">C3</tspan>
                        </text>
                    </g>
                </g>
            ),
        },
        {
            id: 'c1',
            mockup: (
                <g id="c1" transform="translate(149.000000, 26.000000)">
                    <polygon id="shape" stroke="#6D6C6F" strokeWidth="2.1" fill="#FFFFFF" strokeLinecap="round" points="0 0 0 117.371274 0 118.904155 12.7273702 118.904155 12.7273702 142 71.3864623 142 71.3864623 136.227642 95.6900155 136.227642 95.6900155 121.597922 116.527145 121.597922 116.527145 25.7831978 120 25.7831978 120 0"></polygon>
                    <g id="type" transform="translate(32.659647, 86.964839)" fill="#2A2A2A" fontFamily="OpenSans-Semibold, Open Sans" fontSize="14" fontWeight="500" letterSpacing="1" lineSpacing="20">
                        <text id="2-Bed">
                            <tspan x="2.52099609" y="15">2 BED </tspan>
                        </text>
                    </g>
                    <g transform="translate(32.659647, 32.393173)" fill="#97C93D" fontFamily="OpenSans-Bold, Open Sans" fontSize="40" fontWeight="bold">
                        <text>
                            <tspan x="0.33984375" y="43">C1</tspan>
                        </text>
                    </g>
                </g>
            ),
        },
        {
            id: 'b5',
            mockup: (
                <g id="b5" transform="translate(64.000000, 143.000000)">
                    <polygon id="shape" stroke="#6D6C6F" strokeWidth="2.1" fill="#FFFFFF" strokeLinecap="round" points="156 24.6322099 97.5960591 24.6322099 97.5960591 1.53309848 84.9162562 1.53309848 84.9162562 0 48.0295567 0 48.0295567 53.8765445 15.7536946 53.8765445 0 104.686892 56.0985222 122 156 122"></polygon>
                    <g id="type" transform="translate(49.359299, 85.028825)" fill="#2A2A2A" fontFamily="OpenSans-Semibold, Open Sans" fontSize="14" fontWeight="500" letterSpacing="1" lineSpacing="20">
                        <text id="1-Bed+Den">
                            <tspan x="0.499023438" y="15">1 BED+DEN</tspan>
                        </text>
                    </g>
                    <g transform="translate(67.057477, 30.450429)" fill="#97C93D" fontFamily="OpenSans-Bold, Open Sans" fontSize="40" fontWeight="bold">
                        <text>
                            <tspan x="0.146484375" y="43">B5</tspan>
                        </text>
                    </g>
                </g>
            ),
        },
        {
            id: 'b2',
            mockup: (
                <g id="b2" transform="translate(244.000000, 261.000000)">
                    <polygon id="shape" stroke="#6D6C6F" strokeWidth="2.1" fill="#FFFFFF" strokeLinecap="round" points="117 45.7280899 101.996546 45.7280899 101.996546 0 0 0 0 29.2044944 11.1611842 29.2044944 11.1611842 137.952809 7.69736842 137.952809 7.69736842 171 73.1314145 171 73.1314145 137.952809 117 137.952809"></polygon>
                    <g id="type" transform="translate(19.575576, 95.139395)" fill="#2A2A2A" fontFamily="OpenSans-Semibold, Open Sans" fontSize="14" fontWeight="500" letterSpacing="1" lineSpacing="20">
                        <text id="1-Bed+Den">
                            <tspan x="0.499023438" y="15">1 BED+DEN</tspan>
                        </text>
                    </g>
                    <g transform="translate(37.575576, 40.636236)" fill="#97C93D" fontFamily="OpenSans-Bold, Open Sans" fontSize="40" fontWeight="bold">
                        <text>
                            <tspan x="0.146484375" y="43">B2</tspan>
                        </text>
                    </g>
                </g>
            ),
        },
        {
            id: 'b1',
            mockup: (
                <g id="b1" transform="translate(347.000000, 261.000000)">
                    <polygon id="shape" stroke="#6D6C6F" strokeWidth="2.1" fill="#FFFFFF" strokeLinecap="round" points="121 72.2426966 90.6635623 72.2426966 90.6635623 29.588764 36.4895227 29.588764 36.4895227 0 0 0 0 45.7280899 14.976135 45.7280899 14.976135 137.952809 11.5186263 137.952809 11.5186263 171 77.2112922 171 77.2112922 137.952809 117.542491 137.952809 121 137.952809"></polygon>
                    <g id="type" transform="translate(19.662593, 107.015184)" fill="#2A2A2A" fontFamily="OpenSans-Semibold, Open Sans" fontSize="14" fontWeight="500" letterSpacing="1" lineSpacing="20">
                        <text id="1-Bed+Den">
                            <tspan x="0.499023438" y="15">1 BED+DEN</tspan>
                        </text>
                    </g>
                    <g transform="translate(37.662593, 52.512026)" fill="#97C93D" fontFamily="OpenSans-Bold, Open Sans" fontSize="40" fontWeight="bold">
                        <text>
                            <tspan x="0.146484375" y="43">B1</tspan>
                        </text>
                    </g>
                </g>
            ),
        },
        {
            id: 'a2',
            mockup: (
                <g id="a2" transform="translate(146.000000, 290.000000)">
                    <polygon id="shape" stroke="#6D6C6F" strokeWidth="2.1" fill="#FFFFFF" strokeLinecap="round" points="110 0 67.9273642 0 67.9273642 9.62059621 36.668811 9.62059621 32.4293818 9.62059621 32.4293818 35.403794 3.48032049 35.403794 3.48032049 107.750678 0 107.750678 0 142 66.010293 142 66.010293 108.905149 110 108.905149"></polygon>
                    <g id="type" transform="translate(32.759669, 84.933002)" fill="#2A2A2A" fontFamily="OpenSans-Semibold, Open Sans" fontSize="14" fontWeight="500" letterSpacing="1" lineSpacing="20">
                        <text id="1-Bed">
                            <tspan x="0.0209960938" y="15">1 BED</tspan>
                        </text>
                    </g>
                    <g transform="translate(29.259669, 30.361336)" fill="#97C93D" fontFamily="OpenSans-Bold, Open Sans" fontSize="40" fontWeight="bold">
                        <text>
                            <tspan x="0.28515625" y="43">A2</tspan>
                        </text>
                    </g>
                </g>
            ),
        },
        {
            id: 'a1',
            mockup: (
                <g id="a1" transform="translate(407.000000, 143.000000)">
                    <polygon id="shape" stroke="#6D6C6F" strokeWidth="2.1" fill="#FFFFFF" strokeLinecap="round" points="16.9694084 0 16.9694084 0.76875 16.9694084 35.3560937 0 35.3560937 0 61.115625 16.9694084 61.115625 16.9694084 102.621719 59.3993572 102.621719 59.3993572 116.08125 70.1916439 116.08125 70.1916439 123 108 123 108 0"></polygon>
                    <g id="type" transform="translate(40.444175, 71.714885)" fill="#2A2A2A" fontFamily="OpenSans-Semibold, Open Sans" fontSize="14" fontWeight="500" letterSpacing="1" lineSpacing="20">
                        <text id="1-Bed">
                            <tspan x="0.0209960938" y="15">1 BED</tspan>
                        </text>
                    </g>
                    <g transform="translate(36.944175, 17.198705)" fill="#97C93D" fontFamily="OpenSans-Bold, Open Sans" fontSize="40" fontWeight="bold">
                        <text>
                            <tspan x="0.28515625" y="43">A1</tspan>
                        </text>
                    </g>
                </g>
            ),
        },
    ]
}