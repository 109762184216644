import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import vw from 'src/styles/utils';

const DropdownFilter = (props) => {
  const { filters, activeFilter, handleFilter } = props;
  const [isOpen, setIsOpen] = useState(false);
  const handleSelect = () => {
    setIsOpen(!isOpen);
  }
  return (
    <Root>
      <Strapline>Filter</Strapline>
      <Select isOpen={isOpen}>
        <Button
          onClick={handleSelect}
          disable
        >
          {activeFilter.length !== 0 ? 
            activeFilter[0] :
            'Select'
          }
          <img 
            src={require('src/assets/images/icons/dropdown_arrow.svg')} alt='' 
            style={{
              transform: isOpen ? 'rotate(180deg)' : '',
            }}
          />
        </Button>
        <Button 
          onClick={() => {
            handleSelect();
            handleFilter();
          }}
        >
          see all
        </Button>
          {filters.map((filter, index) => (
            <Button 
              key={index}
              onClick={() => {
                handleSelect();
                handleFilter(filter.type);
              }}
            >
              {filter.type}
            </Button>
          ))}
      </Select>
    </Root>
  )
}

export default DropdownFilter;

const Root = styled.div`
  position: absolute;
  z-index: 1;
  ${vw('top', 16, 40)}
  ${vw('left', 12, 30)}
`;

const Strapline = styled.span`
  display: inline-block;
  font-weight: bold;
  text-transform: uppercase;
  ${vw('font-size', 10, 12)}
  ${vw('letter-spacing', 0.5, 0.8)}
  ${vw('margin-bottom', 8, 4)}
`;

const Select = styled.div`
  overflow: hidden;
  ${props => props.isOpen ? vw('max-height', 208, 240) : vw('max-height', 26, 30)};
  transition: all 0.3s ease;
`;

const Button = styled.div`
  background-color: ${({ theme }) => theme.color.brandGrey};
  font-weight: 600;
  color: white;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.color.brandGreen};
  ${vw('font-size', 10, 12)}
  ${vw('letter-spacing', 0.5, 0.8)}
  ${vw('width', 125, 125)}
  ${vw('height', 26, 30)}
  ${vw('padding-left', 11, 11)}
  ${props => !props.disable && css`
    border-bottom: 1px solid ${({ theme }) => theme.color.brandGreen};
    &:hover {
      background-color: ${({ theme }) => theme.color.brandGreen};
      color: ${({ theme }) => theme.color.darkGrey};
    }
  `}
  img {
    position: absolute;
    ${vw('right', 16, 16)}
    ${vw('width', 9, 11)}
  }
`;