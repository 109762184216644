import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Heading from 'components/Heading';
import Tabs from './elements/Tabs';
import Filter from './elements/Filter';
import DropdownFilter from './elements/DropdownFilter';
import ExploreByLevel from './sections/ExploreByLevel';
import ExploreByType from './sections/ExploreByType';
import filters from 'src/data/floorplans/filters';

import vw from 'src/styles/utils';

const Floorplans = () => {
  const browser = useSelector((state) => state.browser);
  const history = useHistory();
  const [activePlan, setActivePlan] = useState(null);
  const [activeLevel, setActiveLevel] = useState(3);
  const [activeFilter, setActiveFilter] = useState([]);

  const [hasTabs, setHasTabs] = useState(null);
  const [activeTab, setActiveTab] = useState(0);  

  useEffect(() => {
    if (!browser.is.desktop) {
      setHasTabs(false);
      setActiveTab(1);
      setActiveFilter([]);
    } else {
      setHasTabs(false);
      setActiveTab(1);
      setActiveFilter([]);
    }
    
    if(typeof history.location.state !== 'undefined') {
      if (typeof history.location.state.activeFilter !== 'undefined') {
        setActiveFilter(history.location.state.activeFilter);
      }
      if(typeof history.location.state.activeLevel !== 'undefined') {
        setActiveLevel(history.location.state.activeLevel);
      }
      if(typeof history.location.state.activeTab !== 'undefined') {
        setActiveTab(history.location.state.activeTab);
      }
    }    
  }, [browser, history])

  const handleLevel = (index) => {
    setActiveLevel(index);
    setActiveFilter([]);
  }

  const handleUnit = (id) => {
    setActivePlan(id);
    history.push({
      pathname: `/floorplans/${id}`,
      state: {
        activeFilter: activeFilter,
        activeLevel: activeLevel,
        activeTab: activeTab,
      },
    })
  }

  const handleTab = (index) => {
    setActiveTab(index);
    setActiveFilter([]);
  }

  const handleFilter = (filter) => {
    if (activeFilter.length === 0 || !activeFilter.includes(filter)) {
      setActiveFilter(currentArray => [...currentArray, filter]);
    } else {
      setActiveFilter(activeFilter.filter(item => item !== filter))
    }
  }

  const handleSelectFilter = (filter) => {
    if (typeof filter === 'undefined') {
      setActiveFilter([]);
    } else {
      setActiveFilter([filter])
    }
  }

  return (
    <Root>
      <Heading 
        title="Find your Home"
        subtitle='Floorplans'
      />
      {hasTabs && 
        <Tabs
          activeTab={activeTab}
          handleTab={handleTab}
        />
      }
      <Container>
        {browser.is.desktop &&
          <Filter 
            filters={filters}
            activeFilter={activeFilter}
            handleFilter={handleFilter}
            activeLevel={activeLevel}
            activeTab={activeTab}
          />
        }
        {!browser.is.desktop && 
          <DropdownFilter
            filters={filters}
            activeFilter={activeFilter}
            handleFilter={handleSelectFilter}
          />
        }
        {hasTabs &&
          activeTab === 0 &&
          <ExploreByLevel
            activeLevel={activeLevel}
            handleLevel={handleLevel}
            handleUnit={handleUnit}
            filters={filters}
            activeFilter={activeFilter}
            active={activeTab === 0}
          />
        }

        {activeTab === 1 &&
          <ExploreByType
            filters={filters}
            activeFilter={activeFilter}
            handleUnit={handleUnit}
            active={activeTab === 1}
          />
        }
      </Container>
    </Root>
  )
}

export default Floorplans;

const Root = styled.div`
  ${vw('padding-bottom', 56, 72, 120)}
`;

const Container = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.color.lightGreen};
`;