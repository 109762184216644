module.exports = {
  defaultLocale: 'en',
  locales: ['en', 'zh'],
  prerender: true,
  dbProject: 'ascent-teaser',
  analytics: {
    gtag: '',
    gtmId: 'GTM-NSHJMJ8',
    optimizeId: 'OPT-59QZQ7W',
    hotjarId: '',
  },
  seo: {
    title: 'Ascent - Surrey City Centre',
  },
  stage: {
    mobile: 320,
    tablet: 768,
    desktop: 1440,
    xl: 1920,
  },
  mediaQuery: {
    tablet: 576,
    desktop: 992,
    xl: 1920,
  },
};
