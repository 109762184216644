import React, { Component } from 'react';
import GTag from './elements/GTag';
import GTM from './elements/GTM';
import Hotjar from './elements/Hotjar';

export default class Analytics extends Component {
  render() {
    return !window.__PRERENDERING ? (
      <>
        <GTag />
        <GTM />
        <Hotjar />
      </>
    ) : null;
  }
}
