// Constants
export const SET_LOADED = 'SET::LOADED';

// Actions
export const setLoaded = () => ({
  type: SET_LOADED,
});

// Initial State
const initialState = {
  hasLoaded: false,
};

// Reducer
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_LOADED:
      return {
        ...state,
        hasLoaded: true,
      };
    default:
      return state;
  }
};