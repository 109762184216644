export default [
  {
    image: require('src/assets/images/neighbourhood/card_people_icon.svg'),
    number: 1500,
    text:
      'The estimated number of people moving to the City of Surrey every month',
    morePadding: true,
  },
  {
    image: require('src/assets/images/neighbourhood/card_medtech_icon.svg'),
    number: 180,
    suffix: '+',
    text:
      'The number of medtech companies concentrated along one square mile of the city centre',
  },
  {
    image: require('src/assets/images/neighbourhood/card_size_icon.svg'),
    number: 19,
    suffix: '+ MILLION',
    text: `Amount of square footage planned for new commercial, retail, and office space in Surrey's Central Business District.`,
    lessPadding: true,
  },
];
