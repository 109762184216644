import React from 'react';

export default {
    width: '720px',
    height: '470px',
    viewBox: '0 0 720 470',
    defs: (
        <defs>
            <filter x="-9.7%" y="-18.3%" width="119.3%" height="136.5%" filterUnits="objectBoundingBox" id="filter-1">
                <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                <feGaussianBlur stdDeviation="11.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                <feColorMatrix values="0 0 0 0 0.37254902   0 0 0 0 0.501960784   0 0 0 0 0.141176471  0 0 0 0.303646061 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
                <feMerge>
                    <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                    <feMergeNode in="SourceGraphic"></feMergeNode>
                </feMerge>
            </filter>
        </defs>
    ),
    outline: (
        <g id="lines" filter="url(#filter-1)" transform="translate(0.000000, 109.000000)">
            <polygon id="Path-2" fill="#EFEFEF" points="450.814487 3.06942061 450.814487 24.0467166 483 24.0467166 483 175.782491 450.814487 175.782491 450.814487 244.308325 344.462358 244.308325 344.462358 252 332.567712 252 332.567712 250.601514 0.217308329 250.601514 18.4091199 190.466598 49.8949476 168.790059 49.8949476 125.436981 38.6999867 125.436981 58.2911683 61.8058495 86.9782558 61.8058495 86.9782558 3.06942061"></polygon>
            <path d="M217.952896,50.5130626 L356.191441,50.5130626 L356.191441,3.20915264 L217.952896,3.20915264 L217.952896,50.5130626 Z M356.191441,50.5130626 L356.191441,86.27787 L217.952896,86.27787 L217.952896,50.5130626 L356.191441,50.5130626 Z M261.850373,86.27787 L261.850373,50.5130626 L261.850373,86.27787 Z M308.058243,86.27787 L308.058243,50.5130626 L308.058243,86.27787 Z" id="Stroke-10" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M217.94712,86.2804342 L261.844597,50.5156268 L308.052467,86.2804342 L356.185665,50.5156268 M356.185665,86.2804342 L308.052467,50.5156268 L261.844597,86.2804342 L217.94712,50.5156268" id="Stroke-11" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M320.374566,26.6572501 L320.374566,50.5111394 M316.145262,3.20722945 L316.145262,26.6572501 M253.764637,3.20722945 L253.764637,26.6572501 M257.993941,26.6572501 L257.993941,50.5111394" id="Stroke-12" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></path>
            <line x1="253.758861" y1="26.6572501" x2="320.375207" y2="26.6572501" id="Stroke-13" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></line>
            <polygon id="Stroke-14" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" points="380.82922 103.195476 397.765688 103.195476 397.765688 63.5843016 380.82922 63.5843016"></polygon>
            <polygon id="Stroke-15" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" points="380.82922 37.8072285 397.765688 37.8072285 397.765688 3.20274203 380.82922 3.20274203"></polygon>
            <path d="M356.197217,3.20530627 L380.834996,3.20530627 L356.197217,3.20530627 Z" id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M217.952255,3.20530627 L193.693123,3.20530627 L217.952255,3.20530627 Z" id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M86.6397563,61.6649642 L58.1513209,61.6649642 M49.679878,168.593981 L18.483148,190.518276 L7.95449819e-14,250.515199 L332.311599,250.515199 M153.63475,218.205712 L153.63475,250.515199" id="Stroke-18" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M450.916933,23.9712035 L482.877376,23.9712035 L482.877376,175.89631 L450.916933,175.89631 M86.6448905,61.6591946 L58.1500373,61.6591946 L38.5116926,125.124259 L49.6785945,125.124259" id="Stroke-27" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></path>
        </g>
    ),
    compass: (
        <g id="Group" transform="translate(666.000000, 439.000000)">
            <g id="Group-3" transform="translate(-0.000000, -0.000000)">
                <polygon id="Triangle" fill="#6D6C6F" points="0.373344996 2.31092474 24.9689907 -3.66818172e-13 17.5516395 8.46906839 17.7779259 19.713931"></polygon>
                <polygon id="Path" fill="#484747" points="-2.3037041e-14 2.28628273 24.4764585 0.353004374 17.1911927 8.45150412 17.1929131 8.51412667"></polygon>
                <ellipse id="Oval" stroke="#6D6C6F" strokeWidth="2.08091907" fill="#FFFFFF" cx="38.5996674" cy="15.6738079" rx="14.3598731" ry="14.2857326"></ellipse>
                <path d="M35.7097304,21.1989884 L35.7091288,16.1978729 C35.7035141,15.5970509 35.6585964,14.6503812 35.5743759,13.357864 L35.5743759,13.357864 L35.6384912,13.357864 L40.1693045,21.1989884 L42.9761291,21.1989884 L42.9761291,10.8339577 L40.9885553,10.8339577 L40.9885553,15.7399777 C40.9885553,16.2976948 41.0241749,17.2500677 41.0954141,18.5970963 L41.0954141,18.5970963 L41.0455467,18.5970963 L36.5218573,10.8339577 L33.7364044,10.8339577 L33.7364044,21.1989884 L35.7097304,21.1989884 Z" id="N" fill="#6D6C6F" fillRule="nonzero"></path>
            </g>
        </g>
    ),
    units: [
        {
            id: 'd3',
            mockup: (
                <g id="d3" transform="translate(154.000000, 230.000000)">
                    <polygon id="shape" stroke="#6D6C6F" strokeWidth="2.1" fill="#FFFFFF" strokeLinecap="round" points="182 1.98110499e-14 64.3940678 1.98110499e-14 64.3940678 4.21766915 40.4937147 4.21766915 40.4937147 9.20218723 1.19480968e-13 9.20218723 1.19480968e-13 97 178.915254 97 182 97"></polygon>
                    <g id="type" transform="translate(69.235780, 66.167356)" fill="#2A2A2A" fontFamily="OpenSans-Semibold, Open Sans" fontSize="14" fontWeight="500" letterSpacing="1" line-spacing="20">
                        <text id="2-Bed">
                            <tspan x="0.0209960938" y="15">2 BED</tspan>
                        </text>
                    </g>
                    <g transform="translate(64.735780, 10.739073)" fill="#97C93D" fontFamily="OpenSans-Bold, Open Sans" fontSize="40" fontWeight="bold" id="D3">
                        <text>
                            <tspan x="0.279296875" y="43">D3</tspan>
                        </text>
                    </g>
                </g>
            ),
        },
        {
            id: 'e3',
            mockup: (
                <g id="e3" transform="translate(50.000000, 112.000000)">
                    <polygon id="shape" stroke="#6D6C6F" strokeWidth="2.1" fill="#FFFFFF" strokeLinecap="round" points="143.609617 1.98737681e-14 36.8623617 1.98737681e-14 36.8623617 121.923937 0 121.923937 0 215 103.675392 215 103.675392 126.924235 144 126.924235 144 121.923937 143.609617 121.923937"></polygon>
                    <g id="type" transform="translate(46.570203, 75.074394)" fill="#2A2A2A" fontFamily="OpenSans-Semibold, Open Sans" fontSize="14" fontWeight="500" letterSpacing="1" line-spacing="20">
                        <text id="2-Bed+Den">
                            <tspan x="0.499023438" y="15">2 BED+DEN</tspan>
                        </text>
                    </g>
                    <g transform="translate(66.570203, 20.518123)" fill="#97C93D" fontFamily="OpenSans-Bold, Open Sans" fontSize="40" fontWeight="bold" id="E3">
                        <text>
                            <tspan x="0.3828125" y="43">E3</tspan>
                        </text>
                    </g>
                </g>
            ),
        },
        {
            id: 'e2',
            mockup: (
                <g id="e2" transform="translate(333.000000, 112.000000)">
                    <polygon id="shape" stroke="#6D6C6F" strokeWidth="2.1" fill="#FFFFFF" strokeLinecap="round" points="119 1.99161644e-14 65.6818182 1.99161644e-14 65.6818182 34.6784489 48.6818182 34.6784489 48.6818182 60.5106169 65.6818182 60.5106169 65.6818182 100.21288 48.6818182 100.21288 48.6818182 111.391187 23.9609848 111.391187 23.9609848 117.943987 3.09090909 117.943987 3.09090909 215.458655 -1.29696332e-13 215.458655 -1.29696332e-13 249 11.9772727 249 11.9772727 241.290823 119 241.290823"></polygon>
                    <g id="type" transform="translate(16.574517, 183.464489)" fill="#2A2A2A" fontFamily="OpenSans-Semibold, Open Sans" fontSize="14" fontWeight="500" letterSpacing="1" line-spacing="20">
                        <text id="2-Bed+Den">
                            <tspan x="0.499023438" y="15">2 BED+DEN</tspan>
                        </text>
                    </g>
                    <g transform="translate(36.574517, 128.834347)" fill="#97C93D" fontFamily="OpenSans-Bold, Open Sans" fontSize="40" fontWeight="bold" id="E2">
                        <text>
                            <tspan x="0.3828125" y="43">E2</tspan>
                        </text>
                    </g>
                </g>
            ),
        },
    ]
}