import React from 'react';
import styled from 'styled-components';

import vw, { vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Input = (props) => {
  const { name, label, pattern, setField, required } = props;
  return (
    <Root>
      <label htmlFor={name} className='form-body'>{label}</label>
      <input name={name} pattern={pattern} required={required} onChange={(e) => setField(e.target.value)} />
    </Root>
  )
}

export default Input;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  ${vw('margin-bottom', 24, 24, 60)}
  label {
    ${vw('margin-bottom', 4, 8, 11)}
  }
  input {
    border: none;
    outline: none;
    background-color: ${({ theme }) => theme.color.lightGreen};
    font-family: ${({ theme }) => theme.font.family};
    line-height: normal;
    ${vw('font-size', 16, 16)}
    ${vw('letter-spacing', 0, 0, 0)};
    ${vw('width', 294, 505, 554)}
    ${vw('height', 32, 36, 36)}
    ${vw('padding-left', 8, 9, 18)}
  }
  @media ${media.desktop} {
    input {
      line-height: ${vwDesktop(26)};
    }
  }
`;