import React from 'react';
import styled from 'styled-components';

import vw from 'src/styles/utils';

const Select = (props) => {
  const { id, label, options, setField, required } = props;
  return (
    <Root src={require('src/assets/images/register_now_shape.svg')}>
      <label className='form-body' htmlFor={id}>{label}</label>
      <span className='arrow-down' />
      <select name={id} onChange={(e) => setField(e.target.value)} required={required}>
          <span className='arrow-down' />
          <option selected value="" disabled>Select</option>
          {options.map((option, i) => (
            <option key={i} value={option}>{option}</option>
          ))}
      </select>
    </Root>
  )
}

export default Select;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  ${vw('margin-bottom', 24, 24, 60)}
  label {
    ${vw('margin-bottom', 4, 8, 11)}
  }
  select {
    border: none;
    outline: none;
    appearance: none;
    position: relative;
    border-radius: 0;
    background-color: ${({ theme }) => theme.color.lightGreen};
    font-family: ${({ theme }) => theme.font.family};
    line-height: normal;
    ${vw('font-size', 16, 16)}
    ${vw('letter-spacing', 0, 0, 0)};
    ${vw('width', 294, 505, 554)}
    ${vw('height', 32, 36, 36)}
    ${vw('padding-left', 8, 9, 18)}
  }
  option {
    font-family: ${({ theme }) => theme.font.family};
    line-height: normal;
    ${vw('font-size', 16, 16)}
    ${vw('letter-spacing', 0, 0, 0)};
  }
  .arrow-down {
    position: absolute;
    z-index: 1;
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid ${({ theme }) => theme.color.darkGrey};
    ${vw('bottom', 13, 15)}
    ${vw('right', 16, 16)}
  }
`;