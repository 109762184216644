import React from 'react';
import styled from 'styled-components';

import Hero from 'components/Hero';
import Intro from 'components/Layout/Intro';
import Callouts from './sections/Callouts';
import ContentBlock from './elements/ContentBlock';

import content from 'src/data/amenitiesContent';

const Amenities = () => {
  return (
    <Root>
      <Hero
        image={require('src/assets/images/amenities/hero_rendering.jpg')}
        mobileImage={require('src/assets/images/amenities/mobile_hero_rendering.jpg')}
        title="MORE AT YOUR FRONT DOOR"
        shortTitle
        subtitle="AMENITIES"
      />
      <Intro
        title="A VERTICAL VILLAGE"
        text="Spanning three floors and culminating at the top of the building, the amenities at Ascent offer endless choices for recreation and relaxation."
        tablet={315}
        desktop={429}
      />
      <Callouts />
      {content.map((item, index) => (
        <ContentBlock data={item} key={index} />
      ))}
    </Root>
  );
};

const Root = styled.div``;

export default Amenities;
