import React from 'react';
import styled, { css } from 'styled-components';

import vw, { vwDesktop } from 'src/styles/utils';

const Tabs = (props) => {
  const { activeTab, handleTab } = props;
  return (
    <Root>
      <Button
        active={activeTab === 0}
        onClick={() => handleTab(0)}
      >
        Explore By Level
      </Button>
      <Button
        active={activeTab === 1}
        onClick={() => handleTab(1)}
      >
        Explore By Type
      </Button>
    </Root>
  )
}

export default Tabs;

const Root = styled.div`
  width: 100%;
  padding: 0 ${vwDesktop(84)};
  margin-bottom: ${vwDesktop(63)};
`;

const Button = styled.button`
  cursor: pointer;
  position: relative;
  font-family: ${({ theme }) => theme.font.family};
  font-weight: bold;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.darkGrey};
  transition: all 0.3s ease;
  ${vw('font-size', 16, 16, 16)}
  ${vw('letter-spacing', 2, 2, 2)}
  &:first-of-type {
    margin-right: ${vwDesktop(65)};
  }
  &:after {
    content: '';
    width: 100%;
    height: ${vwDesktop(3)};
    background-color: ${({ theme }) => theme.color.brandGreen};
    position: absolute;
    bottom: ${vwDesktop(-12)};
    left: 0;
    transition: all 0.3s ease;
  }
  ${props => props.active && css`
    opacity: 1;
    &:after {
      opacity: 1;
    }
  `}

  ${props => !props.active && css`
    opacity: 0.5;
    &:after {
      opacity: 0;
    }
  `}
`;