import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import Button from 'components/Button';

import vw, { vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Features = (props) => {
  const { data } = props;
  const browser = useSelector((state) => state.browser);
  const pos = ['a', 'b', 'c', 'd', 'e', 'f'];
  return (
    <Root>
      {!browser.is.mobile &&
        <span>Feature Sheet</span>
      }
      <h2>More Value In the Details</h2>
      {
        browser.is.mobile &&
        <p>Set on this minimalist backdrop, four contemporary colour options let you bring your personality into the home. Live with everything you need in a home designed to reflect your style.</p>
      }
      <ul>
        {data.map((feature, index) => (
          <ListItem index={pos[index]} key={index}>{feature}</ListItem>
        ))}
      </ul>
      <Button 
        to="/pdfs/AscentFeatureSheet.pdf"
        text='Download Feature Sheet'
        target="_blank"
        download
      />
    </Root>
  )
}

export default Features;

const Root = styled.div`
  ${vw('padding-top', 57, 0)}
  ${vw('padding-right', 13, 29, 74)}
  ${vw('padding-bottom', 56, 72, 120)}
  ${vw('padding-left', 12, 30,74)}
  h2 {
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.brandGreen};
    line-height: normal;
    ${vw('margin-bottom', 16, 24, 40)}
    ${vw('width', 161, 420, 692)}
  }
  span {
    display: inline-block;
    font-family: ${({ theme }) => theme.font.family};
    font-weight: 600;
    color: ${({ theme }) => theme.color.brandGrey};
    text-transform: uppercase;
    ${vw('font-size', 13, 13, 14)}
    ${vw('letter-spacing', 1, 1, 1.2)}
    ${vw('margin-bottom', 2, 2, 8)}
  }
  p {
    ${vw('margin-bottom', 16, 16)}
  }
  ul {
    list-style: none;
    ${vw('margin-bottom', 24, 40, 56)}
    li {
      position: relative;
      color: ${({ theme }) => theme.color.darkGrey};
      display: flex;
      ${vw('font-size', 14, 14, 16)}
      ${vw('line-height', 21, 21, 26)}
      ${vw('letter-spacing', 0, 0, 0)}
      ${vw('padding-left', 16, 15, 28)}
      &:not(:last-of-type) {
        ${vw('margin-bottom', 16, 0)}
      }
      &:before {
        content: '';
        border-radius: 50%;
        background-color: ${({ theme }) => theme.color.brandGreen};
        opacity: 0.4;
        position: absolute;
        left: 0;
        ${vw('width', 6, 5, 8)}
        ${vw('height', 6, 5, 8)}
        ${vw('top', 10, 10, 11)}
      }
    }

    @media ${media.tablet} {
      display: grid;
      grid-template-areas: 
      'a c f'
      'b d e';
      grid-column-gap: ${vwTablet(20)};
      grid-row-gap: ${vwTablet(20)};
      justify-content: space-between;
    }
    @media ${media.desktop} {
      grid-column-gap: ${vwDesktop(124)};
      grid-row-gap: ${vwDesktop(18)};
    }
  }
`;

const ListItem = styled.li`
  position: relative;
  grid-area: ${props => props.index};
  color: ${({ theme }) => theme.color.darkGrey};
  display: flex;
  ${vw('font-size', 14, 14, 16)}
  ${vw('line-height', 21, 21, 26)}
  ${vw('letter-spacing', 0, 0, 0)}
  ${vw('padding-left', 16, 15, 28)}
  &:not(:last-of-type) {
    ${vw('margin-bottom', 16, 0)}
  }
  &:before {
    content: '';
    border-radius: 50%;
    background-color: ${({ theme }) => theme.color.brandGreen};
    opacity: 0.4;
    position: absolute;
    left: 0;
    ${vw('width', 6, 5, 8)}
    ${vw('height', 6, 5, 8)}
    ${vw('top', 10, 10, 11)}
  }
  @media ${media.tablet} {
    br {
      display: none;
    }
  }
  @media ${media.desktop} {
    br {
      display: inline-block;
    }
  }
`;