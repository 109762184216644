import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

import Register from './Register';

import vw, { vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

import data from 'src/data/menu';

const Menu = (props) => {
  const { isOpen, innerRef, handleClose, isScrollingDown } = props;
  const location = useLocation();
  const path = location.pathname.replace('/', '').toLowerCase();

  return (
    <Root
      style={{
        opacity: isOpen ? 1 : 0,
        pointerEvents: isOpen ? 'all' : 'none',
      }}
      ref={innerRef}
    >
      <Image
        src={require('src/assets/images/ascent_hero_rendering_menu.jpg')}
        alt="Rendering"
      />
      <Wrapper>
        {data.map((item, index) => (
          <Link
            className={`menu-items white ${
              path === item.to.replace('/', '').toLowerCase()
                ? 'green active'
                : 'white'
            }`}
            to={item.to}
            key={index}
            onClick={handleClose}
          >
            <div>
              <img
                src={require('src/assets/images/icons/green_diagonal_line.svg')}
                alt="Line"
              />
            </div>
            <span>{item.text}</span>
          </Link>
        ))}
      </Wrapper>
      <Container isScrollingDown={isScrollingDown}>
        <Register handleClick={handleClose} />
        <Close onClick={handleClose}>
          <img
            src={require('src/assets/images/icons/modal_close.svg')}
            alt="Close menu"
          />
        </Close>
      </Container>
    </Root>
  );
};

Menu.propTypes = {
  isOpen: PropTypes.bool,
  innerRef: PropTypes.object,
};

const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.color.brandGrey};
  height: 100%;
  width: 100%;
  transition: 0.4s ease;
  z-index: 9;
  ${vw('padding-top', 50, 84, 0)}
  @media ${media.desktop} {
    display: flex;
    width: 100%;
    z-index: 11;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  a {
    transition: 0.4s ease;
    ${vw('margin-bottom', 28, 45, 29.4)}
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .menu-items img {
    display: none;
  }
  @media ${media.tablet} {
    justify-content: flex-start;
    padding-top: ${vwTablet(178)};
  }
  @media ${media.desktop} {
    justify-content: center;
    align-items: flex-start;
    padding-top: ${vwDesktop(70)};
    padding-left: ${vwDesktop(122)};
    width: auto;
    a {
      display: flex;
      align-items: center;
      height: ${vwDesktop(48)};
      transition: 0.4s ease;
      &:hover {
        color: ${({ theme }) => theme.color.brandGreen};
      }
    }
    .menu-items div {
      width: ${vwDesktop(29)};
      height: ${vwDesktop(48)};
      max-width: 0;
      margin-right: 0;
      transition: 0.4s ease;
      overflow: hidden;
    }
    .menu-items img {
      display: block;
      width: ${vwDesktop(29)};
    }
    .menu-items:hover div,
    .menu-items.active div {
      max-width: ${vwDesktop(29)};
      margin-right: ${vwDesktop(8)};
    }
  }
`;

const Container = styled.div`
  display: none;
  @media ${media.desktop} {
    display: flex;
    align-items: center;
    position: absolute;
    top: ${props => props.isScrollingDown ? vwDesktop(18) : vwDesktop(28)};
    right: ${vwDesktop(32)};
  }
`;

const Image = styled.img`
  display: none;
  @media ${media.desktop} {
    display: block;
    width: ${vwDesktop(960)};
    object-fit: cover;
    object-position: top;
  }
`;

const Close = styled.div`
  width: ${vwDesktop(32)};
  height: ${vwDesktop(32)};
  margin-left: ${vwDesktop(40)};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    width: ${vwDesktop(19)};
    height: ${vwDesktop(19)};
  }
`;

export default Menu;
