import { useEffect, useState } from 'react';

const useScrollDown = (inputThreshold) => {
  const [isScrollingDown, setIsScrollingDown] = useState(false);

  const threshold = inputThreshold || 100;

  useEffect(() => {
    let lastPosition = 0;

    const handleScroll = (e) => {
      let scrollPos = window.pageYOffset;

      // checks to see if user is scrolling up or down
      if (
        scrollPos > lastPosition &&
        e.target.scrollingElement.scrollTop > threshold
      ) {
        setIsScrollingDown(true);
      } else if (scrollPos < lastPosition) {
        setIsScrollingDown(false);
      }
      lastPosition = scrollPos;
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [threshold]);

  return isScrollingDown;
};

export default useScrollDown;
