import React, { useState, useRef } from 'react';
import styled from 'styled-components';

import useScrollDown from 'components/hooks/useScrollDown';
import Map from '../elements/Map';
import Category from '../elements/Category';

import vw, { vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

import data from 'src/data/map';

const Location = () => {
  const [activeCategory, setActiveCategory] = useState(0);
  const $ref = useRef();
  const isScrollingDown = useScrollDown();

  const handleCategory = (index) => {
    index === activeCategory
      ? setActiveCategory(null)
      : setActiveCategory(index);
  };

  return (
    <Root>
      <Wrapper>
        <h2 className="green">
          AT THE CENTRE
          <br />
          OF THE CITY CENTRE
        </h2>
        <p className="darkGrey">
          Just steps to Surrey Central SkyTrain Station and the Civic Plaza,
          Ascent stands next to all the urban amenities while also offering
          instant access to the rest of the city and Metro Vancouver. The ideal
          location for those who want to live downtown, and occasionally get out
          of town.
        </p>
      </Wrapper>
      <Container isScrollingDown={isScrollingDown} ref={$ref}>
        <Map data={data} activeCategory={activeCategory} $ref={$ref} />
        <div className="categories" id="sticky">
          {data.map((item, index) => (
            <Category
              data={item}
              active={activeCategory === index}
              key={index}
              handleClick={() => handleCategory(index)}
            />
          ))}
        </div>
      </Container>
    </Root>
  );
};

const Root = styled.div`
  ${vw('margin-top', 56, 72, 120)}
  ${vw('padding-bottom', 56, 72, 120)}
  ${vw('padding-left', 12, 30, 74)}
  ${vw('padding-right', 12, 30, 74)}
  h2 {
    line-height: normal;
    ${vw('margin-bottom', 16, 24, 0)}
  }
`;

const Wrapper = styled.div`
  @media ${media.desktop} {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    p {
      max-width: ${vwDesktop(646)};
    }
  }
`;

const Container = styled.div`
  ${vw('margin-top', 24, 40, 80)}
  @media ${media.tablet} {
    display: flex;
    justify-content: space-between;
  }
  @media ${media.desktop} {
    height: auto;
    .categories {
      position: -webkit-sticky;
      position: sticky;
      top: ${(props) => (props.isScrollingDown ? 0 : vwDesktop(96))};
      align-self: flex-start;
      transition: 0.4s ease;
    }
  }
`;

export default Location;
