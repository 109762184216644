import theme from 'src/styles/theme';

export default [
  {
    title: 'Convenience',
    mapImage: require('src/assets/images/neighbourhood/map_convenience.jpg'),
    color: theme.color.mapConvenience,
    points: [
      'Safeway',
      'London Drugs',
      'Save-On-Foods',
      'Canadian Tire',
      'T&T Supermarket',
      'Shoppers Drug Mart',
      'Walmart Supercentre',
      'Prospera Credit Union',
      '150+ Shops at Central City Shopping Centre',
    ],
  },
  {
    title: 'Parks & Recreation',
    mapImage: require('src/assets/images/neighbourhood/map_park_recreation.jpg'),
    color: theme.color.mapParksRec,
    points: [
      'Surrey City Hall',
      'North Surrey Recreation Centre',
      'The Civic Plaza',
      'Surrey City Centre Library',
      'Chuck Bailey Recreation Centre',
      'Holland Park',
      'Quibble Creek Greenway',
      'Hawthorne Park',
      'Whalley Athletic Park',
      'Forsyth Park',
      'Green Timbers Urban Forest',
    ],
  },
  {
    title: 'Education',
    mapImage: require('src/assets/images/neighbourhood/map_education.jpg'),
    color: theme.color.mapEducation,
    points: [
      'Douglas College',
      'Kwantlen Polytechnic University',
      'Canadian Health Care Academy',
      'Stenberg College',
      'Vancouver Career College',
      'Sprott Shaw College Surrey',
      'SFU',
      'Westminster College',
      'Canadian College',
    ],
  },
  {
    title: 'Restaurants',
    mapImage: require('src/assets/images/neighbourhood/map_restaurants.jpg'),
    color: theme.color.mapRestaurants,
    points: [
      'Bon Ga Korean Restaurant',
      'Fusion spice',
      'Starbucks',
      `Bozzini's Restaurant`,
      'Round-Up Cafe',
      'Rickshaw Chinese Food',
      'DONI Korean Restaurant',
      'Dominion Bar + Kitchen',
      'White Spot Central City',
      'Boston Pizza',
      'Central City Brew Pub Surrey',
      'Nahm Thai Bistro',
      'Prado Cafe',
      'Chatime Surrey',
      'An Pho Vietnamese Restaurant',
      'All About Pho Vietnamese Noodle House',
      'New Town Bakery & Restaurant',
      'Umami Ramen + Grill',
      'Take Five Café',
    ],
  },
];
