import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import Button from 'components/Button';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const CTA = () => {
  const browser = useSelector((state) => state.browser);
  return (
    <Root src={browser.is.mobile ? 
      require('src/assets/images/footer/sub-footer_image_mobile.jpg') :
      require('src/assets/images/footer/sub-footer_image.jpg')
    }>
      <h1 className="footer yellow">OWN YOUR ASCENT</h1>
      <Button to='/register' text="Register for Updates" />
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${vw('padding-top', 50, 46, 86)}
  ${vw('padding-bottom', 38, 34, 84)}
  ${vw('padding-left', 0, 30, 72)}
  h1 {
    ${vw('margin-bottom', 16, 16, 28)}
  }
  @media ${media.tablet} {
    align-items: flex-start;
  }
`;

export default CTA;
