import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import vw, { vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Select = (props) => {
  const { data, activeLevel, handleLevel } = props;
  return (
    <Root>
      <Mobile>
        <Arrow
          src={require('src/assets/images/icons/back_arrow.svg')}
          alt="Swipe"
        />
        <h4 className="button grey">SWIPE TO SEE ALL 3 LEVELS</h4>
        <Arrow
          src={require('src/assets/images/icons/back_arrow.svg')}
          alt="Swipe"
          right
        />
      </Mobile>
      <Wrapper>
        {data.map((item, index) => (
          <Button
            className="button-map"
            onClick={() => handleLevel(index)}
            active={activeLevel === index}
          >
            {item.label}
          </Button>
        ))}
      </Wrapper>
    </Root>
  );
};

Select.propTypes = {
  data: PropTypes.array,
  activeLevel: PropTypes.number,
  handleLevel: PropTypes.func,
};

const Root = styled.div`
  ${vw('margin-bottom', 10, 34)}
`;

const Mobile = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${vw('padding-left', 12)}
  ${vw('padding-right', 12)}
  h4.button {
    color: ${({ theme }) => theme.color.brandGrey};
  }
  @media ${media.tablet} {
    display: none;
  }
`;

const Wrapper = styled.div`
  display: none;
  @media ${media.tablet} {
    display: flex;
    padding-left: ${vwTablet(40)};
  }
  @media ${media.desktop} {
    padding-left: ${vwDesktop(84)};
  }
`;

const Button = styled.button`
  position: relative;
  opacity: ${(props) => (props.active ? 1 : 0.5)};
  transition: 0.4s ease;
  cursor: pointer;
  ${vw('margin-right', 0, 64, 64)}
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: ${`calc(100% + ${vwTablet(8)})`};
    left: 0;
    background-color: ${({ theme }) => theme.color.brandGreen};
    transition: 0.4s ease;
    opacity: ${(props) => (props.active ? 1 : 0)};
    width: 100%;
    ${vw('height', 2, 2, 3)}
    @media ${media.desktop} {
      width: 110%;
      left: -5%;
      top: ${`calc(100% + ${vwDesktop(12)})`};
    }
  }
`;

const Arrow = styled.img`
  transform: ${(props) => (props.right ? 'scaleX(-1)' : 'scaleX(1)')};
  ${vw('width', 8)};
`;

export default Select;
