import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Gallery from './Gallery';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const ContentBlock = (props) => {
  const { data } = props;
  return (
    <Root vertical={data.vertical} reverse={data.reverse}>
      <Wrapper
        reverse={data.reverse}
        size={data.sizes.text}
        vertical={data.vertical}
      >
        <div>
          <h4 className="strapline grey">{data.subtitle}</h4>
          <h2 className="green">{data.title}</h2>
        </div>
        <p>{data.text}</p>
      </Wrapper>
      {data.gallery ? (
        <Container reverse={data.reverse}>
          <Gallery images={data.images} />
        </Container>
      ) : (
        <Image
          src={data.image}
          alt={data.title}
          size={data.sizes.image}
          margins={data.sizes.margin}
          reverse={data.reverse}
          vertical={data.vertical}
        />
      )}
    </Root>
  );
};

ContentBlock.propTypes = {
  data: PropTypes.object,
};

const Root = styled.div`
  padding-bottom: ${vwMobile(56)};
  .tablet-only {
    display: none;
  }
  @media ${media.tablet} {
    padding: 0 ${vwTablet(30)} ${vwTablet(72)};
    display: flex;
    flex-direction: ${(props) => (props.vertical ? 'column' : 'row')};
    justify-content: ${(props) =>
      !props.reverse && !props.vertical ? 'space-between' : 'flex-start'};
    align-items: ${(props) => (props.vertical ? 'flex-start' : 'center')};
    .tablet-only {
      display: block;
    }
  }
  @media ${media.desktop} {
    padding: 0 ${vwDesktop(74)} ${vwDesktop(120)};
    .tablet-only {
      display: none;
    }
  }
`;

const Wrapper = styled.div`
  padding: 0 ${vwMobile(12)};
  h2 {
    margin: ${vwMobile(1)} 0 ${vwMobile(16)};
    line-height: normal;
  }
  @media ${media.tablet} {
    padding: 0;
    width: ${(props) => (props.vertical ? '100%' : 'auto')};
    order: ${(props) => (props.reverse ? 2 : 1)};
    display: ${(props) => (props.vertical ? 'flex' : 'block')};
    justify-content: space-between;
    h2 {
      margin: ${vwTablet(2)} 0 ${vwTablet(16)};
      line-height: 1.29;
    }
    h4 br {
      display: none;
    }
    p {
      max-width: ${(props) => vwTablet(props.size.tablet)};
      margin-top: ${vwTablet(20)};
    }
  }
  @media ${media.desktop} {
    h2 {
      margin: ${vwDesktop(8)} 0
        ${(props) => (props.vertical ? 0 : vwDesktop(24))};
      line-height: 1.29;
    }
    p {
      max-width: ${(props) => vwDesktop(props.size.desktop)};
      margin-top: ${vwDesktop(28)};
    }
  }
`;

const Container = styled.div`
  order: ${(props) => (props.reverse ? 1 : 2)};
  margin-top: ${vwMobile(24)};
  width: 100%;
  @media ${media.tablet} {
    margin-top: ${vwTablet(40)};
  }
  @media ${media.desktop} {
    margin-top: ${vwDesktop(80)};
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  display: block;
  margin-top: ${vwMobile(24)};
  @media ${media.tablet} {
    margin-top: ${(props) => (props.vertical ? vwTablet(24) : 0)};
    order: ${(props) => (props.reverse ? 1 : 2)};
    width: ${(props) =>
      props.vertical ? '100%' : vwTablet(props.size.tablet)};
    margin-right: ${(props) => props.reverse && vwTablet(props.margins.tablet)};
  }
  @media ${media.desktop} {
    margin-top: ${(props) => (props.vertical ? vwDesktop(80) : 0)};
    width: ${(props) =>
      props.vertical ? '100%' : vwDesktop(props.size.desktop)};
    margin-right: ${(props) =>
      props.reverse && vwDesktop(props.margins.desktop)};
  }
`;

export default ContentBlock;
