import React from 'react';
import styled from 'styled-components';

import levels from 'src/data/floorplans/levels';

import vw, { vwDesktop } from 'src/styles/utils';

const Filter = (props) => {
  const { filters, activeFilter, handleFilter, activeLevel, activeTab } = props;
  return (
    <Root>
      <span>Filter By Type</span>
      <Container>
        {filters.map((filter, index) => (
          <Button
            key={index}
            active={activeFilter.includes(filter.type)}
            onClick={() => handleFilter(filters[index].type)}
            disable={!levels.filtersEnabled[activeLevel].includes(filter.type) && activeTab === 0 || filter.sold}
          >
            {filter.type}
            <Close active={activeFilter.includes(filter.type)} />
            <Tag active={filter.sold}>
              <img src={require('src/assets/images/floorplans/filter_badge_shape.svg')} alt='' />
              <span>Sold <br/> Out</span>
            </Tag>
          </Button>
        ))}
      </Container>
    </Root>
  )
}

export default Filter;

const Root = styled.div`
  position: absolute;
  top: ${vwDesktop(46)};
  left: ${vwDesktop(74)};
  span {
    display: inline-block;
    font-family: ${({ theme }) => theme.font.family};
    font-weight: bold;
    line-height: normal;
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.darkGrey};
    margin-bottom: ${vwDesktop(16)};
    ${vw('font-size', 16, 16, 16)}
    ${vw('letter-spacing', 2, 2, 2)}
  }
`;

const Container = styled.div`
  display: flex;
`;

const Button = styled.button`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  width: ${vwDesktop(125)};
  height: ${vwDesktop(40)};
  padding-left: ${vwDesktop(11)};
  pointer-events: ${props => props.disable ? 'none' : 'auto'};
  color: ${props => props.disable ? 'rgba(109, 108, 111, 0.5)' : props.active ? props.theme.color.darkGrey : '#fff'};
  background-color: ${props => props.disable ? 'white' : props.active ? props.theme.color.brandGreen : props.theme.color.brandGrey};
  border: ${props => props.disable ? `1px solid #dddddd` : 'none'};
  box-shadow: ${props => !props.disable && props.active && '0px 6px 12px 0px rgba(0,0,0,0.5)'};
  font-family: ${({ theme }) => theme.font.family};
  font-weight: 600;
  text-transform: uppercase;
  ${vw('font-size', 16, 16, 16)}
  ${vw('letter-spacing', 0.6, 0.6, 0.6)}
  ${vw('line-height', 26, 26, 26)}
  &:nth-of-type(5), &:nth-of-type(6) {
    width: ${vwDesktop(80)};
  }
  &:not(:last-of-type) {
    margin-right: ${vwDesktop(16)};
  }
`;

const Close = styled.div`
  width: ${vwDesktop(10)};
  height: ${vwDesktop(10)};
  position: absolute;
  top: 50%;
  right: ${vwDesktop(15)};
  transform: translateY(-50%);
  visibility: ${props => props.active ? 'visible' : 'hidden'};
  opacity: ${props => props.active ? '1' : '0'};
  transition: all 0.3s ease;
  &:before, &:after {
    content: '';
    width: 100%;
    height: ${vwDesktop(2)};
    background-color: ${({ theme }) => theme.color.darkGrey};
    position: absolute;
    top: 50%;
  }
  &:before {
    transform: translateY(-50%) rotate(45deg);
  }
  &:after {
    transform: translateY(-50%) rotate(-45deg);
  }
`;

const Tag = styled.div`
  display: ${props => props.active ? 'block' : 'none'};
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  ${vw('width', 48)}
  img {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
  }
  span {
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: ${({ theme }) => theme.color.brandGrey};
    text-transform: uppercase;
    font-weight: 600;
    ${vw('font-size', 10)}
    ${vw('line-height', 10)}
    ${vw('letter-spacing', -0.42)}
    ${vw('right', 7)}
  }
`;