import React from 'react';

export default {
	width: '720px',
	height: '470px',
	viewBox: '0 0 720 470',
	defs: (
		<defs>
			<filter x="-8.1%" y="-12.6%" width="116.3%" height="125.2%" filterUnits="objectBoundingBox" id="filter-1">
				<feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
				<feGaussianBlur stdDeviation="11.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
				<feColorMatrix values="0 0 0 0 0.37254902   0 0 0 0 0.501960784   0 0 0 0 0.141176471  0 0 0 0.303646061 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
				<feMerge>
					<feMergeNode in="shadowMatrixOuter1"></feMergeNode>
					<feMergeNode in="SourceGraphic"></feMergeNode>
				</feMerge>
			</filter>
		</defs>
	),
	outline: (
		<g id="lines" filter="url(#filter-1)" transform="translate(0.000000, 49.000000)">
            <polygon id="Path" fill="#EFEFEF" points="488.541628 201.908244 518.556964 180.75528 574 0.384599341 471.596244 0.384599341 471.596244 5.38439077 369.493807 6.37931034 369.493807 31.2586207 338.748374 31.2586207 338.748374 0.700018306 243.118799 0.700018306 243.118799 5.10344828 122.485288 6.37931034 122.485288 58.0517241 88.0363005 58.0517241 88.0363005 122.482759 88.0363005 181.172414 59.3288112 181.172414 0 368.724138 102.709017 369.362069 102.709017 364.258621 206.055979 364.258621 206.055979 338.741379 235.401412 338.741379 235.401412 370 341.300151 370 341.300151 362.982759 451.026554 361.706897 451.026554 314.5 478.458155 314.5 498.87237 244.327586 488.665262 244.327586"></polygon>
            <polygon id="Stroke-7" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" points="380.744351 157.297925 397.676901 157.297925 397.676901 122.305795 380.744351 122.305795"></polygon>
            <polygon id="Stroke-8" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" points="380.744351 222.679813 397.676901 222.679813 397.676901 183.066081 380.744351 183.066081"></polygon>
            <path d="M217.89928,169.989704 L356.490829,169.989704 L356.490829,126.908167 L217.89928,126.908167 L217.89928,169.989704 Z M356.490829,169.989704 L356.490829,205.757442 L217.905696,205.757442 L217.905696,169.989704 L356.490829,169.989704 Z M261.79302,205.757442 L261.79302,169.989704 L261.79302,205.757442 Z M307.983787,205.757442 L307.983787,169.989704 L307.983787,205.757442 Z" id="Stroke-9" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M217.90313,205.765775 L261.790454,169.998037 L307.981221,205.765775 L356.494679,169.998037 M356.494679,205.765775 L307.981221,169.998037 L261.790454,205.765775 L217.90313,169.998037" id="Stroke-10" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M320.300469,148.451499 L320.300469,169.989063 M316.072144,126.913936 L316.072144,148.451499 M254.09734,126.913936 L254.09734,148.451499 M258.325665,148.451499 L258.325665,169.989063" id="Stroke-11" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></path>
            <line x1="254.088357" y1="148.451499" x2="320.297903" y2="148.451499" id="Stroke-12" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></line>
            <g id="Group-17" transform="translate(0.000000, 0.000000)" stroke="#6D6C6F" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.4">
                <path d="M86.6197183,181.139879 L58.1442879,181.139879 L0,369.587146 L102.403756,369.587146 L102.403756,364.209166 M235.61205,338.832019 L235.61205,369.984566 L332.234742,369.984566 M450.807512,314.211251 L477.370892,314.211251 L498.929577,244.220581 L488.541628,244.220581 M488.541628,201.908244 L518.556964,180.75528 L574,0.384599341 L471.596244,0.384599341 L471.596244,5.38439077 M525.492958,124.994786 L535.89374,124.994786 M338.785759,31.1589566 L338.785759,0 L241.380282,0 L241.380282,5.38439077 M88.1596244,122.68719 L88.1596244,58.0745004 L122.422535,58.0745004" id="Stroke-13"></path>
            </g>
        </g>
	),
	compass: (
        <g id="Group" transform="translate(666.000000, 439.000000)">
            <g id="Group-3" transform="translate(-0.000000, -0.000000)">
                <polygon id="Triangle" fill="#6D6C6F" points="0.373344996 2.31092474 24.9689907 -3.66818172e-13 17.5516395 8.46906839 17.7779259 19.713931"></polygon>
                <polygon id="Path" fill="#484747" points="-2.3037041e-14 2.28628273 24.4764585 0.353004374 17.1911927 8.45150412 17.1929131 8.51412667"></polygon>
                <ellipse id="Oval" stroke="#6D6C6F" strokeWidth="2.08091907" fill="#FFFFFF" cx="38.5996674" cy="15.6738079" rx="14.3598731" ry="14.2857326"></ellipse>
                <path d="M35.7097304,21.1989884 L35.7091288,16.1978729 C35.7035141,15.5970509 35.6585964,14.6503812 35.5743759,13.357864 L35.5743759,13.357864 L35.6384912,13.357864 L40.1693045,21.1989884 L42.9761291,21.1989884 L42.9761291,10.8339577 L40.9885553,10.8339577 L40.9885553,15.7399777 C40.9885553,16.2976948 41.0241749,17.2500677 41.0954141,18.5970963 L41.0954141,18.5970963 L41.0455467,18.5970963 L36.5218573,10.8339577 L33.7364044,10.8339577 L33.7364044,21.1989884 L35.7097304,21.1989884 Z" id="N" fill="#6D6C6F" fillRule="nonzero"></path>
            </g>
        </g>
	),
	units: [
		{
			id: 'e1',
			mockup: (
				<g id="e1" transform="translate(50.000000, 277.000000)">
					<polygon id="shape" stroke="#6D6C6F" strokeWidth="2.1" fill="#FFFFFF" strokeLinecap="round" points="37.7092439 18.5100796 37.7092439 18.1181619 1.69026446e-13 18.1181619 1.69026446e-13 138 157 138 157 112.5639 153.915281 112.5639 153.915281 43.1751944 144.680364 43.1751944 144.680364 -9.97096841e-15 126.595319 -9.97096841e-15 126.595319 18.5100796"></polygon>
					<g id="type" transform="translate(35.317911, 86.741690)" fill="#2A2A2A" fontFamily="OpenSans-Semibold, Open Sans" fontSize="14" font-weight="500" letterSpacing="1" line-spacing="20">
						<text id="2-Bed+Den">
							<tspan x="0.499023438" y="15">2 BED+DEN</tspan>
						</text>
					</g>
					<g transform="translate(55.317911, 32.074500)" fill="#97C93D" fontFamily="OpenSans-Bold, Open Sans" fontSize="40" font-weight="bold" id="E1">
						<text>
							<tspan x="0.3828125" y="43">E1</tspan>
						</text>
					</g>
				</g>
			),
		},
		{
			id: 'd2',
			mockup: (
				<g id="d2" transform="translate(369.000000, 56.000000)">
					<polygon id="shape" stroke="#6D6C6F" strokeWidth="2.1" fill="#FFFFFF" strokeLinecap="round" points="157 120 157 9.98026145e-15 0.377465363 9.98026145e-15 -9.91882744e-15 25.85209 6.90953545 25.85209 6.90953545 95.3054662 12.6738794 95.3054662 12.6738794 117.299035 29.5574572 117.299035 29.5574572 116.913183 120.149144 116.913183 120.149144 120"></polygon>
					<g id="type" transform="translate(56.774935, 77.591939)" fill="#2A2A2A" fontFamily="OpenSans-Semibold, Open Sans" fontSize="14" fontWeight="500" letterSpacing="1" line-spacing="20">
						<text id="2-Bed">
							<tspan x="0.0209960938" y="15">2 BED</tspan>
						</text>
					</g>
					<g transform="translate(51.925947, 22.898031)" fill="#97C93D" fontFamily="OpenSans-Bold, Open Sans" fontSize="40" fontWeight="bold" id="D2">
						<text>
							<tspan x="0.279296875" y="43">D2</tspan>
						</text>
					</g>
				</g>
			),
		},
		{
			id: 'c2',
			mockup: (
				<g id="c2" transform="translate(334.000000, 273.000000)">
					<polygon id="shape" stroke="#6D6C6F" strokeWidth="2.1" fill="#FFFFFF" strokeLinecap="round" points="65.6824873 1.91079062 65.6824873 9.91777438e-15 48.6870873 9.91777438e-15 48.6870873 11.8865236 24.3371036 11.8865236 24.3371036 17.6316767 3.47120901 17.6316767 3.47120901 115.791355 2.99535633e-14 115.791355 2.99535633e-14 148 11.9785691 148 11.9785691 140.324884 119 140.324884 119 15.3374498 108.187087 15.3374498 108.187087 1.91079062"></polygon>
					<g id="type" transform="translate(37.733926, 91.772091)" fill="#2A2A2A" fontFamily="OpenSans-Semibold, Open Sans" fontSize="14" fontWeight="500" letterSpacing="1" line-spacing="20">
						<text id="2-Bed">
							<tspan x="0.0209960938" y="15">2 BED</tspan>
						</text>
					</g>
					<g transform="translate(35.233926, 36.293814)" fill="#97C93D" fontFamily="OpenSans-Bold, Open Sans" fontSize="40" fontWeight="bold" id="C2">
						<text>
							<tspan x="0.33984375" y="43">C2</tspan>
						</text>
					</g>
				</g>
			),
		},
		{
			id: 'c1',
			mockup: (
				<g id="c1" transform="translate(124.000000, 56.000000)">
					<polygon id="shape" stroke="#6D6C6F" strokeWidth="2.1" fill="#FFFFFF" strokeLinecap="round" points="1.29619838e-13 0 1.29619838e-13 117.377687 1.29619838e-13 118.910569 12.7273702 118.910569 12.7273702 142 71.3800311 142 71.3800311 136.227642 95.6900155 136.227642 95.6900155 121.597922 116.533576 121.597922 116.533576 25.7831978 120 25.7831978 120 0"></polygon>
					<g id="type" transform="translate(35.847554, 83.389437)" fill="#2A2A2A" fontFamily="OpenSans-Semibold, Open Sans" fontSize="14" fontWeight="500" letterSpacing="1" line-spacing="20">
						<text id="2-Bed">
							<tspan x="0.0209960938" y="15">2 BED</tspan>
						</text>
					</g>
					<g transform="translate(33.347554, 28.771994)" fill="#97C93D" fontFamily="OpenSans-Bold, Open Sans" fontSize="40" fontWeight="bold" id="C1">
						<text>
							<tspan x="0.33984375" y="43">C1</tspan>
						</text>
					</g>
				</g>
			),
		},
		{
			id: 'b6',
			mockup: (
				<g id="b6" transform="translate(240.000000, 81.000000)">
					<polygon id="shape" stroke="#6D6C6F" strokeWidth="2.1" fill="#FFFFFF" strokeLinecap="round" points="-9.92750431e-15 0 -9.92750431e-15 96 117.558454 96 117.558454 69.4022358 136 69.4022358 136 0"></polygon>
					<g id="type" transform="translate(18.322712, 62.140941)" fill="#2A2A2A" fontFamily="OpenSans-Semibold, Open Sans" fontSize="14" fontWeight="500" letterSpacing="1" line-spacing="20">
						<text id="1-Bed+Den">
							<tspan x="0.499023438" y="15">1 BED+DEN</tspan>
						</text>
					</g>
					<g transform="translate(36.322712, 7.468161)" fill="#97C93D" fontFamily="OpenSans-Bold, Open Sans" fontSize="40" fontWeight="bold" id="B6">
						<text>
							<tspan x="0.146484375" y="43">B6</tspan>
						</text>
					</g>
				</g>
			),
		},
		{
			id: 'b3',
			mockup: (
				<g id="b3" transform="translate(204.000000, 291.000000)">
					<polygon id="shape" stroke="#6D6C6F" strokeWidth="2.1" fill="#FFFFFF" strokeLinecap="round" points="15.0347826 9.9016409e-15 15.0347826 29.09375 1.49419678e-13 29.09375 1.49419678e-13 98 133 98 133 9.9016409e-15"></polygon>
					<g id="type" transform="translate(23.561303, 66.266711)" fill="#2A2A2A" fontFamily="OpenSans-Semibold, Open Sans" fontSize="14" fontWeight="500" letterSpacing="1" line-spacing="20">
						<text id="1-Bed+Den">
							<tspan x="0.499023438" y="15">1 BED+DEN</tspan>
						</text>
					</g>
					<g transform="translate(41.561303, 10.854281)" fill="#97C93D" fontFamily="OpenSans-Bold, Open Sans" fontSize="40" fontWeight="bold" id="B3">
						<text>
							<tspan x="0.146484375" y="43">B3</tspan>
						</text>
					</g>
				</g>
			),
		},
		{
			id: 'a3',
			mockup: (
				<g id="a3" transform="translate(88.000000, 173.000000)">
					<polygon id="shape" stroke="#6D6C6F" strokeWidth="2.1" fill="#FFFFFF" strokeLinecap="round" points="48.4964029 24.5483516 48.4964029 1.52827716 35.8013789 1.52827716 35.8013789 0 9.94541643e-15 0 9.94541643e-15 121.609938 9.94541643e-15 122 88.9100719 122 88.9100719 103.577546 107 103.577546 107 24.5483516"></polygon>
					<g id="type" transform="translate(31.416671, 89.032455)" fill="#2A2A2A" fontFamily="OpenSans-Semibold, Open Sans" fontSize="14" fontWeight="500" letterSpacing="1" line-spacing="20">
						<text id="1-Bed">
							<tspan x="0.0209960938" y="15">1 BED</tspan>
						</text>
					</g>
					<g transform="translate(27.566748, 34.529722)" fill="#97C93D" fontFamily="OpenSans-Bold, Open Sans" fontSize="40" fontWeight="bold" id="A3">
						<text>
							<tspan x="0.28515625" y="43">A3</tspan>
						</text>
					</g>
				</g>
			),
		},
		{
			id: 'a1',
			mockup: (
				<g id="a1" transform="translate(381.000000, 172.000000)">
					<polygon id="shape" stroke="#6D6C6F" strokeWidth="2.1" fill="#FFFFFF" strokeLinecap="round" points="16.9660099 0 16.9660099 0.77515625 16.9660099 35.3560938 0 35.3560938 0 61.115625 16.9660099 61.115625 16.9660099 102.621719 59.397107 102.621719 59.397107 116.08125 70.1913209 116.08125 70.1913209 123 108 123 108 0"></polygon>
					<g id="type" transform="translate(40.418888, 71.630629)" fill="#2A2A2A" fontFamily="OpenSans-Semibold, Open Sans" fontSize="14" fontWeight="500" letterSpacing="1" line-spacing="20">
						<text id="1-Bed">
							<tspan x="0.0209960938" y="15">1 BED</tspan>
						</text>
					</g>
					<g transform="translate(36.568197, 17.053250)" fill="#97C93D" fontFamily="OpenSans-Bold, Open Sans" fontSize="40" fontWeight="bold" id="A1">
						<text>
							<tspan x="0.28515625" y="43">A1</tspan>
						</text>
					</g>
				</g>
			),
		},
	]
}