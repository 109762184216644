import React from 'react';

export default {
	width: '720px',
	height: '470px',
	viewBox: '0 0 720 470',
	defs: (
		<defs>
			<filter x="-7.8%" y="-11.2%" width="115.5%" height="122.4%" filterUnits="objectBoundingBox" id="filter-1">
				<feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
				<feGaussianBlur stdDeviation="11.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
				<feColorMatrix values="0 0 0 0 0.37254902   0 0 0 0 0.501960784   0 0 0 0 0.141176471  0 0 0 0.303646061 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
				<feMerge>
					<feMergeNode in="shadowMatrixOuter1"></feMergeNode>
					<feMergeNode in="SourceGraphic"></feMergeNode>
				</feMerge>
			</filter>
		</defs>
	),
	outline: (
		<g id="lines" filter="url(#filter-1)" transform="translate(0.000000, 26.000000)">
            <polygon id="Path" fill="#EFEFEF" points="515.148357 240.432663 580.979287 240.432663 580.979287 417 0 417 110.492521 58.094943 148.993893 58.094943 149.012845 5.5947529 267.943285 5.5947529 267.943285 0 365.186409 0 365.186409 30.7711409 394.569223 30.7711409 394.569223 5.5947529 498.108665 6.29409701 498.108665 0 600.948516 0 545.680841 181.130125 514.898845 202.110448"></polygon>
            <polygon id="Stroke-7" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" points="407.342591 157.340417 424.283194 157.340417 424.283194 122.339833 407.342591 122.339833"></polygon>
            <polygon id="Stroke-8" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" points="407.342591 222.738102 424.283194 222.738102 424.283194 183.114799 407.342591 183.114799"></polygon>
            <polygon id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" points="244.481787 170.035262 383.093143 170.035262 383.093143 126.943317 244.481787 126.943317"></polygon>
            <polygon id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" points="383.093143 170.035262 383.093143 205.811642 244.488204 205.811642 244.488204 170.035262"></polygon>
            <path d="M288.379768,205.811642 L288.379768,170.035262 L288.379768,205.811642 Z" id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M334.581414,205.811642 L334.581414,170.035262 L334.581414,205.811642 Z" id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></path>
            <polyline id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" points="244.479862 205.819977 288.365009 170.043597 334.573073 205.819977 383.091218 170.043597"></polyline>
            <polyline id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" points="383.091218 205.819977 334.573073 170.043597 288.365009 205.819977 244.479862 170.043597"></polyline>
            <line x1="346.89287" y1="148.491854" x2="346.89287" y2="170.034621" id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></line>
            <line x1="342.670553" y1="126.949087" x2="342.670553" y2="148.491854" id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></line>
            <line x1="280.683344" y1="126.949087" x2="280.683344" y2="148.491854" id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></line>
            <line x1="284.918495" y1="148.491854" x2="284.918495" y2="170.034621" id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></line>
            <line x1="280.675002" y1="148.491854" x2="346.890945" y2="148.491854" id="Stroke-12" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></line>
            <polyline id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" points="515.148357 201.963437 545.179427 180.805363 601 0.391103799 498.207754 0.391103799 498.207754 5.3921032"></polyline>
            <line x1="552.103258" y1="125.024985" x2="562.498628" y2="125.024985" id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></line>
            <polyline id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" points="365.371603 31.1664847 365.371603 4.96913879e-15 267.969549 4.96913879e-15 267.969549 5.3921032"></polyline>
            <polyline id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" points="515.148357 240.432663 580.979287 240.432663 580.979287 417 1.19301402e-13 417 110.492521 58.094943 148.993893 58.094943"></polyline>
            <line x1="113.194034" y1="122.723243" x2="90.8632379" y2="122.723243" id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></line>
            <line x1="75.4626891" y1="244.657867" x2="53.1318934" y2="244.657867" id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></line>
            <line x1="232.548287" y1="364.29716" x2="232.548287" y2="417" id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></line>
            <line x1="358.832787" y1="371.221621" x2="358.832787" y2="417" id="Path" stroke="#6D6C6F" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"></line>
        </g>
	),
	compass: (
		<g id="Group" transform="translate(666.000000, 439.000000)">
            <g id="Group-3" transform="translate(-0.000000, -0.000000)">
                <polygon id="Triangle" fill="#6D6C6F" points="0.373344996 2.31092474 24.9689907 -3.66818172e-13 17.5516395 8.46906839 17.7779259 19.713931"></polygon>
                <polygon id="Path" fill="#484747" points="-2.3037041e-14 2.28628273 24.4764585 0.353004374 17.1911927 8.45150412 17.1929131 8.51412667"></polygon>
                <ellipse id="Oval" stroke="#6D6C6F" strokeWidth="2.08091907" fill="#FFFFFF" cx="38.5996674" cy="15.6738079" rx="14.3598731" ry="14.2857326"></ellipse>
                <path d="M35.7097304,21.1989884 L35.7091288,16.1978729 C35.7035141,15.5970509 35.6585964,14.6503812 35.5743759,13.357864 L35.5743759,13.357864 L35.6384912,13.357864 L40.1693045,21.1989884 L42.9761291,21.1989884 L42.9761291,10.8339577 L40.9885553,10.8339577 L40.9885553,15.7399777 C40.9885553,16.2976948 41.0241749,17.2500677 41.0954141,18.5970963 L41.0954141,18.5970963 L41.0455467,18.5970963 L36.5218573,10.8339577 L33.7364044,10.8339577 L33.7364044,21.1989884 L35.7097304,21.1989884 Z" id="N" fill="#6D6C6F" fillRule="nonzero"></path>
            </g>
        </g>
	),
	units: [
		{
			id: 'e1',
			mockup: (
				<g id="e1" transform="translate(75.000000, 253.000000)">
					<polygon id="shape" stroke="#6D6C6F" strokeWidth="2.1" fill="#FFFFFF" strokeLinecap="round" points="37.7043709 18.5100796 37.7043709 18.1181619 3.97454645e-14 18.1181619 3.97454645e-14 138 157 138 157 112.5639 153.921569 112.5639 153.921569 43.1751944 144.686275 43.1751944 144.686275 -3.98358366e-14 126.594077 -3.98358366e-14 126.594077 18.5100796"></polygon>
					<g id="type" transform="translate(31.256281, 96.320478)" fill="#2A2A2A" fontFamily="OpenSans-Semibold, Open Sans" fontSize="14" fontWeight="500" letterSpacing="1" line-spacing="20">
						<text id="2-Bed-+-Den">
							<tspan x="0.362304688" y="15">2 BED + DEN</tspan>
						</text>
					</g>
					<g transform="translate(55.756281, 41.687814)" fill="#97C93D" fontFamily="OpenSans-Bold, Open Sans" fontSize="40" fontWeight="bold">
						<text>
							<tspan x="0.3828125" y="43">E1</tspan>
						</text>
					</g>
				</g>
			),
		},
		{
			id: 'd2',
			mockup: (
				<g id="d2" transform="translate(395.000000, 32.000000)">
					<polygon id="shape" stroke="#6D6C6F" strokeWidth="2.1" fill="#FFFFFF" strokeLinecap="round" points="158 120 158 -9.968241e-15 0.379869601 -9.968241e-15 -1.49628091e-13 25.85209 6.9599837 25.85209 6.9599837 95.3054662 12.7481663 95.3054662 12.7481663 117.299035 29.7457213 117.299035 29.7457213 116.913183 120.914425 116.913183 120.914425 120"></polygon>
					<g id="type" transform="translate(54.399126, 79.467405)" fill="#2A2A2A" fontFamily="OpenSans-Semibold, Open Sans" fontSize="14" fontWeight="500" letterSpacing="1" line-spacing="20">
						<text id="2-Bed">
							<tspan x="2.52099609" y="15">2 BED </tspan>
						</text>
					</g>
					<g transform="translate(52.399126, 24.808055)" fill="#97C93D" fontFamily="OpenSans-Bold, Open Sans" fontSize="40" fontWeight="bold">
						<text>
							<tspan x="0.279296875" y="43">D2</tspan>
						</text>
					</g>
				</g>
			),
		},
		{
			id: 'c2',
			mockup: (
				<g id="c2" transform="translate(359.000000, 249.000000)">
					<polygon id="shape" stroke="#6D6C6F" strokeWidth="2.1" fill="#FFFFFF" strokeLinecap="round" points="65.6789328 1.92370137 65.6789328 0 48.6780129 0 48.6780129 11.9668379 24.3422263 11.9668379 24.3422263 17.7508096 3.4774609 17.7508096 3.4774609 116.573729 3.99087232e-14 116.573729 3.99087232e-14 149 11.9779209 149 11.9779209 141.273026 119 141.273026 119 15.4410812 108.181233 15.4410812 108.181233 1.92370137"></polygon>
					<g id="type" transform="translate(38.781778, 95.807259)" fill="#2A2A2A" fontFamily="OpenSans-Semibold, Open Sans" fontSize="14" fontWeight="500" letterSpacing="1" line-spacing="20">
						<text id="2-Bed">
							<tspan x="0.0209960938" y="15">2 BED</tspan>
						</text>
					</g>
					<g transform="translate(35.281778, 41.134916)" fill="#97C93D" fontFamily="OpenSans-Bold, Open Sans" fontSize="40" fontWeight="bold">
						<text>
							<tspan x="0.33984375" y="43">C2</tspan>
						</text>
					</g>
				</g>
			),
		},
		{
			id: 'c1',
			mockup: (
				<g id="c1" transform="translate(149.000000, 32.000000)">
					<polygon id="shape" stroke="#6D6C6F" strokeWidth="2.1" fill="#FFFFFF" strokeLinecap="round" points="3.98537273e-14 9.94167703e-15 3.98537273e-14 117.377687 3.98537273e-14 118.910569 12.733119 118.910569 12.733119 142 71.3826367 142 71.3826367 136.227642 95.6848875 136.227642 95.6848875 121.597922 116.527331 121.597922 116.527331 25.7831978 120 25.7831978 120 9.94167703e-15"></polygon>
					<g id="type" transform="translate(35.848695, 85.453135)" fill="#2A2A2A" fontFamily="OpenSans-Semibold, Open Sans" fontSize="14" fontWeight="500" letterSpacing="1" line-spacing="20">
						<text id="2-Bed">
							<tspan x="0.0209960938" y="15">2 BED</tspan>
						</text>
					</g>
					<g transform="translate(33.348695, 30.877302)" fill="#97C93D" fontFamily="OpenSans-Bold, Open Sans" fontSize="40" fontWeight="bold">
						<text>
							<tspan x="0.33984375" y="43">C1</tspan>
						</text>
					</g>
				</g>
			),
		},
		{
			id: 'b6',
			mockup: (
				<g id="b6" transform="translate(265.000000, 58.000000)">
					<polygon id="shape" stroke="#6D6C6F" strokeWidth="2.1" fill="#FFFFFF" strokeLinecap="round" points="-7.93622057e-14 0 -7.93622057e-14 96 117.559322 96 117.559322 69.4022358 136 69.4022358 136 0"></polygon>
					<g id="type" transform="translate(20.040985, 59.800175)" fill="#2A2A2A" fontFamily="OpenSans-Semibold, Open Sans" fontSize="14" fontWeight="500" letterSpacing="1" line-spacing="20">
						<text id="1-Bed+Den">
							<tspan x="0.499023438" y="15">1 BED+DEN</tspan>
						</text>
					</g>
					<g transform="translate(38.040985, 5.161928)" fill="#97C93D" fontFamily="OpenSans-Bold, Open Sans" fontSize="40" fontWeight="bold">
						<text>
							<tspan x="0.146484375" y="43">B6</tspan>
						</text>
					</g>
				</g>
			),
		},
		{
			id: 'b3',
			mockup: (
				<g id="b3" transform="translate(229.000000, 267.000000)">
					<polygon id="shape" stroke="#6D6C6F" strokeWidth="2.1" fill="#FFFFFF" strokeLinecap="round" points="15.0283575 -1.99812612e-14 15.0283575 29.390625 -3.98181092e-14 29.390625 -3.98181092e-14 99 133 99 133 -1.99812612e-14"></polygon>
					<g id="type" transform="translate(23.187903, 64.148299)" fill="#2A2A2A" fontFamily="OpenSans-Semibold, Open Sans" fontSize="14" fontWeight="500" letterSpacing="1" line-spacing="20">
						<text id="1-Bed+Den">
							<tspan x="0.499023438" y="15">1 BED+DEN</tspan>
						</text>
					</g>
					<g transform="translate(41.187903, 9.424578)" fill="#97C93D" fontFamily="OpenSans-Bold, Open Sans" fontSize="40" fontWeight="bold">
						<text>
							<tspan x="0.146484375" y="43">B3</tspan>
						</text>
					</g>
				</g>
			),
		},
		{
			id: 'a4',
			mockup: (
				<g id="a4" transform="translate(113.000000, 149.000000)">
					<polygon id="shape" stroke="#6D6C6F" strokeWidth="2.1" fill="#FFFFFF" strokeLinecap="round" points="48.4999101 24.5483516 48.4999101 1.52827716 35.7992327 1.52827716 35.7992327 2.97354421e-14 0 2.97354421e-14 0 121.609938 0 122 88.9047419 122 88.9047419 103.577546 107 103.577546 107 24.5483516"></polygon>
					<g id="type" transform="translate(28.344900, 85.749275)" fill="#2A2A2A" fontFamily="OpenSans-Semibold, Open Sans" fontSize="14" fontWeight="500" letterSpacing="1" line-spacing="20">
						<text id="1-Bed">
							<tspan x="0.0209960938" y="15">1 BED</tspan>
						</text>
					</g>
					<g transform="translate(24.544228, 31.295324)" fill="#97C93D" fontFamily="OpenSans-Bold, Open Sans" fontSize="40" fontWeight="bold">
						<text>
							<tspan x="0.28515625" y="43">A4</tspan>
						</text>
					</g>
				</g>
			),
		},
		{
			id: 'a1',
			mockup: (
				<g id="a1" transform="translate(407.000000, 149.000000)">
					<polygon id="shape" stroke="#6D6C6F" strokeWidth="2.1" fill="#FFFFFF" strokeLinecap="round" points="16.9714286 2.9790244e-14 16.9714286 0.77515625 16.9714286 35.3560938 -1.89237596e-13 35.3560938 -1.89237596e-13 61.115625 16.9714286 61.115625 16.9714286 102.621719 59.4 102.621719 59.4 116.08125 70.2 116.08125 70.2 123 108 123 108 2.9790244e-14"></polygon>
					<g id="type" transform="translate(39.844098, 65.608973)" fill="#2A2A2A" fontFamily="OpenSans-Semibold, Open Sans" fontSize="14" fontWeight="500" letterSpacing="1" line-spacing="20">
						<text id="1-Bed">
							<tspan x="0.0209960938" y="15">1 BED</tspan>
						</text>
					</g>
					<g transform="translate(36.344098, 11.080467)" fill="#97C93D" fontFamily="OpenSans-Bold, Open Sans" fontSize="40" fontWeight="bold">
						<text>
							<tspan x="0.28515625" y="43">A1</tspan>
						</text>
					</g>
				</g>
			),
		},
	]
}