import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';

import vw, { vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Intro = (props) => {
  const { title, text, subtitle, tablet, desktop, largeTitle } = props;

  const [start, setStart] = useState(false);

  const handleEnter = () => {
    setStart(true);
  };

  return (
    <Waypoint onEnter={handleEnter} bottomOffset="20%">
      <Root 
        largeTitle={largeTitle}
        tablet={tablet}
        desktop={desktop}
      >
        <div>
          {subtitle && <h4 className="strapline">{subtitle}</h4>}
          <h2 className="green">{title}</h2>
        </div>
        <Wrapper start={start}>
          <div>
            <Line
              src={require('src/assets/images/icons/green_diagonal_line.svg')}
              alt="Line"
            />
          </div>
        </Wrapper>
        <p>{text}</p>
      </Root>
    </Waypoint>
  );
};

Intro.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
};

const Root = styled.div`
  ${vw('padding-left', 12, 30, 80)}
  ${vw('padding-right', 24, 30, 74)}
  ${vw('margin-top', 56, 72, 120)}
  ${vw('margin-bottom', 56, 72, 120)}
  h2 {
    text-transform: uppercase;
    ${props => props.largeTitle ? '' : vw('margin-top', 0, 10, 18)}
    ${vw('margin-bottom', 16, 0)}
  }
  @media ${media.tablet} {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    p {
      margin-top: ${props => props.largeTitle ? '' : vwTablet(10)};
      width: ${props => vwTablet(props.tablet)};
    }
  }
  @media ${media.desktop} {
    p {
      margin-top: ${props => props.largeTitle ? '' : vwDesktop(18)};
      width: ${props => vwDesktop(props.desktop)};
    }
  }
`;

const Wrapper = styled.div`
  width: auto;
  @media ${media.desktop} {
    width: ${vwDesktop(60)};
    height: ${vwDesktop(100)};
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    > div {
      overflow: hidden;
      width: 100%;
      height: 100%;
      transition: 0.6s ease;
      max-width: ${(props) => (props.start ? vwDesktop(60) : 0)};
    }
  }
`;

const Line = styled.img`
  display: none;
  @media ${media.tablet} {
    display: block;
    width: ${vwTablet(50)};
  }
  @media ${media.desktop} {
    width: ${vwDesktop(60)};
  }
`;

export default Intro;
