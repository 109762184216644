import React, { Component } from 'react';
import { hotjar } from 'react-hotjar';

import { analytics } from 'src/config/app.conf';

export default class Hotjar extends Component {
  componentDidMount() {
    if (window.__prerendering) return;
    if (analytics.hotjarId) {
      hotjar.initialize(analytics.hotjarId, '6');
    }
  }

  render() {
    return <></>;
  }
}
