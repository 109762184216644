export default [
  {
    label: 'EXPLORE LEVEL 1',
    mobileLabel: 'Level 1',
    image: require('src/assets/images/amenities/Dollhouse_level_1.jpg'),
    points: [
      {
        top: '16.5%',
        left: '70%',
        text: 'DOG RUN',
      },
      {
        top: '7.1%',
        left: '40%',
        text: 'PLAY AREA',
        mobileBottom: true,
      },
      {
        top: '25%',
        left: '42.5%',
        text: 'OUTDOOR LOUNGE',
      },
      {
        top: '38%',
        left: '56.5%',
        text: 'YOGA ROOM',
      },
      {
        top: '54%',
        left: '63.5%',
        text: 'FITNESS CENTRE',
        mobileLeft: true,
      },
      {
        top: '57%',
        left: '78%',
        text: 'WORKOUT PATIO',
        mobileLeft: true,
        tabletLeft: true,
      },
    ],
  },
  {
    label: 'EXPLORE LEVEL 2',
    mobileLabel: 'Level 2',
    image: require('src/assets/images/amenities/Dollhouse_level_2.jpg'),
    points: [
      {
        top: '37.5%',
        left: '37.5%',
        text: 'BOARDROOM',
      },
      {
        top: '45%',
        left: '29%',
        text: 'CO-WORKING SPACE',
      },
      {
        top: '58.5%',
        left: '30.2%',
        text: 'CALL BOOTHS',
      },
      {
        top: '29%',
        left: '74.5%',
        text: 'DINING TERRACE',
        mobileLeft: true,
      },
      {
        top: '41.5%',
        left: '64%',
        text: 'PARTY ROOM',
      },
      {
        top: '57%',
        left: '62.75%',
        text: 'ENTERTAINMENT LOUNGE',
        mobileLeft: true,
      },
    ],
  },
  {
    label: 'EXPLORE LEVEL 30',
    mobileLabel: 'Level 30',
    image: require('src/assets/images/amenities/Dollhouse_level_30.jpg'),
    points: [
      {
        top: '21%',
        left: '50%',
        text: 'ROOFTOP SUN TERRACE',
        mobileLeft: true
      },
    ],
  },
];
