import React from 'react';
import styled from 'styled-components';

import StatCard from '../elements/StatCard';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

import data from 'src/data/stats';

const Stats = () => {
  return (
    <Root>
      {data.map((item, index) => (
        <StatCard data={item} key={index} />
      ))}
    </Root>
  );
};

const Root = styled.div`
  ${vw('padding-left', 12, 30, 72)}
  ${vw('padding-right', 12, 30, 72)}
  @media ${media.tablet} {
    display: flex;
    justify-content: space-between;
  }
`;

export default Stats;
