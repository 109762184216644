import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import vw, { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Hero = (props) => {
  const { image, mobileImage, title, subtitle, shortTitle } = props;
  return (
    <Root image={image} mobileImage={mobileImage} shortTitle={shortTitle}>
      <Placeholder />
      <Overlay />
      <Wrapper >
        <h3 className="strapline-hero white">{subtitle}</h3>
        <h1 className="white">{title}</h1>
      </Wrapper>
    </Root>
  );
};

Hero.propTypes = {
  image: PropTypes.string,
  mobileImage: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

const Root = styled.div`
  width: 100%;
  height: ${`calc(100vh - ${vwMobile(50)})`};
  max-height: -webkit-fill-available;
  position: relative;
  background-image: ${(props) =>
    props.mobileImage ? `url(${props.mobileImage})` : `url(${props.image})`};
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  text-transform: uppercase;
  ${vw('margin-top', 50, 87, 96)}
  ${vw('padding-left', 12, 30, 80)}
  ${vw('padding-right', 24, 30, 80)}
  ${vw('padding-bottom', 48, 48, 80)}
  h1 {
    width: ${props => props.shortTitle && vwMobile(176)};
    ${vw('margin-top', 8, 8, 16)}
  }
  @media ${media.tablet} {
    height: ${vwTablet(352)};
    background-position: center;
    h1 {
      width: auto;
    }
    h3 br {
      display: none;
    }
  }
  @media ${media.desktop} {
    height: ${`calc(100vh - ${vwDesktop(96)})`};
    background-image: url(${(props) => props.image});
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
`;

const Placeholder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.color.lightGreen};
  z-index: -1;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to bottom,
    rgba(238, 238, 238, 0) 62%,
    rgba(0, 0, 0, 0.4) 72%
  );
  @media ${media.tablet} {
    background-image: linear-gradient(
      to bottom,
      rgba(238, 238, 238, 0) 47%,
      rgba(0, 0, 0, 0.3) 66%
    );
  }
  @media ${media.desktop} {
    background-image: linear-gradient(
      to bottom,
      rgba(238, 238, 238, 0) 50%,
      rgba(0, 0, 0, 0.4) 66%
    );
  }
`;

export default Hero;
