import { createGlobalStyle } from 'styled-components';
import theme from './theme';
import vw from './utils';
import media from './media';

export default createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html,
  body,
  #app {
    width: 100%;
    height: 100%;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-stroke: 0;
  }

  body {
    font-family: ${theme.font.family};
  }

  a,
  button {
    outline: none;
    border: none;
    background: transparent;
    text-decoration: none;
    color: ${theme.color.darkGrey};
  }

  main {
    text-align: center;
    position: absolute;
    top: 120px;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .white {
    color: ${theme.color.white};
  }

  .green {
    color: ${theme.color.brandGreen};
  }

  .yellow {
    color: ${theme.color.brandYellow};
  }

  .grey {
    color: ${theme.color.brandGrey};
  }

  .darkGrey {
    color: ${theme.color.darkGrey};
  }

  h1 {
    font-family: ${theme.font.family};
    font-weight: bold;
    ${vw('font-size', 22, 36, 64)}
    ${vw('line-height', 24, 42, 72)}
    ${vw('letter-spacing', 0.2, 0.55, 1)}
    @media ${media.desktop} {
      letter-spacing: normal;
    }
    &.footer {
      text-transform: uppercase;
      ${vw('font-size', 20, 24, 40)}
      ${vw('line-height', 22, 24, 52)}
      ${vw('letter-spacing', 1, 0.8, 1.2)}
    }
  }

  h2 {
    font-family: ${theme.font.family};
    font-weight: bold;
    ${vw('font-size', 20, 28, 44)}
    ${vw('line-height', 20, 36, 52)}
    ${vw('letter-spacing', 0.63, 0.76, 0.5)}
    &.yellow {
      ${vw('font-size', 40)}
    }
    &.footer {
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
      color: ${({ theme }) => theme.color.darkGrey};
      ${vw('font-size', 14, 14, 16)}
      ${vw('letter-spacing', 0.5, 0.5, 1)}
    }
    &.2 {
      letter-spacing: normal;
      color: #777777;
    }
  }

  h3 {
    font-family: ${theme.font.family};
    font-weight: bold;
    ${vw('font-size', 14, 16, 18)}
    ${vw('line-height', 26, 26, 26)}
    ${vw('letter-spacing', 0.8, 0.89, 1)}
    &.footer {
      line-height: normal;
      ${vw('font-size', 20, 28, 16)}
      ${vw('letter-spacing', 0.63, 0.76, 1)}
    }
    &.strapline-hero {
      font-family: ${theme.font.family};
      font-weight: bold;
      line-height: normal;
      ${vw('font-size', 12, 14, 20)}
      ${vw('letter-spacing', 0.2, 0.7, 1)}
      @media ${media.tablet} {
        font-weight: normal;
      }
    }
    &.stats {
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-transform: uppercase;
      ${vw('font-size', 24, 20, 40)}
      @media ${media.desktop} {
        line-height: 1.3;
      }
    }
  }

  p {
    font-family: ${theme.font.family};
    font-weight: normal;
    letter-spacing: normal;
    color: ${theme.color.darkGrey};
    ${vw('font-size', 14, 14, 16)}
    ${vw('line-height', 21, 21, 26)}
    &.body2 {
      font-weight: normal;
      letter-spacing: normal;
      line-height: 1.5;
      ${vw('font-size', 14, 14, 24)}
      @media ${media.tablet} {
        line-height: normal;
      }
    }
    &.footer {
      ${vw('font-size', 12, 12, 14)}
      ${vw('line-height', 14, 16, 20)}
    }
    &.footer.disclaimer {
      ${vw('font-size', 8, 8, 10)}
      ${vw('line-height', 10, 10, 14)}
      &.tall {
        ${vw('line-height', 14, 10, 14)}
      }
    }
    &.callouts {
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
      ${vw('font-size', 10, 12)}
      ${vw('letter-spacing', 0.5, 0.8)}
    }
  }

  .plan-name {
    font-family: ${theme.font.family};
    font-weight: bold;
    letter-spacing: normal;
    line-height: normal;
    ${vw('font-size', 44, 58, 64)}
    &.card {
      ${vw('font-size', 40, 58, 64)}
      ${vw('line-height', 48, 58, 64)}
      ${vw('letter-spacing', 0.5, 0.5, 0.5)}
    }
  }

  .floorplans {
    font-family: ${theme.font.family};
    &.floorplate {
      &.unit-label {
        font-weight: bold;
        letter-spacing: normal;
        line-height: normal;
        ${vw('font-size', 50)};
      }
      &.unit-label-2 {
        font-weight: bold;
        letter-spacing: normal;
        line-height: normal;
        ${vw('font-size', 40)};
      }
      &.unit-label-th3 {
        font-weight: bold;
        letter-spacing: normal;
        line-height: normal;
        ${vw('font-size', 25)};
      }
      &.unit-name {
        font-weight: bold;
        letter-spacing: normal;
        ${vw('font-size', 40)};
        ${vw('letter-spacing', 1)};
        ${vw('line-height', 52)};
      }
      &.unit-name-filter {
        font-weight: bold;
        ${vw('font-size', 32)};
        ${vw('letter-spacing', 0.8)};
        ${vw('line-height', 52)};
      }
    }
  }

  .stats {
    font-family: ${theme.font.family};
    font-weight: bold;
    letter-spacing: normal;
    line-height: normal;
    ${vw('font-size', 24, 20, 32)};
    @media ${media.desktop} {
      line-height: 1.63;
    }
  }

  .menu-items {
    font-family: ${theme.font.family};
    font-weight: 600;
    line-height: normal;
    ${vw('font-size', 18, 24, 24)};
    ${vw('letter-spacing', 1, 1.5, 1.5)};
  }

  .button {
    font-family: ${theme.font.family};
    font-weight: bold;
    text-transform: uppercase;
    color: ${theme.color.darkGrey};
    ${vw('font-size', 12, 14)};
    ${vw('letter-spacing', 0.8, 1.2)};
  }

  .button-map {
    font-family: ${theme.font.family};
    font-weight: bold;
    text-transform: uppercase;
    color: ${theme.color.darkGrey};
    ${vw('font-size', 12, 12, 14)};
    ${vw('letter-spacing', 0.8, 0.8, 1.2)};
  }

  .strapline {
    font-family: ${theme.font.family};
    font-weight: 600;
    text-transform: uppercase;
    color: ${theme.color.brandGrey};
    ${vw('font-size', 12, 13, 14)};
    ${vw('letter-spacing', 0.4, 1, 1.2)};
  }
  
  .form-body {
    font-family: ${theme.font.family};
    font-weight: 600;
    text-transform: uppercase;
    color: ${theme.color.darkGrey};
    ${vw('font-size', 10, 12, 14)}
    ${vw('letter-spacing', 0.83, 0, 1.17)}
  }
  
  .form-disclaimer {
    font-family: ${theme.font.family};
    font-weight: normal;
    color: ${theme.color.darkGrey};
    ${vw('font-size', 10, 10, 12)}
    ${vw('line-height', 10, 12, 14)}
    ${vw('letter-spacing', 0, 0, 1)}
  }

  .slick-slider {
    line-height: 0;
  }
`;
