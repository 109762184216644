import React from 'react';
import styled from 'styled-components';

import vw, { vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Radio = (props) => {
  const { id, label, options, setField, required } = props;
  return (
    <Root>
      <p className='form-body'>{label}</p>
      <Container>
        {options.map((option, i) => (
          <Option key={i}>
            <input type='radio' name={id} value={option} required={required} onChange={(e) => setField(e.target.value)} />
            <span class="checkmark" />
            <label for={id}>{option}</label>
          </Option>
        ))}
      </Container>
    </Root>
  )
}

export default Radio;

const Root = styled.div`
  display: flex;
  align-items: flex-end;
  ${vw('width', 294, 505, 554)}
  ${vw('margin-bottom', 32, 24, 60)}
  p {
    line-height: normal;
    ${vw('margin-right', 31, 24)}
  }
  @media ${media.desktop} {
    flex-direction: column;
    align-items: flex-start;
    p {
      margin-bottom: ${vwDesktop(11)};
    }
  }
`;

const Container = styled.div`
  display: flex;
`;

const Option = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  &:first-of-type {
    ${vw('margin-right', 31, 40)}
  }
  input {
    opacity: 0;
    ${vw('width', 18, 22, 35)}
    ${vw('height', 18, 22, 35)}
    ${vw('margin-right', 4, 8, 16)}
  }
  label {
    font-family: ${({ theme }) => theme.font.family};
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    ${vw('font-size', 10, 12, 12)}
    ${vw('letter-spacing', 0.83, 0, 0)}
  }
  .checkmark {
    display: inline-block;
    background-color: ${({ theme }) => theme.color.lightGreen};
    position: absolute;
    bottom: 0;
    left: 0;
    pointer-events: none;
    ${vw('width', 18, 22, 35)}
    ${vw('height', 18, 22, 35)}
    &:after {
      content: '';
      width: 50%;
      height: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: transparent;
    }
  }
  input:checked ~ .checkmark {
    &:after {
      background-color: ${({ theme }) => theme.color.brandGreen};
    }
  }
  @media ${media.tablet} {
    label {
      line-height: ${vwTablet(20)};
    }
  }
  @media ${media.desktop} {
    label {
      line-height: normal;
    }
  }
`;