export default {
  color: {
    brandYellow: '#ffd100',
    brandGreen: '#97c93d',
    brandGrey: '#777679',
    brandGrey2: '#6d6c6f',
    darkGrey: '#2a2a2a',
    lightGreen: '#f2f7e6',
    white: '#ffffff',
    mapParksRec: '#d7df20',
    mapConvenience: '#f7921e',
    mapEducation: '#00aeef',
    mapRestaurants: '#a7a9ac',
  },
  font: {
    family: 'Open Sans',
  },
};
