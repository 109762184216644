import React from 'react';
import styled from 'styled-components';

import data from 'src/data/floorplans/floorplans';

const Floorplate = (props) => {
  const { plate, handleUnit, activeFilter} = props;
  return (
    <Root {...props}>
      <SVG
        width={plate.width}
        height={plate.height}
        viewBox={plate.viewBox}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Level Select</title>
        {plate.defs}
        <g
          id="keyplates"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          {plate.outline}
          {plate.compass}
          {plate.units.map((unit, index) => (
            <G 
              key={index}
              onClick={() => handleUnit(unit.id)}
              highlight={activeFilter.some(filter => data.find(item => item.id === unit.id).type.includes(filter))}
            >
              {unit.mockup}
            </G>
          ))}
        </g>
      </SVG>
    </Root>
  )
}

export default Floorplate;

const Root = styled.div``;

const SVG = styled.svg`

`;

const G = styled.g`
  cursor: pointer;
  g {
    polygon {
      fill: ${props => props.highlight ? props.theme.color.brandGreen : '#fff'};
      transition: all 0.3s ease;
    }
    &:nth-of-type(2) {
      text {
        tspan {
          fill: ${props => props.highlight ? props.theme.color.darkGrey : props.theme.color.brandGreen};
          transition: all 0.3s ease;
        }
      }
    }
  }
`;