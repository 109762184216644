import React, { useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import vw, { vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

const Scheme = (props) => {
  const { data } = props;
  const [activeSlide, setActiveSlide] = useState(0);
  const [activePoint, setActivePoint] = useState(null);
  const browser = useSelector((state) => state.browser);
  const $ref = useRef();

  const mobileSettings = {
    dots: false,
    infinite: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => {
      setActiveSlide(next);
    },
  }

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    beforeChange: (current, next) => {
      setActiveSlide(next);
    },
  }

  const handleSettings = () => {
    if (browser.is.mobile) {
      return mobileSettings;
    } else {
      return settings;
    }
  }

  const handleSlide = (index) => {
    $ref.current.slickGoTo(index);
    setActivePoint(null);
  }
  
  const handleNext = () => {
    $ref.current.slickNext();
    setActivePoint(null);
  }

  const handlePrev = () => {
    $ref.current.slickPrev();
    setActivePoint(null);
  }

  const handlePoint = (index) => {
    if (index === activePoint) {
      setActivePoint(null);
    } else {
      setActivePoint(index);
    }
  }
  
  return (
    <Root content={data.content}>
      <SliderWrapper content={data.content}>
        <Slider
          ref={$ref}
          {...handleSettings()}
        >
          {data.schemes.map((scheme, index) => (
            <Container
              src={scheme.image}
              content={data.content}
              key={index}
            >
              <Overlay />
              {browser.is.mobile &&
                <Strapline>{scheme.label}</Strapline>
              }
              {data.points.map((point, index) => (
                <Point
                  top={point.pos[1]}
                  left={point.pos[0]}
                  onClick={() => handlePoint(index)}
                  key={index}
                >
                  <span />
                  <Label
                    active={activePoint === index}
                    pos={point.labelPos}
                  >
                    {point.label}
                  </Label>
                </Point>
              ))}
            </Container>
          ))}
        </Slider>
        {
          !browser.is.mobile &&
          <Buttons content={data.content}>
            {data.schemes.map((scheme, index) => (
              <Button
                active={activeSlide === index}
                onClick={() => handleSlide(index)}
                key={index}
              >
                {scheme.label}
              </Button>
            ))}
          </Buttons>
        }
      </SliderWrapper>
      {browser.is.mobile &&
        <Swipe>
          <img src={require('src/assets/images/icons/dropdown_arrow.svg')} alt='' onClick={handlePrev} />
          <span>Swipe to see all 4 Scheme Colors</span>
          <img src={require('src/assets/images/icons/dropdown_arrow.svg')} alt='' onClick={handleNext} />
        </Swipe>
      }
      {data.content &&
        <Content>
          <h2>{data.content.title}</h2>
          <p>{data.content.body}</p>
        </Content>
      }
    </Root>
  )
}

export default Scheme;

const Root = styled.div`
  display: flex;
  flex-direction: column-reverse;
  ${vw('padding-right', 0, 31, 74)}
  ${vw('padding-left', 0, 31, 74)}
  @media ${media.tablet} {
    ${props => props.content && css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    `}
  }
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(to bottom, rgba(238, 238, 238, 0) 67%, rgba(0, 0, 0, 0.4) 91%);
`;

const SliderWrapper = styled.div`
  ${props => props.content && css`
    ${vw('width', 320, 456, 813)}
  `}
`;

const Content = styled.div`
  ${vw('width', 264, 197, 300)}
  ${vw('margin-top', 56, 70, 207)}
  ${vw('margin-bottom', 24, 0)}
  ${vw('padding-right', 13, 0)}
  ${vw('padding-left', 12, 0)}
  h2 {
    font-family: ${({ theme }) => theme.font.family};
    font-weight: bold;
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.brandGreen};
    line-height: normal;
    ${vw('margin-bottom', 15, 16, 25)}
  }
`;

const Container = styled.div`
  background: url(${props => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  ${vw('height', 148, 327, 597)}
  ${props => props.content && css`
    ${vw('width', 320, 456, 813)}
    ${vw('height', 235, 336, 600)}
  `}
`;

const Strapline = styled.span`
  display: inline-block;
  position: absolute;
  left: ${vwMobile(16)};
  bottom: ${vwMobile(14)};
  font-family: ${({ theme }) => theme.font.family};
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  font-size: ${vwMobile(10)};
  letter-spacing: ${vwMobile(0.2)};
  z-index: 1;
`;

const Point = styled.div`
  cursor: pointer;
  position: absolute;
  top: ${props => props.top};
  left: ${props => props.left};
  transform: translate(-50%, -50%);
  span {
    display: inline-block;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0 0 33px 0 rgba(0, 0, 0, 0.1), 0 10px 15px 0 rgba(0, 0, 0, 0.5);
    position: relative;
    z-index: 1;
    ${vw('width', 18, 20, 36)}
    ${vw('height', 18, 20, 36)}
    &:after {
      content: '';
      width: 50%;
      height: 50%;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: ${({ theme }) => theme.color.brandGreen};
    }
  }
`;

const Label = styled.div`
  background-color: rgba(242, 247, 230, 0.85);
  position: absolute;
  top: 50%;
  opacity: ${props => props.active ? '1' : '0'};
  transition: all 0.3s ease;
  ${props => props.pos === 'right' && css`
    ${vw('left', 9, 10, 18)}
  `}
  ${props => props.pos === 'left' && css`
    ${vw('right', 9, 10, 18)}
  `}
  white-space: nowrap;
  font-family: ${({ theme }) => theme.font.family};
  font-weight: 600;
  text-transform: uppercase;
  line-height: normal;
  color: ${({ theme }) => theme.color.darkGrey};
  display: flex;
  justify-content: center;
  align-items: center;
  ${vw('height', 32, 50)}
  ${vw('font-size', 10, 12)}
  ${vw('letter-spacing', 0.5, 0.8)}
  ${vw('padding-left', 12, 23)}
  ${vw('padding-right', 11, 24)}
`;

const Swipe = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${vwMobile(10)};
  font-family: ${({ theme }) => theme.font.family};
  font-weight: bold;
  font-size: ${vwMobile(10)};
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.brandGrey};
  letter-spacing: ${vwMobile(0.5)};
  margin-bottom: ${vwMobile(13)};
  img {
    width: ${vwMobile(12)};
    :nth-of-type(1) {
      transform: rotate(90deg);
    }
    :nth-of-type(2) {
      transform: rotate(-90deg);
    }
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  ${vw('padding-right', 165, 165, 345)}
  ${vw('padding-left', 165, 165, 345)}
  ${vw('margin-top', 12, 12, 32)}
  ${vw('margin-bottom', 72, 72, 120)}
  ${props => props.content && css`
    ${vw('padding-right', 42, 42, 107)}
    ${vw('padding-left', 42, 42, 107)}
  `}
  
`;

const Button = styled.button`
  cursor: pointer;
  font-family: ${({ theme }) => theme.font.family};
  font-weight: bold;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.darkGrey};
  opacity: ${props => props.active ? '1' : '0.5'};
  position: relative;
  transition: all 0.3s ease;
  ${vw('width', 60, 60, 80)}
  ${vw('font-size', 12, 12, 16)}
  ${vw('letter-spacing', 0.8, 0.8, 2)}
  ${vw('padding-top', 4, 4, 16)}
  &:after {
    content: '';
    width: 100%;
    background-color: ${({ theme }) => theme.color.brandGreen};
    position: absolute;
    top: 0;
    left: 0;
    opacity: ${props => props.active ? '1' : '0'};
    ${vw('height', 2, 2, 4)}
  }
`;