import React from 'react';
import styled from 'styled-components';

import CTA from './elements/CTA';
import Info from './elements/Info';

import vw from 'src/styles/utils';

const Footer = (props) => {
  const { $ref, paddingBottom } = props;

  return (
    <Root ref={$ref} paddingBottom={paddingBottom}>
      <CTA />
      <Info />
    </Root>
  );
};

const Root = styled.footer`
  ${props => props.paddingBottom && vw('padding-bottom', 112, 0)}
`;

export default Footer;
