import React from 'react';
import styled from 'styled-components';

import Heading from 'components/Heading';
import Intro from './sections/Intro';
import Grid from './sections/Grid';

import vw from 'src/styles/utils';

const Team = () => {
  return (
    <Root>
      <Heading
        title={<>From our family <br/> to yours</>}
        subtitle='Team'
      />
      <Intro />
      <Grid />
    </Root>
  )
}

export default Team;

const Root = styled.div`
  ${vw('padding-right', 13, 31, 80)}
  ${vw('padding-left', 12, 30, 80)}
`;