import React from 'react';
import styled from 'styled-components';

import Button from 'components/Button';

import vw from 'src/styles/utils';

const ThankYou = () => {
  return (
    <Root>
      <span>Thank You for Registering</span>
      <h1>One of our advisors will be in touch shortly.</h1>
      <Button
        to='/'
        text='back to homepage'
      />
    </Root>
  )
}

export default ThankYou;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  ${vw('margin-top', 50, 84, 96)}
  ${vw('padding-top', 47, 156.5, 80)}
  ${vw('padding-right', 24.5, 160, 216)}
  ${vw('padding-bottom', 56, 156.5, 120)}
  ${vw('padding-left', 24.5, 161, 215)}
  height: fill-available;
  span {
    display: inline-block;
    font-family: ${({ theme }) => theme.font.family};
    color: ${({ theme }) => theme.color.brandGrey};
    text-transform: uppercase;
    ${vw('font-size', 12, 14, 20)}
    ${vw('letter-spacing', 0.4, 0.7, 1)}
    ${vw('margin-bottom', 4, 8, 16)}
  }
  h1 {
    color: ${({ theme }) => theme.color.brandGreen};
    ${vw('font-size', 22, 36, 44)}
    ${vw('line-height', 24, 42, 52)}
    ${vw('letter-spacing', 0.55, 1, 1.2)}
    ${vw('width', 240, 447, 1009)}
    ${vw('margin-bottom', 40, 40, 120)}
  }
`;