export default [
  {
    id: 'a1',
    bed: 1,
    den: false,
    interior: '501 - 504',
    balcony: '114 - 159',
    size: '615 - 663',
    type: ['1 bed'],
    thumb1: 'a1_levels_3-6.png',
    thumb2: 'a1_level_7.png',
    thumb3: 'a1_levels_8-29.png',
    views: {
      defaultIndex: 1,
      defaultAngle: 360,
    },
    levels: '3 - 29',
    sold: true,
  },
  {
    id: 'a2',
    bed: 1,
    den: false,
    interior: '581',
    balcony: '55',
    size: '636',
    type: ['1 bed'],
    thumb1: 'a2_levels_3-6.png',
    views: {
      defaultIndex: 13,
      defaultAngle: 270,
    },
    levels: '3 - 6',
    sold: true,
  },
  {
    id: 'a3',
    bed: 1,
    den: false,
    interior: '530',
    balcony: '114',
    size: '644',
    type: ['1 bed'],
    thumb1: 'a3_levels_20-29.png',
    views: {
      defaultIndex: 1,
      defaultAngle: 180,
    },
    levels: '20 - 29',
    sold: true,
  },
  {
    id: 'a4',
    bed: 1,
    den: false,
    interior: '524',
    balcony: '114 - 182',
    size: '638 - 706',
    type: ['1 bed'],
    thumb1: 'a4_level_7.png',
    thumb2: 'a4_levels_8-19.png',
    views: {
      defaultIndex: 6,
      defaultAngle: 180,
    },
    levels: '7 - 29',
    sold: true,
  },
  {
    id: 'b1',
    bed: 1,
    den: true,
    interior: '643',
    balcony: '55',
    size: '698',
    type: ['1 bed'],
    thumb1: 'b1_levels_3-6.png',
    views: {
      defaultIndex: 13,
      defaultAngle: 270,
    },
    levels: '3 - 6',
    sold: true,
  },
  {
    id: 'b2',
    bed: 1,
    den: true,
    interior: '772',
    balcony: '55',
    size: '827',
    type: ['1 bed'],
    thumb1: 'b2_levels_3-6.png',
    views: {
      defaultIndex: 13,
      defaultAngle: 270,
    },
    levels: '3 - 6',
    sold: true,
  },
  {
    id: 'b3',
    bed: 1,
    den: true,
    interior: '589',
    balcony: '139 - 414',
    size: '728 - 1,003',
    type: ['1 bed'],
    thumb1: 'b3_level_7.png',
    thumb2: 'b3_levels_8-29.png',
    views: {
      defaultIndex: 1,
      defaultAngle: 270,
    },
    levels: '3 - 6',
    sold: true,
  },
  {
    id: 'b5',
    bed: 1,
    den: true,
    interior: '657',
    balcony: '49',
    size: '706',
    type: ['1 bed'],
    thumb1: 'b5_levels_3-6.png',
    views: {
      defaultIndex: 13,
      defaultAngle: 180,
    },
    levels: '3 - 6',
    sold: true,
  },
  {
    id: 'b6',
    bed: 1,
    den: true,
    interior: '585',
    balcony: '139',
    size: '724',
    type: ['1 bed'],
    thumb1: 'b6_level_7.png',
    thumb2: 'b6_levels_8-29.png',
    views: {
      defaultIndex: 1,
      defaultAngle: 90,
    },
    levels: '7 - 29',
    sold: true,
  },
  {
    id: 'c1',
    bed: 2,
    den: false,
    interior: '734 - 738',
    balcony: '102 - 120',
    size: '836 - 858',
    type: ['2 bed'],
    thumb1: 'c1_levels_3-6.png',
    thumb2: 'c1_level_7.png',
    thumb3: 'c1_levels_8-29.png',
    views: {
      defaultIndex: 1,
      defaultAngle: 135,
    },
    levels: '3 - 29',
    sold: true,
  },
  {
    id: 'c2',
    bed: 2,
    den: false,
    interior: '728',
    balcony: '118 - 966',
    size: '846 - 1,694',
    type: ['2 bed'],
    thumb1: 'c2_level_7.png',
    thumb2: 'c2_levels_8-29.png',
    views: {
      defaultIndex: 1,
      defaultAngle: 315,
    },
    levels: '7 - 29',
    sold: true,
  },
  {
    id: 'c3',
    bed: 2,
    den: false,
    interior: '754',
    balcony: '55',
    size: '809',
    type: ['2 bed'],
    thumb1: 'c3_levels_3-6.png',
    views: {
      defaultIndex: 13,
      defaultAngle: 180,
    },
    levels: '3 - 6',
    sold: true,
  },
  {
    id: 'd1',
    bed: 2,
    den: false,
    interior: '969',
    balcony: '111',
    size: '1,080',
    type: ['2 bed'],
    thumb1: 'd1_levels_3-6.png',
    views: {
      defaultIndex: 13,
      defaultAngle: 315,
    },
    levels: '3 - 6',
    sold: true,
  },
  {
    id: 'd2',
    bed: 2,
    den: false,
    interior: '825',
    balcony: '124 - 169',
    size: '949 - 994',
    type: ['2 bed'],
    thumb1: 'd2_level_7.png',
    thumb2: 'd2_levels_8-29.png',
    views: {
      defaultIndex: 1,
      defaultAngle: 325,
    },
    levels: '7 - 29',
    sold: true,
  },
  {
    id: 'd3',
    bed: 2,
    den: false,
    interior: '807',
    balcony: '268',
    size: '1,075',
    type: ['2 bed', 'ph'],
    thumb1: 'd3_level_30.png',
    thumb2: 'd3_level_31.png',
    views: {
      defaultIndex: 0,
      defaultAngle: 270,
    },
    levels: '3 - 6',
    sold: true,
  },
  {
    id: 'e1',
    bed: 2,
    den: true,
    interior: '866 - 869',
    balcony: '122 - 604',
    size: '988 - 1,473',
    type: ['2 bed'],
    thumb1: 'e1_level_7.png',
    thumb2: 'e1_levels_8-29.png',
    views: {
      defaultIndex: 1,
      defaultAngle: 225,
    },
    levels: '7 - 29',
    sold: true,
  },
  {
    id: 'e2',
    bed: 2,
    den: true,
    interior: '1018',
    balcony: '221 - 370',
    size: '1,239 - 1,388',
    type: ['2 bed', 'ph'],
    thumb1: 'e2_level_30.png',
    thumb2: 'e2_level_31.png',
    views: {
      defaultIndex: 0,
      defaultAngle: 315,
    },
    levels: '30 - 31',
    sold: true,
  },
  {
    id: 'e3',
    bed: 2,
    den: true,
    interior: '1,079',
    balcony: '385 - 442',
    size: '1,464 - 1,521',
    type: ['2 bed', 'ph'],
    thumb1: 'e3_level_30.png',
    thumb2: 'e3_level_31.png',
    views: {
      defaultIndex: 0,
      defaultAngle: 180,
    },
    levels: '30 - 31',
    sold: true,
  },
  {
    id: 'f',
    bed: 3,
    den: false,
    interior: '957',
    balcony: '124 - 169',
    size: '1,081 - 1,126',
    type: ['3 bed'],
    thumb1: 'f_levels_3-6.png',
    views: {
      defaultIndex: 13,
      defaultAngle: 45,
    },
    levels: '3 - 6',
    sold: true,
  },
  {
    id: 'g',
    bed: 0,
    den: false,
    interior: '453',
    balcony: '139',
    size: '592',
    type: ['studio'],
    thumb1: 'g_levels_3-6.png',
    views: {
      defaultIndex: 13,
      defaultAngle: 90,
    },
    levels: '3 - 6',
    sold: true,
  },
  // {
  //   id: 'th1',
  //   bed: 3,
  //   den: false,
  //   interior: '1,469',
  //   balcony: '380',
  //   size: '1,849',
  //   type: ['th'],
  //   thumb1: 'th1_level_1.png',
  //   thumb2: 'th1_level_2.png',
  //   levels: '1 - 2',
  //   sold: true,
  // },
  // {
  //   id: 'th2',
  //   bed: 2,
  //   den: true,
  //   interior: '1,362',
  //   balcony: '394',
  //   size: '1,756',
  //   type: ['th'],
  //   thumb1: 'th2_level_1.png',
  //   thumb2: 'th2_level_2.png',
  //   levels: '1 - 2',
  //   sold: true,
  // },
  // {
  //   id: 'th3',
  //   bed: 2,
  //   den: true,
  //   interior: '1,492',
  //   balcony: '394',
  //   size: '1,886',
  //   type: ['th'],
  //   thumb1: 'th3_level_1.png',
  //   thumb2: 'th3_level_2.png',
  //   levels: '1 - 2',
  //   sold: true,
  // },
  // {
  //   id: 'th4',
  //   bed: 2,
  //   den: true,
  //   interior: '1,400',
  //   balcony: '394',
  //   size: '1,794',
  //   type: ['th'],
  //   thumb1: 'th4_level_1.png',
  //   thumb2: 'th4_level_2.png',
  //   levels: '1 - 2',
  //   sold: true,
  // },
]