import React from 'react';
import styled, { css } from 'styled-components';

import Heading from 'components/Heading';

import vw, { vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Contact = () => {
  return (
    <Root>
      <Heading 
        title={<>Your Home in <br/> Surrey City Centre</>}
        subtitle='Contact Us'
      />
      <Wrapper>
        <Container>
          <div>
            <h3>Maple Leaf Office</h3>
            <P margin height>
              13018 84 Ave, <br/>
              Surrey, BC V3W 1L2
            </P>
          </div>
          <div>
            <P width></P>
          </div>
        </Container>
        <Container>
          <div>
            <h3>Call or Text</h3>
            <P margin height><a href='tel:604-498-8215'>604-498-8215</a></P>
          </div>

          <div>
            <h3>Email</h3>
            <P><a href='mailto:info@ownyourascent.com'>info@OwnYourAscent.com</a></P>
          </div>
        </Container>
      </Wrapper>
    </Root>
  )
}

export default Contact;

const Root = styled.div`
  ${vw('padding-bottom', 32, 161, 120)}
`;

const Wrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${media.tablet} {
    flex-direction: row;
    text-align: left;
    align-items: flex-start;
    justify-content: center;
  }
`;

const Container = styled.div`
  &:first-of-type {
    ${vw('margin-right', 0, 72, 171)}
  }
  h3 {
    text-transform: uppercase;
    ${vw('margin-bottom', 0, 4, 8)}
  }
  div {
    ${vw('margin-bottom', 24)}
  }
`;

const P = styled.p`
  ${vw('font-size', 14, 14, 24)}
  ${vw('line-height', 21, 14, 34)}
  ${props => props.margin && css`
    ${vw('margin-bottom', 24, 24, 49)}
  `}
  ${props => props.width && css`
    display: inline-block;
    ${vw('width', 106, 199, 342)}
    ${vw('margin-bottom', 4, 0)}
  `}
  @media ${media.tablet} {
    ${props => props.height && css`
      min-height: ${vwTablet(38)};
    `}
  }
  @media ${media.desktop} {
    ${props => props.height && css`
      min-height: ${vwDesktop(66)};
    `}
  }
`;