import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Hamburger = (props) => {
  const { isOpen, handleMenu } = props;
  return (
    <Root onClick={handleMenu}>
      <Wrapper>
        <img
          src={require('src/assets/images/icons/hamburger_menu.svg')}
          alt="Open Menu"
          style={{ opacity: isOpen ? 0 : 1 }}
        />
        <img
          src={require('src/assets/images/icons/modal_close.svg')}
          alt="Close Menu"
          className="close"
          style={{ opacity: isOpen ? 1 : 0 }}
        />
      </Wrapper>
    </Root>
  );
};

Hamburger.propTypes = {
  isOpen: PropTypes.bool,
  handleMenu: PropTypes.func,
};

const Root = styled.div`
  background-color: ${({ theme }) => theme.color.brandGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${vw('width', 50, 32)}
  ${vw('height', 50, 32)}
  ${vw('margin-left', 0, 22, 40)}
  img {
    display: block;
    transition: 0.4s ease;
    ${vw('width', 18, 24)}
  }
  .close {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    ${vw('width', 14, 24)}
    ${vw('height', 14, 24)}
  }
  @media ${media.tablet} {
    background-color: transparent;
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  ${vw('width', 24, 24)}
  ${vw('width', 24, 24)}
`;

export default Hamburger;
