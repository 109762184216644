import Level1 from './level1-2';
import Level3 from './level3-6';
import Level7 from './level7';
import Level8 from './level8-19';
import Level20 from './level20-29';
import Level30 from './level30';
import Level31 from './level31';

export default [
  {
    title: 'Level 1-2',
    component: Level1,
  },
  {
    title: 'Level 3-6',
    component: Level3,
  },
  {
    title: 'Level 7',
    component: Level7,
  },
  {
    title: 'Level 8-10',
    component: Level8,
  },
  {
    title: 'Level 20-29',
    component: Level20,
  },
  {
    title: 'Level 30',
    component: Level30,
  },
  {
    title: 'Level 31',
    component: Level31,
  },
]