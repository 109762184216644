import React from 'react';
import styled from 'styled-components';

import Input from './Input';
import Select from './Select';
import Radio from './Radio';
import Button from 'components/Button';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Form = (props) => {
  const {
    isSubmitted,
    handleSubmit,
    setFirstName,
    setLastName,
    setEmail,
    setPhone,
    setPostalCode,
    setBedroom,
    setSurvey,
    setRealtor,
  } = props;

  return (
    <Root>
      <form
        id="registration-form"
        action="https://gateway.rennie.com/leads"
        method="POST"
        onSubmit={(e) => handleSubmit(e)}
      >
        
        <div style={{ position: 'absolute', left: '-9999px', top: '-9999px' }}>
          <label htmlFor="pardot_extra_field">Comments</label>
          <input
            type="text"
            id="pardot_extra_field"
            name="pardot_extra_field"
          />
        </div>
        <input name="00N3Z00000CeVvt" type="hidden" value="Project Site" />
        <input name="00N3Z00000CeVw9" type="hidden" value="Web Source" />
        <input
          name="00N3Z00000CeVw2"
          type="hidden"
          value="7013Z000002sYcIQAU"
        />
        <input
          name="00N3Z00000CeVw3"
          type="hidden"
          value="a043Z00000YjeV9QAJ"
        />
        <input name="00N3Z00000CeYiQ" type="hidden" value="1" />
        <input name="00N3Z00000CeVw1" type="hidden" value="Web Registration" />
        <input
          name="retURL"
          type="hidden"
          value="https://www.ownyourascent.com/thank-you"
        />
        <input name="language" type="hidden" value="en" />

        <Row>
          <Input
            name="first_name"
            label="First Name"
            setField={setFirstName}
            required
          />
          <Input
            name="last_name"
            label="Last Name"
            setField={setLastName}
            required
          />
        </Row>

        <Row>
          <Input
            name="phone"
            label="Phone Number"
            pattern="^[0-9-+\s()]*$"
            setField={setPhone}
            required
          />
          <Input name="email" label="Email" setField={setEmail} required />
        </Row>

        <Row>
          <Input
            name="zip"
            label="Postal Code"
            setField={setPostalCode}
            required
          />
          <Radio
            id="00Nj0000000gK0N"
            label="Are you a Realtor?"
            options={['Yes', 'No']}
            setField={setRealtor}
            required
          />
        </Row>

        <Row>
          <Select
            id="00Nf100000CNWPU"
            label="Bedroom Type Interested"
            options={['Studio', '1 Bed', '2 Bed', '3 Bed', 'Townhomes']}
            setField={setBedroom}
          />
          <Select
            id="00Nj000000AITER"
            label="How did you hear about us?"
            options={[
              'Friends and Family',
              'Online Advertisement',
              'Realtor',
              'Signage/Walk by/Drive by',
              'Other',
            ]}
            setField={setSurvey}
          />
        </Row>

        <Consent>
          <input name="consent" id="consent" type="checkbox" required />
          <span />
          <label for="consent" className='form-disclaimer'>
            By clicking on the box, I am permitting Rennie and Maple Leaf Homes
            to contact me with promotional messages such as newsletters,
            announcements, press releases and event invitations regarding their
            products and services. I understand this may take the form of
            communication via email, telephone or post and consent to the
            collection, use and disclosure of the information submitted for the
            purposes disclosed on this page. If I want to receive email
            communication, I must give explicit consent via the tick box. I may
            withdraw my consent at any time.
          </label>
        </Consent>
        <div
          className="g-recaptcha"
          data-sitekey="6LfCNjwaAAAAAIH0fw2V6n7L-dN_wyR6p-WTZr98"
          data-size="invisible"
          data-callback="formSubmit"
        ></div>
        <Button
          type="submit"
          text="Submit"
          width={{
            mobile: '100%',
          }}
        />
      </form>
    </Root>
  );
};

export default Form;

const Root = styled.div`
  .g-recaptcha {
    opacity: 0 !important;
  }
  > iframe {
    display: none;
  }
`;

const Row = styled.div`
  @media ${media.desktop} {
    display: flex;
    justify-content: space-between;
  }
`;

const Consent = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  ${vw('margin-bottom', 24, 24, 40)}
  ${vw('padding-left', 26, 32, 59)}
  label {
    font-family: ${({ theme }) => theme.font.family};
  }
  input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    ${vw('width', 18, 22, 35)}
    ${vw('height', 18, 22, 35)}
  }
  span {
    display: inline-block;
    background-color: ${({ theme }) => theme.color.lightGreen};
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    ${vw('width', 18, 22, 35)}
    ${vw('height', 18, 22, 35)}
    &:after {
      content: '';
      width: 50%;
      height: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: transparent;
    }
  }
  input:checked ~ span {
    &:after {
      background-color: ${({ theme }) => theme.color.brandGreen};
    }
  }
`;
