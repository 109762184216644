export default [
  {
    text: 'HOME',
    to: '/',
  },
  {
    text: 'NEIGHBOURHOOD',
    to: '/neighbourhood',
  },
  {
    text: 'AMENITIES',
    to: '/amenities',
  },
  {
    text: 'INTERIORS',
    to: '/interiors',
  },
  {
    text: 'FLOORPLANS',
    to: '/floorplans',
  },
  {
    text: 'TEAM',
    to: '/team',
  },
  {
    text: 'CONTACT US',
    to: '/contact',
  },
];
