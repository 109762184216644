export default {
  filtersEnabled: [
    //Level 1 to 2
    ['th'],
    //Level 3 to 6
    ['studio', '1 bed', '2 bed', '3 bed'],
    //Level 7
    ['1 bed', '2 bed'],
    //Level 8-19
    ['1 bed', '2 bed'],
    //Level 20-29
    ['1 bed', '2 bed'],
    //Level 30
    ['2 bed', 'ph'],
    //Level 31
    ['2 bed', 'ph'],
  ],
  views: [
    {
      label: '31',
      img: require('src/assets/images/views/level_31.jpg'),
      units: ['e2', 'd3', 'e3'],
    },
    {
      label: '29 - 30',
      img: require('src/assets/images/views/level_29-30.jpg'),
      units: ['b6', 'd2', 'a1', 'c2', 'b3', 'e1', 'a3', 'c1', 'e2', 'd3', 'e3'],
    },
    {
      label: '27 - 28',
      img: require('src/assets/images/views/level_27-28.jpg'),
      units: ['b6', 'd2', 'a1', 'c2', 'b3', 'e1', 'a3', 'c1'],
    },
    {
      label: '25 - 26',
      img: require('src/assets/images/views/level_25-26.jpg'),
      units: ['b6', 'd2', 'a1', 'c2', 'b3', 'e1', 'a3', 'c1'],
    },
    {
      label: '23 - 24',
      img: require('src/assets/images/views/level_23-24.jpg'),
      units: ['b6', 'd2', 'a1', 'c2', 'b3', 'e1', 'a3', 'c1'],
    },
    {
      label: '21 - 22',
      img: require('src/assets/images/views/level_21-22.jpg'),
      units: ['b6', 'd2', 'a1', 'c2', 'b3', 'e1', 'a3', 'c1']
    },
    {
      label: '19 - 20',
      img: require('src/assets/images/views/level_19-20.jpg'),
      units: ['b6', 'd2', 'a1', 'c2', 'b3', 'e1', 'a4', 'a3', 'c1'],
    },
    {
      label: '17 - 18',
      img: require('src/assets/images/views/level_17-18.jpg'),
      units: ['b6', 'd2', 'a1', 'c2', 'b3', 'e1', 'a4', 'c1'],
    },
    {
      label: '15 - 16',
      img: require('src/assets/images/views/level_15-16.jpg'),
      units: ['b6', 'd2', 'a1', 'c2', 'b3', 'e1', 'a4', 'c1'],
    },
    {
      label: '13 - 14',
      img: require('src/assets/images/views/level_13-14.jpg'),
      units: ['b6', 'd2', 'a1', 'c2', 'b3', 'e1', 'a4', 'c1'],
    },
    {
      label: '11 - 12',
      img: require('src/assets/images/views/level_11-12.jpg'),
      units: ['b6', 'd2', 'a1', 'c2', 'b3', 'e1', 'a4', 'c1'],
    },
    {
      label: '9 - 10',
      img: require('src/assets/images/views/level_09-10.jpg'),
      units: ['b6', 'd2', 'a1', 'c2', 'b3', 'e1', 'a4', 'c1'],
    },
    {
      label: '7 - 8',
      img: require('src/assets/images/views/level_07-08.jpg'),
      units: ['b6', 'd2', 'a1', 'c2', 'b3', 'e1', 'a4', 'c1'],
    },
    {
      label: '5 - 6',
      img: require('src/assets/images/views/level_05-06.jpg'),
      units: ['g', 'f', 'a1', 'd1', 'b1', 'b2', 'a2', 'c3', 'b5', 'c1'],
    },
    {
      label: '3 - 4',
      img: require('src/assets/images/views/level_03-04.jpg'),
      units: ['g', 'f', 'a1', 'd1', 'b1', 'b2', 'a2', 'c3', 'b5', 'c1'],
    },
  ]
}