export default [
  {
    type: 'studio',
    sold: true,
  },
  {
    type: '1 bed',
    sold: true,
  },
  {
    type: '2 bed',
    sold: true,
  },
  {
    type: '3 bed',
    sold: true,
  },
  {
    type: 'ph',
    sold: true,
  },
  // {
  //   type: 'th',
  //   sold: true,
  // }
]