import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Button from 'components/Button';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const ContentBlock = (props) => {
  const {
    title,
    text,
    subtitle,
    buttons,
    image,
    imageSize,
    contentSize,
    reverse,
    largeMargin,
    noMarginBottom,
  } = props;
  return (
    <Root reverse={reverse} noMarginBottom={noMarginBottom}>
      <Image
        src={image}
        alt={title}
        size={imageSize}
        reverse={reverse}
        largeMargin={largeMargin}
      />
      <Wrapper reverse={reverse} size={contentSize}>
        <h4 className="strapline">{subtitle}</h4>
        <h2 className="green">{title}</h2>
        <p>{text}</p>
        <Links>
          {buttons.map((item, index) => (
            <Button to={item.to} text={item.text} key={index} />
          ))}
        </Links>
      </Wrapper>
    </Root>
  );
};

ContentBlock.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  text: PropTypes.string,
  subtitle: PropTypes.string,
  buttons: PropTypes.array,
  imageSize: PropTypes.object,
  contentSize: PropTypes.object,
  largeMargin: PropTypes.bool,
};

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: ${vwMobile(56)};
  @media ${media.tablet} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: ${vwTablet(72)};
    padding-left: ${(props) => props.reverse && vwTablet(30)};
    padding-right: ${(props) => !props.reverse && vwTablet(30)};
  }
  @media ${media.desktop} {
    justify-content: ${(props) => (props.reverse ? 'flex-end' : 'flex-start')};
    padding-bottom: ${vwDesktop(120)};
    padding-right: 0;
    padding-left: 0;
  }
`;

const Wrapper = styled.div`
  margin: 0 ${vwMobile(12)};
  h4 {
    margin-bottom: ${vwMobile(1)};
  }
  h2 {
    margin: ${vwMobile(1)} 0 ${vwMobile(16)};
    line-height: normal;
    white-space: nowrap;
    br {
      &:first-of-type {
        display: none;
      }
    }
  }
  p {
    margin-bottom: ${vwMobile(32)};
  }
  @media ${media.tablet} {
    order: ${(props) => (props.reverse ? 1 : 2)};
    margin: 0;
    width: ${(props) => vwTablet(props.size.tablet)};
    h4 {
      margin-bottom: 0;
    }
    h2 {
      margin: ${vwTablet(2)} 0 ${vwTablet(16)};
      white-space: auto;
      br {
        &:first-of-type {
          display: block;
        }
        &:nth-of-type(2) {
          display: none;
        }
      }
    }
    p {
      margin-bottom: ${vwTablet(40)};
    }
  }
  @media ${media.desktop} {
    width: auto;
    h2 {
      margin: ${vwDesktop(8)} 0 ${vwDesktop(24)};
    }
    p {
      margin-bottom: ${vwDesktop(40)};
      width: ${(props) => vwDesktop(props.size.desktop)};
    }
  }
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  a:nth-of-type(2) {
    margin-top: ${vwMobile(16)};
  }
  @media ${media.tablet} {
    a:nth-of-type(2) {
      margin-top: ${vwTablet(16)};
    }
  }
  @media ${media.desktop} {
    flex-direction: row;
    align-items: center;
    a:nth-of-type(2) {
      margin-top: 0;
      margin-left: ${vwDesktop(35)};
    }
  }
`;

const Image = styled.img`
  width: ${(props) => (props.size.smallMobile ? vwMobile(257) : '100%')};
  height: ${(props) => (props.size.smallMobile ? vwMobile(182) : 'auto')};
  align-self: ${(props) =>
    props.size.smallMobile && props.reverse ? 'flex-end' : 'flex-start'};
  object-fit: cover;
  object-position: top;
  display: block;
  margin-bottom: ${vwMobile(24)};
  @media ${media.tablet} {
    height: auto;
    margin-bottom: 0;
    order: ${(props) => (props.reverse ? 2 : 1)};
    width: ${(props) => vwTablet(props.size.tablet)};
  }
  @media ${media.desktop} {
    width: ${(props) => vwDesktop(props.size.desktop)};
    margin-left: ${(props) =>
      props.reverse ? (props.largeMargin ? vwDesktop(140) : vwDesktop(40)) : 0};
    margin-right: ${(props) =>
      !props.reverse
        ? props.largeMargin
          ? vwDesktop(140)
          : vwDesktop(40)
        : 0};
  }
`;

export default ContentBlock;
