import React, { useState } from 'react';
import styled from 'styled-components';
import { useSwipeable } from 'react-swipeable';

import Select from '../elements/Select';
import Dollhouse from '../elements/Dollhouse';

import vw from 'src/styles/utils';

import data from 'src/data/amenities';

const Callouts = () => {
  const [activeLevel, setActiveLevel] = useState(0);
  const [activePoint, setActivePoint] = useState(null);

  const swiping = useSwipeable({
    onSwipedLeft: (e) => handleSwipe(e),
    onSwipedRight: (e) => handleSwipe(e),
  });

  const handleNext = () => {
    setActivePoint(null);
    if (activeLevel === data.length - 1) {
      setActiveLevel(0);
    } else {
      setActiveLevel(activeLevel + 1);
    }
  };

  const handlePrev = () => {
    setActivePoint(null);
    if (activeLevel === 0) {
      setActiveLevel(data.length - 1);
    } else {
      setActiveLevel(activeLevel - 1);
    }
  };

  const handleSwipe = (e) => {
    if (e.dir.toLowerCase() === 'right') {
      handlePrev();
    } else {
      handleNext();
    }
  };

  const handleLevel = (index) => {
    setActivePoint(null);
    setActiveLevel(index);
  };

  const handlePoint = (index) => {
    index === activePoint ? setActivePoint(null) : setActivePoint(index);
  };

  return (
    <Root {...swiping}>
      <Select data={data} activeLevel={activeLevel} handleLevel={handleLevel} />
      <Dollhouse
        data={data}
        activeLevel={activeLevel}
        activePoint={activePoint}
        handlePoint={handlePoint}
      />
    </Root>
  );
};

const Root = styled.div`
  ${vw('margin-bottom', 56, 72, 120)}
`;

export default Callouts;
