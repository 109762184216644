import React, { Component } from 'react';
import Script from 'react-load-script';
import OnUpdate from 'src/plugins/OnUpdate';

import { analytics } from 'src/config/app.conf';

export default class GTag extends Component {
  state = {
    isLoaded: false,
  };

  handleScriptLoad = () => {
    let options = {};
    window.dataLayer = window.dataLayer || [];
    window.gtag = function() {
      window.dataLayer.push(arguments);
    };

    if (analytics.optimizeId) {
      options.optimize_id = analytics.optimizeId;
    }

    window.gtag('js', new Date());
    window.gtag('config', analytics.gtag, options);

    if (analytics.optimizeId) {
      window.dataLayer && window.dataLayer.push({ event: 'optimize.activate' });
    }

    this.setState({ isLoaded: true });
  };

  handleUpdate = location => {
    const gtag = window.gtag;
    if (gtag) {
      gtag('config', analytics.gtag, {
        page_location: window.location.href,
        page_path: location.pathname,
      });
    }
  };

  render() {
    return analytics.gtag && !window.__PRERENDERING ? (
      <>
        <Script
          url={`https://www.googletagmanager.com/gtag/js?id=${analytics.gtag}`}
          onLoad={this.handleScriptLoad}
        />
        {this.state.isLoaded && <OnUpdate immediate call={this.handleUpdate} />}
      </>
    ) : null;
  }
}
