import React from 'react';
import styled from 'styled-components';

import vw from 'src/styles/utils';

const Compass = (props) => {
  const { angle } = props;
  return (
    <Root>
      <img src={require('src/assets/images/views/compass/Compass_base.svg')} alt='' />
      <img 
        src={require('src/assets/images/views/compass/Compass_direction.svg')} alt='' 
        style={{
          transform: `rotate(${-225 - angle}deg)`,
        }}
      />
    </Root>
  )
}

export default Compass;

const Root = styled.div`
  img {
    position: absolute;
    ${vw('width', 54, 81, 135)}
    ${vw('top', 8, 16, 18)}
    ${vw('right', 8, 24, 26)}
  }
`;