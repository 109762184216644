import React from 'react';
import styled from 'styled-components';

import Heading from 'components/Heading';
import Button from 'components/Button';

import vw from 'src/styles/utils';

const NotFound = () => {
  return (
    <Root>
      <StyledHeading 
        title='Sorry, page not found.'
        subtitle='404'
      />
      <Button
        to='/'
        text='Back to homepage'
      />
    </Root>
  );
};

export default NotFound;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${vw('padding-bottom', 56, 72, 120)}
`;

const StyledHeading = styled(Heading)`
  ${vw('margin-bottom', 40, 40, 80)}
  h1 {
    text-transform: none;
  }
`;